import React from 'react';

function Divider(props) {
  const { title, textcenter = false } = props;

  return (
    <div className={!textcenter ? 'divider-label' : 'divider-label-center'}>
      <span className={!textcenter ? '' : 'divider-label-text'}>{title}</span>
    </div>
  );
}

export default Divider;
