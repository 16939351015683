import React, { useEffect } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import NotificationAlert from '@components/notifications/NotificationAlert';

function Wrapper(props) {
  const {
    title,
    className,
    customContentClassName,
    customTitleClassName,
    customBreadcrumbClassName,
    breadcrumbData,
    showNotification,
    notification,
    onCloseNotification,
    children,
  } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="page-wrapper">
      <div className={'page-content-tab ' + customContentClassName}>
        <Container fluid={true}>
          <Row>
            <Col sm={12}>
              <div className={'page-title-box ' + customBreadcrumbClassName}>
                {breadcrumbData && (
                  <Breadcrumb>
                    {breadcrumbData.map((item) => (
                      <BreadcrumbItem key={item.id} active={item.active}>
                        <a href={item.url}>{item.label}</a>
                      </BreadcrumbItem>
                    ))}
                  </Breadcrumb>
                )}
                {title && <h4 className={'page-title ' + customTitleClassName}>{title}</h4>}
              </div>
            </Col>
            <Col sm={12}>
              <div className={className}>{children}</div>
            </Col>
          </Row>
          <NotificationAlert
            isShow={showNotification}
            notification={notification}
            onClose={onCloseNotification}
          ></NotificationAlert>
        </Container>
      </div>
    </div>
  );
}

export default Wrapper;
