import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import findIndex from 'lodash/findIndex';
import ReactPaginate from 'react-paginate';

const CustomPagination = (props) => {
  const {
    page,
    pageLimit,
    totalRows,
    totalAllRows,
    totalPages,
    short = false,
    handleChangePage,
    handleChangeRowPerPage,
  } = props;

  const [rowPerPage, setRowPerPage] = useState({});
  const [longInfoShowingData, setLongInfoShowingData] = useState('');
  const [shortInfoShowingData, setShortInfoShowingData] = useState('');

  const rowPerPageOptions = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
  ];

  const customStyles = {
    option: (provided) => ({
      ...provided,
      fontWeight: '500',
      backgroundColor: '#fff',
      textAlign: 'center',
      color: '#333333',
      '&:hover': {
        color: '#6610F2',
        cursor: 'pointer',
      },
      ':active': {
        ...provided[':active'],
        backgroundColor: '#6610F2',
        color: '#fff!important',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      color: '#333333',
    }),
    control: (provided) => ({
      ...provided,
      width: 50,
      height: 40,
      border: 0,
      backgroundColor: '#fff',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginBottom: '-3px',
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: 4,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#BDBDBD',
      };
    },
  };

  const onChangeRowPerPage = (e) => {
    if (e?.value && e?.label) {
      setRowPerPage(e);
      handleChangeRowPerPage(e?.value);
    }
  };

  const onChangePage = (e) => {
    if (e?.selected >= 0) {
      handleChangePage(e?.selected + 1);
    }
  };

  const calculateInfoShowingData = () => {
    let startRow = page * pageLimit - pageLimit + 1;
    let endRow = page * pageLimit - pageLimit + totalRows;
    let allRow = totalAllRows >= 0 ? totalAllRows : 0;
    startRow = startRow >= 0 ? startRow : 0;
    endRow = endRow >= 0 ? endRow : 0;

    setLongInfoShowingData('Showing ' + startRow + ' to ' + endRow + ' of ' + allRow + ' entries');
    setShortInfoShowingData(startRow + ' - ' + endRow + ' of ' + allRow);
  };

  const defaultRowPerPage = () => {
    const indexRowPerPage = findIndex(rowPerPageOptions, (item) => {
      return item.value === pageLimit;
    });

    if (indexRowPerPage < 0) {
      setRowPerPage({ value: 10, label: '10' });
    } else {
      setRowPerPage(rowPerPageOptions[indexRowPerPage]);
    }
  };
  useEffect(() => {
    defaultRowPerPage();
  }, [pageLimit]);

  useEffect(() => {
    calculateInfoShowingData();
  }, [page, pageLimit]);

  return (
    <React.Fragment>
      <div className="section-pagination">
        <div className="section-row-per-page">
          <span>Row per Page</span>
          <Select
            menuPlacement="top"
            components={{ IndicatorSeparator: () => null }}
            name="component"
            isSearchable={false}
            styles={customStyles}
            onChange={onChangeRowPerPage}
            value={rowPerPage}
            options={rowPerPageOptions}
            onKeyDown={onChangeRowPerPage}
          />
        </div>
        <div className="section-info-paging">{short ? shortInfoShowingData : longInfoShowingData}</div>
        <div className="section-paging">
          <ReactPaginate
            forcePage={page - 1}
            breakLabel="..."
            nextLabel=""
            nextClassName="custom-pagination next-section"
            onPageChange={onChangePage}
            pageRangeDisplayed={short ? 1 : 2}
            marginPagesDisplayed={1}
            pageCount={totalPages}
            previousLabel=""
            previousClassName="custom-pagination prev-section"
            renderOnZeroPageCount={null}
            className="custom-pagination d-inline-block"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomPagination;
