import React, { useRef, useEffect, createRef } from 'react';
import { FormGroup, Input, Label, Col, FormFeedback, FormText } from 'reactstrap';

const FilterTextField = (props) => {
  const {
    value,
    invalid,
    onChange,
    onKeyDown,
    onBlur,
    disabled,
    inputName,
    inputMin,
    inputMax,
    placeholder,
    inputType = 'text',
    inputPattern = '',
    className = '',
    width = 'w-261',
    handle,
  } = props;

  const inputFilter = useRef(null);

  useEffect(() => {
    inputFilter.current.focus();
  }, [handle]);

  return (
    <Input
      autoFocus
      innerRef={inputFilter}
      type={inputType}
      pattern={inputPattern}
      value={value}
      name={inputName}
      min={inputMin}
      max={inputMax}
      invalid={invalid}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      placeholder={placeholder}
      className={'text-field-filter' + ' ' + className + ' ' + width}
    />
  );
};

export default FilterTextField;
