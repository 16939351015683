import React, { useState, useMemo, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Wrapper from '@components/wrapper/Wrapper';
import BookingFleetBadges from '@components/badge/BookingFleetBadges';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomModal from '@components/modal/CustomModal';
import ExportFinanceForm from '@components/booking/ExportFinanceForm';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import { Col, Button, Row, Input, Card } from 'reactstrap';
import map from 'lodash/map';
import TickBold from '@assets/icons/tick-bold.svg';
import TickBorder from '@assets/icons/tick-border.svg';
import CloseBold from '@assets/icons/close-bold.svg';
import CloseBorder from '@assets/icons/close-border.svg';
import { filter } from 'lodash';
import findIndex from 'lodash/findIndex';
import editIcon from '@assets/icons/edit.svg';
import EstimationNoteModal from '@components/booking/EstimationNoteModal';
import infoIcon from '@assets/icons/info.svg';
import { setDefaultLocale } from 'react-datepicker';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';

const dataDummy = [
  {
    category: 'oli',
    sku: '8567348951',
    product: 'Paket Oli Shell Helix Ultra 5w10',
    jumlah: '2',
    price: '30000',
    note: null,
    approve: null,
  },
  {
    category: 'oli',
    sku: '8567348952',
    product: 'Paket Oli Shell Helix Ultra 5w10',
    jumlah: '2',
    price: '30000',
    note: null,
    approve: null,
  },
  {
    category: 'oli',
    sku: '8567348953',
    product: 'Paket Oli Shell Helix Ultra 5w10',
    jumlah: '2',
    price: '30000',
    note: null,
    approve: null,
  },
  {
    category: 'oli',
    sku: '8567348954',
    product: 'Paket Oli Shell Helix Ultra 5w10',
    jumlah: '2',
    price: '30000',
    note: null,
    approve: null,
  },
];

function BookingsEstimationPrice() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const { id } = useParams();
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [totalPrice, setTotalPrice] = useState();
  const [bookingStatus, setBookingStatus] = useState();
  const [bookingOrigin, setBookingOrigin] = useState();
  const [hideButton, setHideButton] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [statusOptions, setStatusOptions] = useState([]);
  const [control, setControl] = useState({});
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});
  const [checked, setChecked] = useState(false);
  const [total, setTotal] = useState(100000);

  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [countApproval, setCountApproval] = useState({});
  const [userInfo, setUserInfo] = useState({});

  const toggle = () => setModal(!modal);
  const handleOpenModal = (item) => {
    let dataModal = {
      id: item?.id,
      note: item?.note,
      product: item?.name,
      hideButton: hideButton,
    };

    setModal(true);
    setDataModal(dataModal);
  };

  const goToDetailPage = () => {
    history.push(`/bookings/${id}`);
  };

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Booking / Order',
        active: false,
        url: '/bookings',
      },
      {
        id: 2,
        label: `Detail Booking`,
        active: false,
        url: `/bookings/${id}`,
      },
      {
        id: 3,
        label: `Persetujuan Estimasi Harga`,
        active: true,
        url: `/bookings/${id}/estimation`,
      },
    ];
  };

  const allowPermission = (menu) => {
    return !!(generatePermission('booking', menu) || generatePermission('booking', 'all'));
  };

  const allowShow = allowPermission('all') || allowPermission('estimationshow');
  const allowApproval = allowPermission('all') || allowPermission('estimationapproval');

  const customStylesHeaderTable = {
    rows: {
      style: {
        minHeight: '80px', // override the row height
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        '&:last-of-type': {
          justifyContent: 'center',
          position: 'sticky',
          right: '0',
          backgroundColor: '#5c0ed9',
        },
      },
    },
    cells: {
      style: {
        // paddingTop: '25px',
        // alignItems: 'start',
        // paddingLeft: '8px', // override the cell padding for data cells
        // paddingRight: '8px',
        '&:last-of-type': {
          position: 'sticky',
          right: '0',
        },
      },
    },
  };

  const defaultCustom = {
    rows: {
      style: {
        minHeight: '80px', // override the row height,
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.is_approve == true,
      style: {
        backgroundColor: 'rgba(63, 195, 128, 0.1)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: (row) => row?.is_approve == false,
      style: {
        backgroundColor: 'rgba(255, 2, 0, 0.1)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: (row) => row?.item_details?.length > 0 && row?.is_approve == false,
      style: {
        minHeight: '150px',
        backgroundColor: 'rgba(255, 2, 0, 0.1)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: (row) => row?.item_details?.length > 0 && row?.is_approve == true,
      style: {
        minHeight: '150px',
        backgroundColor: 'rgba(63, 195, 128, 0.1)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: (row) => row?.item_details?.length > 0 && row?.is_approve == null,
      style: {
        minHeight: '150px',
      },
    },
  ];

  let dataFilter = [
    {
      id: 'filter_text_filter',
      type: 'text',
      title: 'filter',
      name: 'filter',
      name_field: 'filter',
      value: '',
      placeholder: 'Cari SKU/Nama Paket/Produk',
    },
  ];

  const handleOmit = (status) => {
    if (status == 'waiting' && allowApproval) {
      if (totalPrice >= 10000000 && !userInfo?.user_type?.name.includes('Admin')) {
        handleResultNotification(true, 'failed', `Untuk transaksi >10 juta rupiah hanya bisa diproses oleh Admin`);
        setHideButton(true);
      } else {
        setHideButton(false);
      }
    } else {
      setHideButton(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: 'Kategori',
        selector: (row) => row?.category?.name ?? '-',
        minWidth: '130px',
      },
      {
        name: 'SKU',
        selector: (row) => row?.sku ?? '-',
        cell: (row) => (row?.sku?.length > 0 ? row?.sku : row?.last ? '' : '-'),
        minWidth: '160px',
        sortable: true,
      },
      {
        name: 'Produk/Paket/Servis',
        minWidth: '250px',
        selector: (row) => row?.name ?? '-',
        cell: (row) =>
          row?.item_details == 0 ? (
            <div className="mb-0">{row?.name ?? '-'}</div>
          ) : (
            <p className="my-1">
              <div className="mb-2">{row?.name ?? '-'}</div>
              {row?.item_details?.map((item, index) => {
                return (
                  <div key={index} className="mb-0 ml-2 font-10 text-grey">
                    - {item?.name ?? '-'}
                  </div>
                );
              })}
            </p>
          ),
        sortable: true,
        wrap: true,
      },
      {
        name: 'Jumlah',
        selector: (row) =>
          row?.last ? (
            <>
              <div className="text-bold mb-0">{row?.qty ?? '-'}</div>
            </>
          ) : (
            row?.qty ?? '-'
          ),
        minWidth: '120px',
        wrap: 'false',
        sortable: true,
      },
      {
        name: 'Harga',
        selector: (row) =>
          row?.last ? <span className="font-10 font-italic text-grey"></span> : `Rp. ${Helper.formatMoney(row?.price)}`,
        minWidth: '200px',
      },
      {
        name: 'Diskon',
        selector: (row) => `${row?.last ? '' : row?.discount ? row?.discount + '%' : 0 + '%'}`,
        minWidth: '100px',
      },
      {
        name: 'Sub Total',
        selector: (row) =>
          `${typeof row?.qty == 'string' ? (row?.qty.includes('PPH') ? '- ' : '') : ''} Rp. ${Helper.formatMoney(
            row?.sub_total
          )}`,
        minWidth: '150px',
      },
      {
        name: 'Catatan',
        selector: (row) => row?.note ?? '',
        cell: (row) => {
          if (row?.last) {
            return '';
          } else {
            return (
              <>
                <span className={`mr-3 ${row?.note?.length > 30 ? 'cut-text' : ''}`}>
                  {row?.note?.length > 0 ? row?.note : 'belum ada catatan'}
                </span>
                {row?.status?.slug == 'waiting' && !hideButton && (
                  <img
                    src={editIcon}
                    height={16}
                    alt=""
                    className="mr-3 zoom-icon"
                    onClick={() => handleOpenModal(row)}
                  />
                )}
                {hideButton && row?.note?.length > 0 && (
                  <i className="mdi mdi-eye mr-3 show-icon" onClick={() => handleOpenModal(row)}></i>
                )}
              </>
            );
          }
        },

        minWidth: '250px',
        wrap: 'false',
        sortable: true,
      },
      {
        name: 'Persetujuan',
        id: 'action',
        omit: hideButton,
        selector: (row) => row?.is_approve,
        cell: (row) => {
          if (row?.last) {
            return '';
          } else {
            return (
              <>
                {row?.is_approve == true && !row?.approved && (
                  <>
                    <img
                      className="pr-3 icon-new-tab"
                      src={CloseBorder}
                      onClick={() => {
                        setControl({ value: false, slug: row?.id });
                        handleOpenModal(row);
                      }}
                    />
                    <img className="m-icon-rejected" src={TickBold} />
                  </>
                )}
                {row?.is_approve == false && !row?.approved && (
                  <>
                    <img className="pr-3 m-icon-approved" src={CloseBold} />
                    <img
                      className="icon-new-tab"
                      src={TickBorder}
                      onClick={() => {
                        setControl({ value: true, slug: row?.id });
                      }}
                    />
                  </>
                )}
                {row?.is_approve == null && !row?.approved && (
                  <>
                    <img
                      className="pr-3 icon-new-tab"
                      src={CloseBorder}
                      onClick={() => {
                        setControl({ value: false, slug: row?.id });
                        handleOpenModal(row);
                      }}
                    />
                    <img
                      className="icon-new-tab"
                      src={TickBorder}
                      onClick={() => {
                        setControl({ value: true, slug: row?.id });
                      }}
                    />
                  </>
                )}
              </>
            );
          }
        },
        maxWidth: '120px',
        style: {
          position: '-webkit-sticky',
          position: 'sticky',
          right: 0,
          backgroundColor: '#fcfcfc',
          alignItem: 'left',
        },
      },
    ],
    [hideButton]
  );

  const handleChangeNote = (value, id) => {
    const index = findIndex(data, (value) => {
      return value?.id === id;
    });

    setData((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], note: value };

      return updated;
    });

    const indexDefault = findIndex(defaultData, (value) => {
      return value?.id === id;
    });

    setDefaultData((prev) => {
      const updated = [...prev];
      updated[indexDefault] = { ...updated[indexDefault], note: value };

      return updated;
    });
  };

  const handleChangeApprove = (value, id) => {
    const index = findIndex(data, (value) => {
      return value?.id === id;
    });

    const indexDefault = findIndex(defaultData, (value) => {
      return value?.id === id;
    });

    setDefaultData((prev) => {
      const updated = [...prev];
      updated[indexDefault] = { ...updated[indexDefault], is_approve: value };

      return updated;
    });

    setData((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], is_approve: value };

      return updated;
    });
  };

  useEffect(() => {
    handleChangeApprove(control?.value, control?.slug);
  }, [control]);

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const totalApprovedSKU = () => {
    let approved = 0;
    data.map((item) => {
      if (item?.is_approve) {
        approved++;
      }
    });

    return approved;
  };

  const checkApprove = () => {
    let approved = 0;
    data.map((item) => {
      if (item?.is_approve !== null) {
        approved++;
      }
    });

    return approved !== data?.length - 4 ? true : false;
  };

  const hanldeConfirmationModal = () => {
    if (checkApprove()) {
      handleResultNotification(true, 'failed', `Harap isi persetujuan setiap SKU`);
    } else if (!checkApprove() && checked) {
      setConfirmModal(!confirmModal);

      let approved = 0;
      data.map((item) => {
        if (item?.is_approve == true) {
          approved++;
        }
      });

      let rejected = 0;
      data.map((item) => {
        if (item?.is_approve == false) {
          rejected++;
        }
      });

      let countApproval = {
        approved,
        rejected,
      };

      setCountApproval(countApproval);
    } else if (!checked) {
      handleResultNotification(true, 'failed', `Harap setujui Syarat & Ketentuan`);
    }
  };

  async function onSubmitEstimationPrice() {
    setConfirmModal(false);

    if (checked) {
      setLoading(true);
      setDisableForm(true);

      let result = [];

      const resultProduct = data?.map((item, index) => {
        if (item?.item_details?.length == 0) {
          return {
            id: item?.id,
            is_approve: item?.is_approve,
            note: item?.note ?? '',
          };
        }
      });

      data?.map((item, index) => {
        if (item?.item_details?.length > 0) {
          item?.item_details?.map((dt, index) => {
            let detail = {
              id: dt?.id,
              is_approve: item?.is_approve,
              note: item?.note ?? '',
            };
            result?.push(detail);
          });
        } else {
          let detail = {
            id: item?.id,
            is_approve: item?.is_approve,
            note: item?.note ?? '',
          };
          result?.push(detail);
        }
      });

      const payload = {
        update_event: 'approval-estimation',
        service_details: result.filter((item) => item.id !== null),
      };

      try {
        const response = await api.put(`v2/fleet/booking/${id}`, payload);
        if (response?.data) {
          setLoading(false);
          handleResultNotification(true, 'success', `Berhasil - Estimasi harga ${totalApprovedSKU()} SKU disetujui`);
          setTimeout(() => {
            setDisableForm(false);
            history.push(`/bookings/${id}`);
          }, 1000);
        }
      } catch (err) {
        const error = err?.response?.data?.error?.message;
        setLoading(false);
        setDisableForm(false);
        handleResultNotification(true, 'failed', `Gagal - Estimasi harga SKU gagal disetujui, ` + error);
      }
    } else {
      setTimeout(() => {
        setDisableForm(false);
        handleResultNotification(true, 'failed', `Gagal - Setujui Syarat & Ketentuan Otoklix`);
      }, 300);
    }
  }

  async function fetchEstimation(id) {
    setLoading(true);

    try {
      const response = await api.get(`v2/fleet/booking/${id}/estimation`);
      mappingData(response?.data?.data ?? {});
      setBookingStatus(response?.data?.data?.status);
      setBookingOrigin(response?.data?.data?.booking_origin);
      setTotalPrice(response?.data?.data?.total_price);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan data Estimasi, ` + error);
    }
  }

  const mappingData = (data) => {
    let totalPrice = {
      id: null,
      category: { slug: '', name: '' },
      sku: '',
      name: '',
      qty: 'TOTAL',
      price: '',
      discount: null,
      sub_total: data?.total_price,
      note: null,
      is_approve: null,
      last: true,
      status: data?.status,
      approved: true,
    };
    let subtotalPrice = {
      id: null,
      category: { slug: '', name: '' },
      sku: '',
      name: '',
      qty: 'SUBTOTAL',
      price: '',
      discount: null,
      sub_total: data?.price_before_tax,
      note: null,
      is_approve: null,
      last: true,
      status: data?.status,
      approved: true,
    };
    let taxPrice = {
      id: null,
      category: { slug: '', name: '' },
      sku: '',
      name: '',
      qty: 'PPN (11%)',
      price: '',
      discount: null,
      sub_total: data?.tax,
      note: null,
      is_approve: null,
      last: true,
      status: data?.status,
      approved: true,
    };
    let serviceTaxPrice = {
      id: null,
      category: { slug: '', name: '' },
      sku: '',
      name: '',
      qty: 'PPH (2%)',
      price: '',
      discount: null,
      sub_total: data?.service_tax,
      note: null,
      is_approve: null,
      last: true,
      status: data?.status,
      approved: true,
    };
    const transformedData = map(data?.service_details?.products, (item) => {
      return {
        id: item?.id ?? item?.booking_package_id,
        category: item?.category,
        sku: item?.sku ?? '',
        name: item?.name ?? '',
        qty: item?.qty ?? '1.00',
        price: item?.price,
        discount: item?.discount_percentage,
        sub_total: item?.sub_total,
        note: item?.note,
        is_approve: item?.is_approve,
        last: false,
        status: data?.status,
        approved: item?.is_approve == true ? true : false,
        item_details: item?.item_details,
      };
    });
    transformedData.push(subtotalPrice, taxPrice, serviceTaxPrice, totalPrice);
    setDefaultData(transformedData);
    setData(transformedData);
  };

  const fetchUserInfo = () => {
    const params = {
      keyword: '',
    };

    return api
      .get(`v2/fleet/auth/`)
      .then((response) => {
        setUserInfo(response?.data?.data);
      })
      .catch(() => {
        handleResultNotification(true, 'failed', `Gagal - Customer info gagal didapatkan`);
      });
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchBookings();
  };

  const handleMethodfetchBookings = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchEstimation(id);
    }
  };

  const resetBookings = () => {
    setResetSorting(true);
    setSortingData({});
  };

  useEffect(() => {
    if (page && pageLimit) {
      fetchEstimation(id);
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    setFilters(dataFilter);
  }, [statusOptions]);

  useEffect(() => {
    handleOmit(bookingStatus?.slug);
  }, [bookingStatus, totalPrice, userInfo]);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (
      bookingStatus?.slug === 'review' ||
      bookingStatus?.slug === 'service-done' ||
      bookingStatus?.slug === 'finish' ||
      bookingStatus?.slug === 'cancel' ||
      bookingOrigin === 'intools'
    ) {
      history.push('/404');
    }
  }, [bookingStatus, bookingOrigin]);

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <>
        <Wrapper
          customContentClassName="px-5"
          breadcrumbData={generateBreadcrumb()}
          customBreadcrumbClassName={'pb-0 pt-3'}
          showNotification={hasNotif}
          notification={notification}
          onCloseNotification={handleCloseNotification}
        >
          <EstimationNoteModal
            modal={modal}
            toggle={toggle}
            data={dataModal}
            onSubmit={(value) => handleChangeNote(value, dataModal?.id)}
          />
          <ConfirmationModal
            modal={confirmModal}
            modalWidth={'390'}
            buttonWidth={'50'}
            buttonClose={'Batal'}
            buttonConfirm={'Kirim'}
            toggle={() => setConfirmModal(!confirmModal)}
            header={`Konfirmasi Pengajuan Anda`}
            subHeader={`Apakah Anda yakin ingin menyetujui ${countApproval?.approved ?? 0} SKU dan menolak ${
              countApproval?.rejected ?? 0
            } SKU ?`}
            isDisabled={disableForm}
            onConfirm={() => onSubmitEstimationPrice()}
            toggleClose={() => setConfirmModal(!confirmModal)}
          ></ConfirmationModal>
          <Row className="mb-3 mt-0">
            <Col xs={12} md={4}>
              <h3>Persetujuan Estimasi Harga</h3>
            </Col>
            <Col xs={12} md={8} className=""></Col>
          </Row>
          {data?.length > 0 && (
            <>
              <Row className="p-0 my-4" style={{ backgroundColor: 'white' }}>
                <Col sm={12} md={12} className="p-0 mb-0">
                  <DataTable
                    persistTableHead
                    highlightOnHover
                    noHeader={true}
                    progressPending={loading}
                    data={allowShow ? data : []}
                    columns={allowShow && bookingStatus ? columns : []}
                    className="table dt-responsive table-custom mb-0"
                    noDataComponent={noDataComponent}
                    progressComponent={<Loading />}
                    customStyles={hideButton ? defaultCustom : customStylesHeaderTable}
                    conditionalRowStyles={conditionalRowStyles}
                  />
                </Col>
              </Row>
              <Row className="py-2 mb-5 card-tnc">
                <Col className="my-2 mx-0">
                  <div className="d-flex">
                    <img src={infoIcon} alt="" height={'18px'} className="mr-1" />
                    <div className="text-tnc">Syarat & Ketentuan:</div>
                  </div>
                  <ol className="text-grey mt-2 mb-3" style={{ marginLeft: '-22px' }}>
                    <li>
                      Biaya jasa dan/atau barang yang tertera pada estimasi pengerjaan yang tercantum merupakan taksiran
                      dan dapat berubah sampai SPK Perusahaan diterima oleh Otoklix.
                    </li>
                    <li>
                      Apabila unit kendaraan Perusahaan tidak kunjung datang Bengkel dalam jangka waktu maksimal 14
                      (empat belas) hari kalender setelah SPK terbit, maka Otoklix berhak menerbitkan invoice tagihan
                      produk tanpa kedatangan unit kendaraan Perusahaan.
                    </li>
                    <li>
                      Apabila Perusahaan memutuskan untuk membatalkan pengerjaan setelah dilakukan pengecekan, maka
                      biaya yang timbul dari proses pengecekan tersebut akan tetap ditagihkan kepada Perusahaan.
                    </li>
                    <li>
                      Pengerjaan yang diperintahkan oleh Perusahaan kepada Bengkel di luar estimasi dan tanpa
                      sepengetahuan dan/atau persetujuan tertulis dari Otoklix, maka segala biaya yang timbul akan
                      menjadi tanggung jawab penuh Perusahaan terhadap Bengkel.
                    </li>
                    <li>
                      Mohon untuk tidak meninggalkan barang berharga di dalam kendaraan. Otoklix tidak bertanggung jawab
                      atas segala kehilangan dan/atau kerusakan barang berharga yang terjadi pada saat pengerjaan.
                    </li>
                    <li>Syarat & ketentuan selengkapnya mengenai pembayaran servis diatur dalam invoice resmi.</li>
                    <li>Dokumen ini bersifat rahasia dan tidak dizinkan untuk disebarluaskan kepada pihak manapun.</li>
                  </ol>
                  {bookingStatus?.slug == 'waiting' && !hideButton && (
                    <div className="d-flex ml-4">
                      <Input
                        type="checkbox"
                        checked={checked}
                        onClick={() => setChecked(!checked)}
                        invalid={true}
                        disabled={disableForm}
                      />
                      <span className="mt-1">Saya setuju dengan syarat dan ketentuan berlaku</span>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Wrapper>
        {data?.length > 0 && (
          <div
            className="d-flex justify-content-end"
            style={{
              backgroundColor: 'white',
              position: 'sticky',
              bottom: '0px',
              marginBottom: '-10px',
              padding: '10px 20px 10px 20px',
              width: '100%',
            }}
          >
            <Button className={`${hideButton ? 'mr-4' : ''} button-outline red`} onClick={goToDetailPage}>
              Kembali
            </Button>
            {bookingStatus?.slug == 'waiting' && !hideButton && (
              <Button
                className="mr-4 ml-2 btn-export-finance w-65"
                color="purple"
                disabled={disableForm}
                onClick={() => hanldeConfirmationModal()}
              >
                Submit
              </Button>
            )}
          </div>
        )}
      </>
    );
  };

  return renderContent();
}

export default BookingsEstimationPrice;
