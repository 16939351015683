import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import ExportButton from '@components/export/ExportButton';
import ActionFilterByText from '@components/filters/ActionFilterByText';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';
import { Button } from 'reactstrap';

function customer() {
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCustomers(1);
  }, []);

  async function loadCustomers(page) {
    setLoading(true);
    const response = await api.get(
      `v2/fleet/customers/get-customer-by-filter/?name=&page=${page}&limit=${perPage}&query=${searchText}`
    );

    setData(response.data.data);
    setTotalRows(response.data.pagination.total_rows);
    setLoading(false);
  }

  const handlePageChange = (page) => {
    loadCustomers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await api.get(
      `v2/intools/customers/get-customer-by-filter/?name=&page=${page}&limit=${newPerPage}&query=${searchText}`
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const openUserDetail = (row) => {
    if (generatePermission('customer', 'detail')) {
      history.push(`/customer/${row.id}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: 'Phone Number',
        selector: 'user.username',
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'Name',
        selector: 'name',
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'Email',
        selector: 'email',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Current Otopoints',
        cell: (row) => `Rp. ${Helper.formatMoney(row?.total_point?.total_point)}`,
        minWidth: '200px',
      },
    ],
    []
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleSearch = () => {
      loadCustomers(1);
    };

    return (
      <ActionFilterByText
        searchText={searchText}
        onChangeText={(e) => setSearchText(e.target.value)}
        onSearch={handleSearch}
        placeholder="Phone Number or Name or Email"
        classWidth="width-250"
      />
    );
  }, [searchText]);

  return (
    <Wrapper title="List Customer" className="wrapperContent">
      <DataTable
        persistTableHead
        highlightOnHover
        pagination
        subHeader
        progressPending={loading}
        data={data}
        columns={generatePermission('customer', 'list') ? columns : []}
        className="table table-bordered dt-responsive"
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressComponent={generatePermission('customer', 'list') ? <Loading /> : <></>}
        actions={
          <div className="section-action">
            <ExportButton
              hasActionExport={generatePermission('customer', 'export')}
              data={data}
              title="Export CSV"
              keys={['user.username', 'name', 'email']}
              header={['phone', 'name', 'email']}
            />
            {generatePermission('customer', 'create') && (
              <Button className="add" onClick={() => history.push('/customer/create')}>
                Create Customer
              </Button>
            )}
          </div>
        }
        subHeaderComponent={generatePermission('customer', 'search') ? subHeaderComponentMemo : ''}
        onRowClicked={(row) => openUserDetail(row)}
      />
    </Wrapper>
  );
}

export default customer;
