import React from 'react';
import ReactDOM from 'react-dom';
import Popup from 'react-popup';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from '@store/configureStore';
import './styles';
import config from '@utils/config';
import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: config.posthog_host,
};

const toolbarJSON = new URLSearchParams(window.location.hash.substring(1)).get('__posthog');
if (toolbarJSON) {
  posthog.loadToolbar(JSON.parse(toolbarJSON));
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {config.app_environment === 'production' ? (
        <PostHogProvider apiKey={config.posthog_key} options={options}>
          <PersistGate loading={null} persistor={persistor}>
            <Popup closeBtn={true} closeHtml={null} defaultOk="Ok" />
            <App />
          </PersistGate>
        </PostHogProvider>
      ) : (
        <PersistGate loading={null} persistor={persistor}>
          <Popup closeBtn={true} closeHtml={null} defaultOk="Ok" />
          <App />
        </PersistGate>
      )}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
