import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, FormText, Label, Row } from 'reactstrap';
import SelectAsyncField from '@components/field/SelectAsyncField';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import { HourOptions } from '@utils/Constants';
import GMap from '../maps/Gmap';
import moment from 'moment';
import GMapsModal from '@components/modal/GMapsModal';
import { has } from 'lodash';

const BookingCustomerDetailForms = (props) => {
  const {
    isOpen,
    toggle,
    userInfo,
    accountType,
    mapValue,
    handleMap,
    onClickCreateNewUser,
    handleClickMap,
    handleClickPUDO,
    handleClickECommerce,
    isPUDO,
    isECommerce,
    onLoadUserDetail,
    handleChangeUser,
    disableCar,
    isCarLoading,
    loadUserCar,
    handleChangeCar,
    disableAll,
    disableTime,
    selectedUser,
    selectedCar,
    defaultCarOptions,
    bookingSourceOptions,
    handleChangeBookingSource,
    selectedBookingSource,
    disableECommerce,
    handleChangeCarMilleage,
    carMilleage,
    handleChangeAddressDetail,
    detailAddress,
    handleChangeFreshDeskLink,
    freshDeskLink,
    handleChangeDate,
    selectedDate,
    handleChangeTime,
    selectedTime,
    errorUser,
    errorCar,
    errorDetailAddress,
    errorMileage,
    errorBookingSource,
    errorDate,
    errorTime,
    errorMap,
  } = props;

  // console.log('booking customer detail => ', selectedCar, selectedBookingSource);

  const today = new Date().toISOString().slice(0, -14);

  const [maps, setMaps] = useState();
  const [showTime, setShowTime] = useState(true);
  const [hasGMapModal, setHasGMapModal] = useState(false);

  // const getMaps = (e) => {
  //   handleMap(e);
  // };

  const handleClickMaps = (e) => {
    if (!disableAll) toggleGMapModal();
  };

  const toggleGMapModal = () => setHasGMapModal(!hasGMapModal);

  const isOptionDisabled = (e) => {
    if (selectedDate == moment(new Date()).format('YYYY-MM-DD')) {
      return e?.value <= moment(new Date()).format('HH:MM');
    }
    return false;
  };

  useEffect(() => {
    if (hasGMapModal) setMaps(mapValue);
  }, [hasGMapModal]);

  useEffect(() => {
    setShowTime(false);
    setTimeout(() => {
      setShowTime(true);
    }, 100);
  }, [selectedDate]);

  return (
    <>
      <Card>
        <CardHeader className="text-white mt-0">Detail Customer</CardHeader>
        <CardBody>
          <FormGroup row>
            <Label lg={3} className="label-required">
              User
            </Label>
            <SelectAsyncField
              colWidth={9}
              selectName="user"
              isDisabled={true}
              value={{ value: '', label: `${userInfo?.name ?? ''}  - ${userInfo?.phone_number ?? ''}` }}
              // loadOptions={onLoadUserDetail}
              getOptionLabel={(e) =>
                e.isB2B ? (
                  <span>
                    <span
                      style={{
                        backgroundColor: '#E0CFFC',
                        color: '#6610F2',
                        fontSize: 10,
                        fontWeight: 400,
                        borderRadius: 4,
                        padding: 4,
                        marginRight: 4,
                      }}
                    >
                      B2B
                    </span>
                    {e.label}
                  </span>
                ) : (
                  e.label
                )
              }
              getOptionValue={(e) => e.value}
              // onChange={handleChangeUser}
              defaultOptions={[]}
              // isCreate
              // textLabelCreate="User Baru"
              // onClickCreate={() => onClickCreateNewUser('new-user')}
              // placeholder="Pilih Customer..."
              // invalid={!!errorUser}
              errorMessage={errorUser}
            />
          </FormGroup>

          {/* {accountType === 'b2c' && (
            <FormGroup row>
              <Label lg={3}>E-Commerce</Label>
              <InputField
                colWidth={9}
                inputType="checkbox"
                onClick={handleClickECommerce}
                checked={isECommerce}
                disabled={isPUDO || disableECommerce}
              />
            </FormGroup>
          )} */}

          <FormGroup row>
            <Label lg={3} className={!isECommerce ? 'label-required' : ''}>
              No. Plat Mobil
            </Label>
            <SelectField
              colWidth={9}
              disabled={disableCar}
              value={selectedCar}
              options={isCarLoading ? [] : defaultCarOptions}
              getOptionLabel={(e) => `${e?.license_plate} - ${e?.brand}`}
              getOptionValue={(e) => e?.id}
              onChange={handleChangeCar}
              placeholder="Pilih Mobil..."
              invalid={!!errorCar}
              errorMessage={errorCar}
              isLoading={isCarLoading}
            />
            {/* <SelectAsyncField
                colWidth={9}
                isDisabled={disableCar}
                value={selectedCar}
                defaultOptions={defaultCarOptions}
                getOptionLabel={(e) => `${e?.license_plate} - ${e?.brand}`}
                getOptionValue={(e) => e?.id}
                loadOptions={loadUserCar}
                onChange={handleChangeCar}
                // isCreate
                // textLabelCreate="Mobil Baru"
                // onClickCreate={onClickCreateNewUser}
                placeholder="Pilih Mobil..."
                invalid={!!errorCar}
                errorMessage={errorCar}
              /> */}
          </FormGroup>

          <FormGroup row>
            <Label lg={3}>KM Mobil</Label>
            <InputField
              colWidth={9}
              disabled={disableAll}
              value={carMilleage?.formatMoneyString}
              onChange={handleChangeCarMilleage}
              placeholder="Ex: 20000 KM"
              suffix={'KM'}
              invalid={!!errorMileage}
              errorMessage={errorMileage}
            />
          </FormGroup>

          {/* <FormGroup row>
            <Label lg={3}>Otoklix Pick Up (PUDO)</Label>
            <InputField
              colWidth={9}
              inputType="checkbox"
              onClick={handleClickPUDO}
              checked={isPUDO}
              disabled={isECommerce || disableAll}
            />
          </FormGroup> */}

          <div className="gmap-wrapper">
            <GMap
              disabled={disableAll}
              label="Lokasi"
              labelWidth={3}
              colWidth={9}
              isDraggable={false}
              handleMap={handleMap}
              latitude={mapValue?.latitude}
              longitude={mapValue?.longitude}
              handleClickMap={handleClickMap}
              handleMapsModal={handleClickMaps}
              withButtonModal
              withAutoComplete={true}
              labelInput={mapValue?.label}
              formText={mapValue?.street_address}
              mapHeight="88px"
              draggable={false}
              errorMessage={errorMap}
            />
            {/* <GoogleMap
              disabled={disableAll}
              labelWidth={0}
              colWidth={12}
              latitude={mapValue?.latitude}
              longitude={mapValue?.longitude}
              // handleAutoComplete={handleAutoComplete}
              handleClickMap={handleClickMap}
            /> */}
          </div>

          <GMapsModal
            isOpen={hasGMapModal}
            toggle={toggleGMapModal}
            lat={maps?.latitude}
            lng={maps?.longitude}
            labelInput={maps?.label}
            handleMap={handleMap}
            mapHeight={'500px'}
          />

          {/* <FormGroup row>
            <Label lg={3}>Lokasi</Label>
            <Col lg={9}>
              <GMap
                labelWidth={0}
                colWidth={12}
                latitude={mapValues?.latitude}
                longitude={mapValues?.longitude}
                handleAutoComplete={handleAutoComplete}
                handleClickMap={handleChangeMap}
                mapHeight="250px"
                formText="Monas, Jakarta Pusat"
              />
            </Col>
          </FormGroup> */}

          <FormGroup row className="mt-4">
            <Label lg={3} className={isPUDO ? 'label-required' : ''}>
              Detail Alamat Lokasi Mobil
            </Label>
            <InputField
              colWidth={9}
              inputType="textarea"
              disabled={disableAll}
              value={detailAddress}
              onChange={handleChangeAddressDetail}
              placeholder="Ex: Jl. Radio Dalam"
              invalid={!!errorDetailAddress && isPUDO}
              errorMessage={errorDetailAddress}
            />
          </FormGroup>

          {/* <FormGroup row>
            <Label lg={3} className="label-required">
              Booking Source
            </Label>
            <SelectField
              colWidth={9}
              disabled={disableAll}
              value={selectedBookingSource}
              options={bookingSourceOptions}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              onChange={handleChangeBookingSource}
              placeholder="Pilih Booking Source..."
              invalid={!!errorBookingSource}
              errorMessage={errorBookingSource}
            />
          </FormGroup> */}

          {/* <FormGroup row>
            <Label lg={3}>Freshdesk ticket link</Label>
            <InputField
              colWidth={9}
              disabled={disableAll}
              value={freshDeskLink}
              onChange={handleChangeFreshDeskLink}
              placeholder="Ex: freshdesk.com"
            />
          </FormGroup> */}

          <Row>
            <Col>
              <FormGroup row>
                <Label lg={6} className="label-required">
                  Tanggal
                </Label>
                <InputField
                  colWidth={6}
                  inputType="date"
                  inputMin={moment(new Date()).format('YYYY-MM-DD')}
                  placeholder="Tanggal"
                  disabled={disableAll}
                  onChange={handleChangeDate}
                  value={selectedDate}
                  invalid={!!errorDate}
                  errorMessage={errorDate}
                />
              </FormGroup>
            </Col>
            {!showTime && (
              <Col>
                <FormGroup row>
                  <Label lg={3} className="label-required">
                    Jam
                  </Label>
                  <SelectField
                    colWidth={9}
                    options={HourOptions}
                    placeholder="hh:mm"
                    disabled={disableTime || disableAll}
                    onChange={handleChangeTime}
                    value={selectedTime}
                    isOptionDisabled={isOptionDisabled}
                    invalid={!!errorTime}
                    errorMessage={errorTime}
                  />
                </FormGroup>
              </Col>
            )}
            {showTime && (
              <Col>
                <FormGroup row>
                  <Label lg={3} className="label-required">
                    Jam
                  </Label>
                  <SelectField
                    colWidth={9}
                    options={HourOptions}
                    placeholder="hh:mm"
                    disabled={disableTime || disableAll}
                    onChange={handleChangeTime}
                    value={selectedTime}
                    isOptionDisabled={isOptionDisabled}
                    invalid={!!errorTime}
                    errorMessage={errorTime}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default BookingCustomerDetailForms;
