import React, { useState } from 'react';
import { FormGroup, Label, Col, FormText, Button } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import Helper from '@helpers/Helper';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GMAPS_API_KEY, gmapConfig } from '@utils/Constants';

const Marker = () => <div className="marker"></div>;

const GMap = ({
  disabled,
  isDraggable = true,
  handleMap,
  latitude,
  longitude,
  labelWidth,
  colWidth,
  handleMapsModal,
  withButtonModal = false,
  // handleAutoComplete,
  withAutoComplete = true,
  handleClickMap,
  draggable = true,
  formText,
  label,
  labelClassName = '',
  mapHeight = '400px',
  mapWidth = '100%',
  errorMessage,
  labelInput,
}) => {
  const [values, setValues] = useState({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [],
    zoom: 15,
    address: '',
    draggable,
    lat: null,
    lng: null,
  });

  const apiHasLoaded = (map, maps) => {
    setValues({
      ...values,
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
      lat: latitude,
      lng: longitude,
      center: [latitude, longitude],
    });
  };

  const [mapValues, setMapValues] = useState({
    latitude: latitude,
    longitude: longitude,
  });

  const handleChangeCoordinate = (coord, streetAddress, label) => {
    const mapValue = {
      latitude: coord?.lat,
      longitude: coord?.lng,
      street_address: streetAddress,
      error_street_address: '',
      label: label,
    };

    handleMap(mapValue);
    // setMapValues(mapValue);
  };

  const handleAutoComplete = async (value) => {
    const response = await Geocode.fromAddress(value?.label);
    const newCoord = Helper.getCoordByAddress(response);

    // console.log('handleAutoComplete => ', newCoord);
    Geocode.fromLatLng(newCoord.lat, newCoord.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        handleChangeCoordinate(newCoord, address, value);
      },
      (error) => {
        handleChangeCoordinate(newCoord, '');
      }
    );
  };

  const customControlSelected = false;
  const customControlKeySelected = '';
  const customControlValueSelected = null;
  const customControlColorLabelSelected = '#fff';
  const customControlColorTextSelected = '#fff';

  const controlBorderStyle = (state) => {
    let style = '';
    if (state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? '1px solid ' + customControlColorTextSelected
          : '1px solid #6610F2'
        : '1px solid #6610F2';
    if (!state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? '1px solid ' + customControlColorTextSelected
          : '1px solid #CED4DA'
        : '1px solid #CED4DA';
    if (errorMessage) style = '1px solid red';
    if (state?.isDisabled) style = '1px solid #CED4DA';

    return style;
  };

  const dropdownIndicatorStyle = (state) => {
    let style = '';
    if (state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#6610F2'
        : '#6610F2';
    if (!state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : 'hsl(0,0%,80%)'
        : 'hsl(0,0%,80%)';
    if (errorMessage) style = 'red';
    if (state?.isDisabled) style = '';

    return style;
  };

  const customStyles = {
    indicatorSeparator: (styles) => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state?.isSelected ? '#6610F2' : state?.isFocused ? '#E0CFFC' : '#fff',
      color: state?.isSelected ? '#fff' : state?.isFocused ? '#6610F2' : '#6C757D',
      cursor: state?.isFocused ? 'pointer' : '',
      '&:hover': {
        color: state?.isSelected ? '#fff' : '#6610F2',
        backgroundColor: state?.isSelected ? '#6610F2' : '#E0CFFC',
        cursor: 'pointer',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: controlBorderStyle(state),
      borderRadius: '0.25rem',
      backgroundColor: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorLabelSelected
          : state?.isDisabled
          ? '#DEE2E6'
          : '#fff'
        : state?.isDisabled
        ? '#DEE2E6'
        : '#fff',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#052C65'
        : '#052C65',
    }),
    input: (provided) => ({
      ...provided,
      color: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#052C65'
        : '#052C65',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      borderRadius: 4,
      paddingBottom: 0,
      border: '0.5px solid #6610F2',
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#BDBDBD',
        fontWeight: '200',
      };
    },
    multiValue: (provided, state) => {
      return {
        ...provided,
        borderRadius: '0.25rem',
        backgroundColor: state?.isDisabled ? '#68717A' : '#6610F2',
      };
    },
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: state?.isDisabled ? '#68717A' : '#FFFFFF',
      '&:hover': {
        color: '#6610F2',
        backgroundColor: '#E0CFFC',
        borderRadius: '0rem 0.25rem 0.25rem 0rem',
      },
    }),
    clearIndicator: (provided, state) => {
      return {
        ...provided,
        color: clearIndicatorStyle(state),
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        color: dropdownIndicatorStyle(state),
      };
    },
  };

  return (
    <FormGroup row>
      <Label lg={labelWidth ? labelWidth : 3} className={labelClassName}>
        {label}
      </Label>
      <Col lg={colWidth ? colWidth : 9}>
        {withAutoComplete && (
          <div className=" mb-2">
            <GooglePlacesAutocomplete
              apiKey={GMAPS_API_KEY}
              selectProps={{
                value: labelInput,
                onChange: (value) => handleAutoComplete(value, values),
                placeholder: 'cari lokasi . . .',
                isDisabled: disabled,
                styles: customStyles,
              }}
              styles={customStyles}
              minLengthAutocomplete={5}
            />
          </div>
        )}
        <div style={{ height: mapHeight, width: mapWidth }}>
          <GoogleMapReact
            options={gmapConfig}
            center={[latitude, longitude]}
            zoom={values.zoom}
            draggable={isDraggable}
            bootstrapURLKeys={{ key: GMAPS_API_KEY }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
            onClick={(e) => handleClickMap(e)}
          >
            <Marker lat={latitude} lng={longitude} />
          </GoogleMapReact>
        </div>
        {withButtonModal && (
          <Button
            className="btn-purple mt-1"
            style={{ width: '100%' }}
            disabled={disabled}
            onClick={(e) => handleMapsModal(e)}
          >
            Buka Map
          </Button>
        )}
        {formText && <FormText>{formText}</FormText>}

        {errorMessage && <div className="invalid-hour">{errorMessage}</div>}
      </Col>
    </FormGroup>
  );
};

export default GMap;
