import React, { useCallback, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import config from '@utils/config';
import AsyncSelect from 'react-select/async';
import Divider from '@components/divider/Divider';
import API from '@utils/API';
import { UserType } from '@utils/Constants';

const CreateUserModal = (props) => {
  const {
    modal,
    toggle,
    title,
    onSubmit,
    submitLoading,
    modalErrorColor,
    hasError,
    errorMessage,
    submitModalSuccess,
    modalType,
    selectedUser,
    phoneNumberInit,
  } = props;

  const apiv2 = new API('v2');

  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userPhoneNumberSuccess, setUserPhoneNumberSuccess] = useState(false);
  const [userPhoneNumberError, setUserPhoneNumberError] = useState('');
  const [userPhoneNumberLoading, setUserPhoneNumberLoading] = useState(false);
  const [userCarVariant, setUserCarVariant] = useState(null);
  const [userCarTranmission, setUserCarTranmission] = useState(null);
  const [disableUserInput, setDisableUserInput] = useState(false);
  const [isNameNotValid, setIsNameNotValid] = useState(false);
  const [userNameErrorMessage, setUserNameErrorMessage] = useState('');
  const [defaultCarVariantOptions, setDefaultCarVariantOptions] = useState([]);

  const [forms, setForms] = useState({
    fullname: '',
    phonenumber: phoneNumberInit || '',
    email: '',
    userType: 'general',
    carYear: null,
    carVariant: null,
    carLicense: '',
  });

  useEffect(() => {
    if (submitModalSuccess) {
      setForms({
        fullname: '',
        phonenumber: '',
        email: '',
        carYear: null,
        carVariant: null,
        carLicense: '',
      });
    }
  }, [submitModalSuccess]);

  useEffect(() => {
    if (modalType === 'new-car') {
      setDisableUserInput(true);
      setUserPhoneNumber(get(selectedUser, 'phone_number', ''));
      setUserPhoneNumberSuccess(true);
      setForms({
        ...forms,
        fullname: get(selectedUser, 'name', ''),
        phonenumber: get(selectedUser, 'phone_number', ''),
        email: get(selectedUser, 'email', ''),
        userType: get(selectedUser, 'user_type', 'general'),
      });
    } else if (modalType === 'new-user') {
      setDisableUserInput(false);

      setUserPhoneNumber(phoneNumberInit || '');
      setForms({
        fullname: '',
        phonenumber: phoneNumberInit || '',
        email: '',
        userType: 'general',
        carYear: null,
        carVariant: null,
        carLicense: '',
      });
      setUserPhoneNumberSuccess(false);
      if (phoneNumberInit) {
        validatePhoneNumber(phoneNumberInit);
      }
    }
  }, [modalType]);

  const resetCarOptions = () => {
    setUserCarTranmission(null);
    setUserCarVariant(null);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value });

    if (name === 'fullname') {
      const regexName = /^[a-zA-Z\s]*$/;
      const isInputCorrect = regexName.test(value);
      if (isInputCorrect) {
        setIsNameNotValid(false);
        setUserNameErrorMessage('');
      } else {
        setIsNameNotValid(true);
        setUserNameErrorMessage('Name is empty or invalid format');
      }
      setForms({ ...forms, [name]: value });
    } else {
      setForms({ ...forms, [name]: value });
    }
  };

  const handleOnYearChange = (e) => {
    const { name, value } = e.target;
    let newValue = value.replace(/[^0-9+]/g, '');
    if (value.length < 5) {
      setForms({ ...forms, [name]: newValue });
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    let isValidated = true;
    if (phoneNumber.indexOf('+62') !== 0) {
      isValidated = false;
      setUserPhoneNumberError('Format not following +62');
    }
    if (isValidated) {
      setUserPhoneNumberLoading(true);
      apiv2
        .get(`${config.api_url_v2}v2/intools/user-accounts/check-user-number/?phone_number=${phoneNumber}`)
        .then((response) => {
          setUserPhoneNumberError('');
          setUserNameErrorMessage('');
          setUserPhoneNumberSuccess(true);
          setForms((currentValues) => {
            currentValues['phonenumber'] = phoneNumber;
            return currentValues;
          });
        })
        .catch((e) => {
          setUserPhoneNumberError(e.response?.data?.error?.message);
        })
        .finally((e) => {
          setUserPhoneNumberLoading(false);
        });
    }
  };

  const handler = useCallback(debounce(validatePhoneNumber, 400), []);

  const changePhoneNumber = (e) => {
    let newValue = e.target.value.replace(/[^0-9+]/g, '');
    newValue = newValue.replace(/(?!^\+)\+/g, '');
    if (newValue !== userPhoneNumber) {
      setUserPhoneNumber(newValue);
      setUserPhoneNumberError('');
      setUserPhoneNumberSuccess(false);
      handler(newValue);
    }
  };

  const fetchCarVariants = (userInputValue) => {
    if (userInputValue) {
      const url = `${config.api_url_v2}v2/intools/cars/search?q=${userInputValue}`;
      return apiv2.get(url).then((response) => {
        return response.data.data;
      });
    }
  };

  const handleCarTransmissionChange = (item) => {
    setUserCarTranmission(item);
    setForms({ ...forms, carTransmission: item.value });
  };

  const handleCarVariantChange = (item) => {
    setUserCarVariant(item);
    setUserCarTranmission(null);
    setForms({ ...forms, carVariant: item.id, carLicense: '', carYear: '', carTransmission: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetCarOptions();
    onSubmit(modalType, forms, userCarVariant);
  };

  const handleCloseModal = (params) => {
    resetCarOptions();
    toggle(params);
  };

  const hasErrorForm = () => {
    let hasEmptyField = false;
    Object.entries(forms).forEach(([key, value]) => {
      if (value) {
        // pass
      } else {
        if (key !== 'email' && key !== 'carLicense') {
          hasEmptyField = true;
        }
      }
    });

    return hasEmptyField;
  };

  const isButtonDisabled =
    hasErrorForm() || submitLoading || userPhoneNumberLoading || !userPhoneNumberSuccess || isNameNotValid;

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggle('close')}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <Form>
          <Divider title="User" />

          <FormGroup row>
            <Label sm={4} className="required">
              Nomor Telepon
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="phonenumber"
                placeholder="Ex: +628 111 222 333"
                value={userPhoneNumber}
                onChange={changePhoneNumber}
                disabled={disableUserInput}
                required
              />
              {userPhoneNumberError && <div className="pt-1 text-danger">{userPhoneNumberError}</div>}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Nama Lengkap
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="fullname"
                placeholder="Ex: John Doe"
                value={forms.fullname}
                onChange={handleOnChange}
                disabled={disableUserInput}
                invalid={isNameNotValid}
                required
              />
              {isNameNotValid && <div className="pt-1 text-danger">{userNameErrorMessage}</div>}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4}>Email</Label>
            <Col sm={8}>
              <Input
                type="text"
                name="email"
                placeholder="Ex: johndoe@email.com"
                value={forms.email}
                onChange={handleOnChange}
                disabled={disableUserInput}
                required
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Tipe User
            </Label>
            {disableUserInput && (
              <Col sm={8}>
                <Input
                  type="text"
                  name="userType"
                  value={get(UserType, forms?.userType, '-')}
                  onChange={handleOnChange}
                  disabled={disableUserInput}
                  required
                />
              </Col>
            )}

            {!disableUserInput && (
              <Col sm={8} className="col-form-label text-left">
                <FormGroup tag="fieldset">
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        name="userType"
                        value="general"
                        onChange={handleOnChange}
                        checked={forms?.userType == 'general'}
                      />{' '}
                      {get(UserType, 'general', 'Undefined')}
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        name="userType"
                        value="ucd"
                        onChange={handleOnChange}
                        checked={forms?.userType == 'ucd'}
                      />{' '}
                      {get(UserType, 'ucd', 'Undefined')}
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            )}
          </FormGroup>

          <Divider title="Mobil" />

          <FormGroup row>
            <Label sm={4} className="required">
              Mobil
            </Label>
            <Col sm={8}>
              <AsyncSelect
                cacheOptions
                defaultOptions={defaultCarVariantOptions}
                value={userCarVariant}
                getOptionLabel={(e) => `${e.car_model?.model_name} - ${e.variant}`}
                getOptionValue={(e) => e.id}
                loadOptions={fetchCarVariants}
                onChange={handleCarVariantChange}
                menuPlacement="top"
                placeholder="Pilih..."
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4}>Plat Nomor</Label>
            <Col sm={8}>
              <Input
                className="text-uppercase"
                type="text"
                name="carLicense"
                placeholder="Ex: AA 1234 CD"
                value={forms.carLicense}
                onChange={handleOnChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Transmisi
            </Label>
            <Col sm={8}>
              <Select
                onChange={handleCarTransmissionChange}
                value={userCarTranmission}
                options={[
                  {
                    value: 'at',
                    label: 'Automatic',
                  },
                  {
                    value: 'mt',
                    label: 'Manual',
                  },
                ]}
                menuPlacement="top"
                placeholder="Pilih..."
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Tahun
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="carYear"
                placeholder="Ketik tahun .."
                value={forms.carYear}
                onChange={handleOnYearChange}
              />
            </Col>
          </FormGroup>

          {hasError && <div className={`alert alert-${modalErrorColor}`}>{errorMessage}</div>}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" size="lg" onClick={() => handleCloseModal('close')}>
          Tutup
        </Button>{' '}
        <Button color="primary" size="lg" disabled={isButtonDisabled} onClick={handleSubmit}>
          Tambah
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateUserModal;
