import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import Helper from '@helpers/Helper';

const dataDummy = [
  {
    booking_code: '746745674565',
    workshop: 'AA Motor',
    category: 'Oli',
    detail: 'Shell helix',
  },
  {
    booking_code: '746745674565',
    workshop: 'AA Motor',
    category: 'Oli',
    detail: 'Shell helix',
  },
  {
    booking_code: '746745674565',
    workshop: 'AA Motor',
    category: 'Oli',
    detail: 'Shell helix',
  },
  {
    booking_code: '746745674565',
    workshop: 'AA Motor',
    category: 'Oli',
    detail: 'Shell helix',
  },
];

const BookingDetailLog = (props) => {
  const history = useHistory();
  const { detailData } = props;
  const { generatePermission } = userPermission();
  const columns = useMemo(
    () => [
      {
        name: 'Action',
        selector: (row) => (
          <Button className="btn-blue" color="secondary" onClick={() => openBookingDetail(row?.booking_code)}>
            Detail
          </Button>
        ),
        minWidth: '135px',
        wrap: true,
      },
      {
        name: 'Tanggal Dibuat',
        selector: (row) => row?.created_at ?? '-',
        cell: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        minWidth: '130px',
        sortable: true,
        left: true,
      },
      {
        name: 'Booking Code',
        selector: (row) => row?.booking_code,
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'Bengkel',
        selector: (row) => row?.workshop ?? '-',
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'Kategori Servis',
        selector: (row) => (row?.service_categories?.length > 0 ? generateCategory(row?.service_categories) : '-'),
        minWidth: '170px',
        sortable: true,
      },
      {
        name: 'Detail Servis',
        selector: (row) => (row?.service_details?.length > 0 ? generateCategory(row?.service_details) : '-'),
        minWidth: '250px',
        sortable: true,
      },
      {
        name: 'Biaya',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.final_price)}`,
        minWidth: '120px',
      },
    ],
    []
  );

  const openBookingDetail = (id) => {
    history.push(`/bookings/${id}`);
  };

  const generateCategory = (data) => {
    const mapping = data.map((item, index) => {
      if (index < 3) {
        return <div className="mb-4">{item}</div>;
      } else if (index == 3) {
        return <div className="mb-4">{'+' + (data?.length - 3) + ' Lainnya'}</div>;
      }
    });
    return mapping;
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '80px', // override the row height
      },
    },
    // cells: {
    //   style: {
    //     paddingTop: '25px',
    //     alignItems: 'start',
    //     paddingLeft: '8px', // override the cell padding for data cells
    //     paddingRight: '8px',
    //   },
    // },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.service_details?.length > 3,
      style: {
        minHeight: '180px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 3,
      style: {
        minHeight: '150px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 2,
      style: {
        minHeight: '100px',
      },
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Belum Ada Servis untuk Ditampilkan'}</div>;
  }, []);

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Riwayat Servis</CardHeader>
      <CardBody className="pb-0">
        <DataTable
          persistTableHead
          highlightOnHover
          data={detailData ?? []}
          noHeader={true}
          fixedHeader
          fixedHeaderScrollHeight="239px"
          noDataComponent={noDataComponent}
          columns={generatePermission('garage', 'show') ? columns : []}
          className="table table-bordered dt-responsive"
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
        />
      </CardBody>
    </Card>
  );
};

export default BookingDetailLog;
