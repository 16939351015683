import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Button } from 'reactstrap';
import Helper from '@helpers/Helper';
import DetailField from '@components/field/DetailField';

function BookingDetailCar(props) {
  const history = useHistory();
  const { detailData } = props;

  const goToCarHealthInspection = () => {
    if (detailData?.inspection_id) {
      history.push(`/stnk/${detailData?.user_car?.id}/car-health/inspections/detail/${detailData?.inspection_id}`);
    } else {
      history.push(`/stnk/${detailData?.user_car?.id}/car-health/inspections/create/${detailData?.booking_code}`);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Mobil</CardHeader>
        <CardBody className="pb-0">
          <Row>
            <Col>
              <img src={detailData?.image ?? ''} className="user-car" />
              <DetailField title="Mobil " value={detailData?.brand ?? '-'} />
              <DetailField title="Plat Nomor" value={detailData?.lincense_plate || '-'} />
              <DetailField title="Transmisi" value={Helper.getCommonLabel(detailData?.transmission) || '-'} />
              <DetailField title="Tahun" value={detailData?.year || '-'} />
              <DetailField title="Bahan Bakar" value={Helper.getCommonLabel(detailData?.fuel) || '-'} />
              <DetailField title="KM" value={detailData?.mileage || '-'} />
            </Col>
          </Row>
        </CardBody>
        {/* <CardFooter className="p-3">
          <Button color="purple" block onClick={goToCarHealthInspection}>
            <i className="mdi mdi-directions mr-2 mb-1"></i>
            <span>Inspeksi Mobil</span>
          </Button>
        </CardFooter> */}
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailCar;
