import React, { useState, useRef, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { Form, Input, Label, Col, FormFeedback, FormText, InputGroup, InputGroupText } from 'reactstrap';

const InputField = (props) => {
  const {
    value,
    invalid,
    onChange,
    onBlur,
    onClick,
    onKeyDown,
    disabled,
    colWidth,
    inputName,
    inputMin,
    inputMax,
    placeholder,
    errorMessage,
    inputType = 'text',
    inputPattern = '',
    disabledFormText = true,
    filterField = false,
    needOnBlur = false,
    formText,
    formTextLeft,
    className = 'input-field',
    emoji = false,
    suffix = '',
    preffix = '',
  } = props;

  const wrapperRef = useRef();
  const [showEmoji, setShowEmoji] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);

  const handleOnBlur = (e) => {
    setCursorPosition(e?.target?.selectionStart ?? 0);
    if (needOnBlur) onBlur();
  };

  const handleShowEmoji = () => {
    setShowEmoji(!showEmoji);
  };

  const onClickEmoji = (data) => {
    const valueWithEmoji = [value.slice(0, cursorPosition), data?.emoji ?? '', value.slice(cursorPosition)].join('');
    const val = {
      target: {
        value: valueWithEmoji,
      },
    };
    onChange(val);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowEmoji(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Col lg={colWidth}>
      <Form>
        {suffix || preffix ? (
          <InputGroup>
            {preffix && <InputGroupText className="input-group-text-left">{preffix}</InputGroupText>}
            <Input
              type={inputType}
              pattern={inputPattern}
              value={value}
              name={inputName}
              min={inputMin}
              max={inputMax}
              invalid={invalid}
              disabled={disabled}
              onChange={onChange}
              onClick={onClick}
              onKeyDown={onKeyDown}
              onBlur={handleOnBlur}
              placeholder={placeholder}
              className={
                emoji
                  ? `${className} ${preffix && !suffix ? ' with-prefix' : ''}` + ' input-emoji'
                  : `${className} ${preffix && !suffix ? ' with-prefix' : ''}`
              }
            />
            {emoji && (
              <>
                {!showEmoji && <div className="button-emoji show-emoji" onClick={handleShowEmoji}></div>}
                {showEmoji && (
                  <>
                    <div className="button-emoji hide-emoji" onClick={handleShowEmoji}></div>
                    <div className="popup-emoji" ref={wrapperRef}>
                      <EmojiPicker onEmojiClick={onClickEmoji} />
                    </div>
                  </>
                )}
              </>
            )}
            {suffix && <InputGroupText className="input-group-text-right">{suffix}</InputGroupText>}
            <FormFeedback>{errorMessage}</FormFeedback>
            {!disabledFormText && <FormText className="muted text-right">{formText}</FormText>}
            {!disabledFormText && <FormText className="muted text-left">{formTextLeft}</FormText>}
            <FormText className="muted text-left">{formTextLeft}</FormText>
          </InputGroup>
        ) : (
          <>
            <Input
              type={inputType}
              pattern={inputPattern}
              value={value}
              name={inputName}
              min={inputMin}
              max={inputMax}
              invalid={invalid}
              disabled={disabled}
              onChange={onChange}
              onClick={onClick}
              onKeyDown={onKeyDown}
              onBlur={handleOnBlur}
              placeholder={placeholder}
              className={emoji ? className + ' input-emoji' : className}
            />
            {emoji && (
              <>
                {!showEmoji && <div className="button-emoji show-emoji" onClick={handleShowEmoji}></div>}
                {showEmoji && (
                  <>
                    <div className="button-emoji hide-emoji" onClick={handleShowEmoji}></div>
                    <div className="popup-emoji" ref={wrapperRef}>
                      <EmojiPicker onEmojiClick={onClickEmoji} />
                    </div>
                  </>
                )}
              </>
            )}
            <FormFeedback>{errorMessage}</FormFeedback>
            {!disabledFormText && <FormText className="muted text-right">{formText}</FormText>}
            {!disabledFormText && <FormText className="muted text-left">{formTextLeft}</FormText>}
          </>
        )}
      </Form>
    </Col>
  );
};

export default InputField;
