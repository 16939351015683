import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleSearchWorkshop, handleChangeWorkshop } from '@actions/Booking';
import { Card, CardBody, CardHeader, ListGroup, CardFooter, Button } from 'reactstrap';
import Popup from 'react-popup';

function BookingDetailWorkshop(props) {
  const dispatch = useDispatch();
  const { id, detailData } = props;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Bengkel</CardHeader>
        {!detailData?.workshop && (
          <CardBody>
            <ListGroup className="booking-detail-bengkel">
              <dt className="text-center">Belum Ada Bengkel Pilihan</dt>
            </ListGroup>
          </CardBody>
        )}
        {detailData?.workshop && (
          <CardBody className="pb-0">
            <ListGroup className="booking-detail-bengkel">
              <dt>{detailData?.workshop?.name}</dt>
              <div className="my-3">
                <dd>{detailData?.workshop?.address}</dd>
              </div>
            </ListGroup>
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailWorkshop;
