import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import MenuIcon from '@assets/icons/menu-icon.svg';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import API from '@utils/API';

const AccountDropdown = (props) => {
  const history = useHistory();
  const {
    id,
    isActive,
    name,
    handleFetchAccount,
    handleResultNotification,
    onLoading,
    allowEdit,
    allowActivation,
    allowDelete,
  } = props;

  const api = new API('v2');

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [action, setAction] = useState('');

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const openAccountDetail = () => {
    history.push(`/accounts/${id}/edit`);
  };

  const handleActivation = () => {
    let action = isActive
      ? {
          value: 'deactive',
          label: 'non-aktifkan status',
        }
      : {
          value: 'activate',
          label: 'aktifkan status',
        };
    setConfirmModal(true);
    setAction(action);
  };

  const handleDelete = () => {
    let action = {
      value: 'delete',
      label: 'hapus akun',
    };
    setConfirmModal(true);
    setAction(action);
  };

  const handleSubmit = (value) => {
    if (value == 'activate') {
      onUserActivation(value);
    } else if (value == 'deactive') {
      onUserActivation(value);
    } else if (value == 'delete') {
      onDeleteuser();
    }
  };

  async function onUserActivation(action) {
    setConfirmModal(false);
    onLoading(true);

    // return;
    try {
      const response = await api.post(`v2/fleet/account/${id}/${action}`);
      if (response?.data) {
        onLoading(false);
        handleResultNotification(
          true,
          'success',
          `Berhasil - Status user sukses ${action == 'activate' ? 'diaktifkan' : 'dinon-aktifkan'}`
        );
        handleFetchAccount();
      }
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      console.log(error);
      onLoading(false);
      handleResultNotification(
        true,
        'failed',
        `Gagal - Status user gagal ${action == 'activate' ? 'diaktifkan' : 'dinon-aktifkan'}, ` + error
      );
    }
  }

  async function onDeleteuser() {
    setConfirmModal(false);
    onLoading(true);

    // return;
    try {
      const response = await api.delete(`v2/fleet/account/${id}/`);
      if (response?.data) {
        onLoading(false);
        handleResultNotification(true, 'success', `Berhasil - User sukses dihapus`);
        handleFetchAccount();
      }
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      console.log(error);
      onLoading(false);
      handleResultNotification(true, 'failed', `Gagal - User gagal dihapus, ` + error);
    }
  }

  return (
    <>
      <ConfirmationModal
        modal={confirmModal}
        buttonWidth={'50'}
        buttonClose={'Kembali'}
        buttonConfirm={'Yakin'}
        toggle={() => setConfirmModal(!confirmModal)}
        header={`Yakin ingin ${action?.label} user '${name}' ?`}
        // isDisabled={disableForm}
        onConfirm={() => handleSubmit(action?.value)}
        toggleClose={() => setConfirmModal(!confirmModal)}
      ></ConfirmationModal>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} id={id}>
        <DropdownToggle
          // caret
          tag="span"
          // onClick={toggle}
          data-toggle="dropdown"
          // aria-expanded={dropdownOpen}
          role="button"
        >
          <img src={MenuIcon} className={'icon-zoom'} alt="" style={{ paddingTop: 0, height: '30px' }} />
        </DropdownToggle>
        <DropdownMenu className="dropdown-account">
          {allowEdit && (
            <DropdownItem onClick={openAccountDetail} className="py-2 font-13">
              Ubah Data
            </DropdownItem>
          )}
          {/* <DropdownItem divider /> */}
          {allowActivation && (
            <DropdownItem onClick={handleActivation} className="py-2 font-13">
              {isActive ? 'Nonaktifkan' : 'Aktifkan'}
            </DropdownItem>
          )}
          {allowDelete && (
            <DropdownItem onClick={handleDelete} className="py-2 font-13">
              Hapus
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default AccountDropdown;
