import React, { useEffect, useState } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import { useParams } from 'react-router';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import BookingCustomerDetailForms from '@components/booking/BookingCustomerDetailForms';
import { monasLocation, GMAPS_API_KEY } from '@utils/Constants';
import BookingDetailServices from '@components/booking/BookingDetailServices';
import Geocode from 'react-geocode';
import CreateUserModal from '@components/modal/CreateUserModal';
import { parsePhoneNumber } from 'libphonenumber-js';
import API from '@utils/API';
import { map, assign } from 'lodash';
import GMapsModal from '@components/modal/GMapsModal';
import ModalChooseWorkshop from '@components/modal/ModalChooseWorkshop';
import moment from 'moment';
import Helper from '@helpers/Helper';
import { useHistory } from 'react-router-dom';
import { find } from 'lodash';
import userPermission from '@utils/userPermission';

const BookingCreate = () => {
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();
  const { car_id } = useParams();

  const [accountType, setAccountType] = useState('b2b');
  const [userInfo, setUserInfo] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [errorUser, setErrorUser] = useState('');
  const [selectedCar, setSelectedCar] = useState(null);
  const [errorCar, setErrorCar] = useState('');
  const [carMilleage, setCarMilleage] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: '0',
  });
  const [errorMileage, setErrorMileage] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [errorDetailAddress, setErrorDetailAddress] = useState('');
  const [freshDeskLink, setFreshDeskLink] = useState('');
  const [selectedBookingSource, setSelectedBookingSource] = useState(null);
  const [errorBookingSource, setErrorBookingSource] = useState('');
  const [selectedDate, setSelectedDate] = useState();
  const [errorDate, setErrorDate] = useState('');
  const [selectedTime, setSelectedTime] = useState();
  const [errorTime, setErrorTime] = useState('');
  const [mapValue, setMapValue] = useState();
  const [errorMap, setErrorMap] = useState('');
  const [selectedWorkshop, setSelectedWorkshop] = useState();
  const [errorWorkshop, setErrorWorkshop] = useState('');
  const [spkFile, setSPKFile] = useState();
  const [errorSPKFile, setErrorSPKFile] = useState('');
  const [spkNumber, setSPKNumber] = useState('');
  const [errorSPKNumber, setErrorSPKNumber] = useState('');
  const [promo, setPromo] = useState('');
  const [promoStatus, setPromoStatus] = useState();
  const [promoCodeResult, setPromoCodeResult] = useState({});
  const [userOtopoints, setUserOtopoints] = useState(0);
  const [useOtopoints, setUseOtopoints] = useState(false);
  const [disableOtopoints, setDisableOtopoints] = useState(true);
  const [internalNotes, setInternalNotes] = useState('');
  const [errorInternalNotes, setErrorInternalNotes] = useState('');
  const [serviceRequest, setServiceRequest] = useState('');
  const [errorServiceRequest, setErrorServiceRequest] = useState('');
  const [cart, setCart] = useState();
  const [errorCart, setErrorCart] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [summaryBooking, setSummaryBooking] = useState();

  const [carOptions, setCarOptions] = useState([]);
  const [bookingSourceOptions, setBookingSourceOptions] = useState([]);

  const [disableECommerce, setDisableEcommerce] = useState(true);
  const [disableCar, setDisableCar] = useState(false);
  const [disableTime, setDisableTime] = useState(true);
  const [disableAll, setDisableAll] = useState(true);

  const [hasGMapModal, setHasGMapModal] = useState(false);
  const [isPUDO, setIsPUDO] = useState(true);
  const [isECommerce, setIsECommerce] = useState(false);
  const [isDuplicateBooking, setIsDuplicateBooking] = useState(false);
  const [hasModalChooseWorkshop, setHasModalChooseWorkshop] = useState(false);

  const [phoneNumberInit, setPhoneNumberInit] = useState('');
  const [modalCreate, setModalCreate] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalHasError, setModalHasError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [modalSubmitLoading, setModalSubmitLoading] = useState(false);
  const [modalErrorColor, setModalErrorColor] = useState('info');
  const [submitModalSuccess, setSubmitModalSuccess] = useState(false);

  const [carLoading, setCarLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [hasNotif, setHasNotif] = useState(true);
  const [notification, setNotification] = useState({});

  const breadcrumb = [
    {
      id: 1,
      label: 'Booking / Order',
      active: false,
      url: '/bookings',
    },
    {
      id: 2,
      label: 'Buat Booking',
      active: true,
      url: '',
    },
  ];

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const toggleGMapModal = () => setHasGMapModal(!hasGMapModal);

  const loadUserDetail = async (key) => {
    let phoneNumber = key.replace(/^0/, '');
    phoneNumber = phoneNumber.replace(/(^\+[0-9]{0,2})/g, '');
    if (phoneNumber) {
      return api
        .get(`v2/fleet/customers/?query=${phoneNumber}`)
        .then((res) => {
          const data = res.data.data.customers;
          let dataArray = [];
          map(data, (item) => {
            dataArray.push({
              value: item.id,
              label: `${!item.is_enterprise ? `${item.phone_number} - ` : ''}${item.name}${
                item.is_enterprise && item.nickname ? ` (${item.nickname})` : ''
              }`,
              isB2B: !!item.is_enterprise,
              ...item,
            });
          });

          return dataArray;
        })
        .catch((err) => console.log(err));
    }
  };

  const fetchUserInfo = () => {
    return api
      .get(`v2/fleet/auth/`)
      .then((response) => {
        setUserInfo(response?.data?.data);
      })
      .catch(() => {
        handleResultNotification(true, 'failed', `Gagal - User info gagal didapatkan`);
      });
  };

  const fetchCar = () => {
    setCarLoading(true);

    return api
      .get(`v2/fleet/garage/options/`)
      .then((response) => {
        setCarLoading(false);
        setCarOptions(response?.data?.data);
        setDisableCar(false);
        return response.data.data;
      })
      .catch(() => {
        setCarLoading(false);
        handleResultNotification(true, 'failed', `Gagal - daftar mobil gagal didapatkan`);
      });
  };

  const loadUserCarOptions = (query) => {
    setCarLoading(true);

    const params = {
      keyword: query,
    };

    return api
      .get(`v2/fleet/garage/optionss/`, { params })
      .then((response) => {
        return response.data.data;
      })
      .catch(() => {});
  };

  const loadBookingSource = async (type = 'ecommerce') => {
    setSelectedBookingSource(null);

    await api
      .get(`v2/fleet/bookings/sources?type=${type}`)
      .then((res) => {
        const transformData = map(res.data.data, (item) => {
          return {
            value: item.id,
            label: `${type === 'ecommerce' ? 'E-Commerce - ' : ''}${item.name}`,
            ...item,
          };
        });

        setBookingSourceOptions(transformData);
        if (transformData.length < 2) setSelectedBookingSource(transformData[0]);
      })
      .catch((err) => console.log(err));
  };

  const handleChangeUser = (value) => {
    setSelectedUser(value);
    setSelectedCar(null);
    loadBookingSource(value.isB2B ? 'b2b' : 'default');
    setAccountType(value.isB2B ? 'b2b' : 'b2c');
    setUserOtopoints(value.loyalty_points ?? 0);
    if (value.loyalty_points < 1) {
      setDisableOtopoints(true);
    } else {
      setDisableOtopoints(false);
    }
    setDisableEcommerce(false);
    fetchCar(value.value);
    if (value && errorUser) setErrorUser('');
  };

  const handleChangeCar = (value) => {
    setSelectedCar(value);
    setDisableAll(false);
    if (value && errorCar) setErrorCar('');
  };

  const handleChangeCarMilleage = (e) => {
    const { value } = e.target;
    let milleageValue = value.replace(/[^0-9+()]/g, '');

    setCarMilleage(Helper.formatMoneyOnField(milleageValue));
    if (milleageValue < 1 && !milleageValue) setErrorMileage('Kilometer harus diisi');
    else setErrorMileage('');
  };

  const handleChangeAddressDetail = (e) => {
    const { value } = e.target;

    setDetailAddress(value);
    if (value?.length > 0) setErrorDetailAddress('');
    else setErrorDetailAddress('Rincian Alamat harus diisi');
  };

  const handleChangeFreshDeskLink = (e) => {
    const { value } = e.target;

    setFreshDeskLink(value);
  };

  const handleChangeBookingSource = (value) => {
    setSelectedBookingSource(value);
    if (isECommerce) setDisableAll(false);
    if (value && errorBookingSource) setErrorBookingSource('');
  };

  const handleClickMap = (e) => {
    if (!disableAll) toggleGMapModal();
  };

  const handleChangeMap = (e, source) => {
    setMapValue(e);
    setErrorMap('');
  };

  const handleClickPUDO = () => {
    setIsPUDO(!isPUDO);
    if (isECommerce) setIsECommerce(false);
  };

  const handleClickECommerce = (e) => {
    setIsECommerce(e.target.checked);
    loadBookingSource(e.target.checked ? 'ecommerce' : 'default');
    if (isPUDO) setIsPUDO(false);
    if (e.target.checked) setDisableAll(false);
    if (!e.target.checked && !selectedCar) setDisableAll(true);
  };

  const handleChangeDate = (e) => {
    const { value } = e.target;

    setSelectedDate(value);
    setDisableTime(false);
    setSelectedTime(null);
    if (value && errorDate) setErrorDate('');
  };

  const handleChangeTime = (value) => {
    setSelectedTime(value);
    if (value && errorTime) setErrorTime('');
  };

  const handleChangeWorkshop = (value) => {
    setSelectedWorkshop(value);
    toggleHasModalChooseWorkshop();
    if (value && errorWorkshop) setErrorWorkshop('');
  };

  const toggleHasModalChooseWorkshop = () => setHasModalChooseWorkshop(!hasModalChooseWorkshop);

  const onClickCreateNewUser = (e) => {
    setPhoneNumberInit('');
    setModalType(modalType);
    setModalHasError(false);
    setModalCreate(!modalCreate);
  };

  const handleModalSubmit = (modalType, forms, carVariant) => {
    if (modalType == 'new-car') {
      const newCarPayload = {
        license_plate: forms.carLicense.toUpperCase(),
        variant_id: forms.carVariant,
        transmission: forms.carTransmission,
        year: forms.carYear,
        customer_id: selectedUser.id,
      };
      postNewCar(newCarPayload, carVariant);
    } else if (modalType == 'new-user') {
      const generatePhoneNumber = parsePhoneNumber(forms.phonenumber, 'ID');
      const idPhoneNumber = generatePhoneNumber.number;

      const newUserPayload = {
        phone_number: idPhoneNumber,
        customer_name: forms.fullname.replace(/\s+$/, ''), //remove space if there is a space after the last name
        email: forms.email,
        user_type: forms.userType,
      };
      postNewUser(newUserPayload, forms, carVariant);
    }
  };

  const handleChangeSPKName = (e) => {
    const { value } = e.target;

    setSPKNumber(value);
    setErrorSPKNumber();
  };

  const handleChangeSPKFile = (e) => {
    setSPKFile(e);
    if (e?.key) {
      setErrorSPKFile();
      setErrorCart();
      setErrorServiceRequest();
    } else setErrorSPKFile('Dokumen SPK harus diisi');
  };

  const handleDeleteSPKFile = () => {
    setSPKFile();
    setSPKNumber('');
  };

  const handleChangePromo = (e) => {
    const { value } = e.target;

    setPromo(value);
  };

  const mappingPackage = (type) => {
    let packagesId = [];
    let servicesId = [];

    const packageMapping =
      cart.package.length > 0
        ? map(cart.package, (item) => {
            let package_details = [];
            map(item.package_details, (packageDetails) => {
              package_details.push(Number(packageDetails?.value?.package_details_id));
            });

            if (type === 'mappingSubmit') {
              return {
                package_id: item?.name?.id,
                is_overide_sell: false,
                overide_sell_price: item?.sellPrice?.formatMoney,
                package_details,
              };
            }

            if (type === 'mappingPromo') {
              return {
                package_id: item?.name?.id,
                package_details,
              };
            }
          })
        : [];

    const productMapping =
      cart.product.length > 0
        ? map(cart.product, (item) => {
            if (item?.formType !== 'custom') {
              if (type === 'mappingSubmit') {
                return {
                  product_id: item?.name?.product_id,
                  is_fbo: item?.name?.is_fbo,
                  is_overide_sell_price: item?.isOverride,
                  overide_sell_price: item?.sellPrice?.formatMoney,
                  qty: item?.qty?.formatMoney,
                };
              }

              if (type === 'mappingPromo') {
                packagesId.push(Number(item?.name?.product_id));
              }
            }
          })
        : [];

    const serviceMapping =
      cart.service.length > 0
        ? map(cart.service, (item) => {
            if (item?.formType !== 'custom') {
              if (type === 'mappingSubmit') {
                return {
                  product_id: item?.name?.product_id,
                  is_fbo: item?.name?.is_fbo,
                  is_overide_sell_price: item?.isOverride,
                  overide_sell_price: item?.sellPrice?.formatMoney,
                  qty: item?.qty?.formatMoney,
                };
              }

              if (type === 'mappingPromo') {
                servicesId.push(Number(item?.name?.product_id));
              }
            }
          })
        : [];

    const customMapping = () => {
      let customMappingList = [];
      cart.service.length > 0
        ? map(cart.service, (item) => {
            if (item?.formType === 'custom') {
              customMappingList.push({
                type: item?.type,
                name: item?.name?.label,
                category_id: item?.categories?.value,
                qty: item?.qty?.formatMoney,
                nett_price: item?.name?.nettPrice?.formatMoney,
                sell_price: item?.name?.price?.formatMoney,
                mark_up_percentage: item?.uprate?.value,
                discount_percentage: item?.discount?.value,
              });
            }
          })
        : [];

      cart.product.length > 0
        ? map(cart.product, (item) => {
            if (item?.formType === 'custom') {
              customMappingList.push({
                type: item?.type,
                name: item?.name?.label,
                category_id: item?.categories?.value,
                qty: item?.qty?.formatMoney,
                nett_price: item?.name?.nettPrice?.formatMoney,
                sell_price: item?.name?.price?.formatMoney,
                mark_up_percentage: item?.uprate?.value,
                discount_percentage: item?.discount?.value,
              });
            }
          })
        : [];

      return customMappingList.filter((x) => x !== undefined);
    };

    return {
      package: packageMapping.filter((x) => x !== undefined),
      product:
        type === 'mappingSubmit'
          ? productMapping.filter((x) => x !== undefined)
          : packagesId.filter((x) => x !== undefined),
      service:
        type === 'mappingSubmit'
          ? serviceMapping.filter((x) => x !== undefined)
          : servicesId.filter((x) => x !== undefined),
      custom: type === 'mappingSubmit' ? customMapping() : customMapping()?.length > 0 ? true : false,
    };
  };

  const checkPromoCode = () => {
    if (!selectedUser.isB2B && !disableAll) {
      const params = {
        promo_code: promo,
        user_car_id: selectedCar?.id,
        workshop_slug_name: selectedWorkshop?.slug,
        customer_id: selectedUser?.id,
        customer_settlement_price: summaryBooking?.total_amount,
        packages: mappingPackage('mappingPromo').package,
        product_id: mappingPackage('mappingPromo').product,
        service_id: mappingPackage('mappingPromo').service,
        custom_products_filled: mappingPackage('mappingPromo').custom,
      };

      api
        .post('v2/fleet/promos/calculate', params)
        .then((response) => {
          const data = response.data.data;
          if (data === 'promo_code is None!') {
            setPromoCodeResult(null);
            setPromoCodeStatus({ color: 'danger', message: data });
          } else {
            setPromoCodeResult(data);
            setPromoStatus({
              color: 'success',
              message: `Berhasil pakai promo ${data.promo_code} - ${data.promo_name}`,
            });
          }
        })
        .catch((error) => {
          setPromoCodeResult({});
          setPromoStatus({ color: 'danger', message: error.response.data.error.message });
        });
    }
  };

  const handleChangeOtopoints = (e) => {
    const { checked } = e.target;
    setUseOtopoints(checked);
  };

  const handleChangeInternalNotes = (e) => {
    const { value } = e.target;

    setInternalNotes(value);
  };

  const handleChangeServiceRequest = (e) => {
    const { value } = e.target;
    setServiceRequest(value);

    if (value?.length > 0 && value?.length <= 1000) {
      setErrorSPKFile('');
      setErrorServiceRequest('');
    } else if (value?.length > 1000) {
      setErrorSPKFile('');
      setErrorServiceRequest('maksimal 1000 karakter');
    }
  };

  const handleAddCart = (items) => {
    setCart(items);
    setErrorSPKFile('');
  };

  const handleSetErrorCart = (value) => {
    if (value) setErrorCart('');
    else setErrorCart('Harus ada produk atau jasa yang dipilih');
  };

  const handleChangeDuplicateBooking = (e) => {
    const { checked } = e.target;

    setIsDuplicateBooking(checked);
  };

  const validate = () => {
    let status = true;

    if (!isECommerce) {
      if (!selectedCar) {
        setErrorCar('No. Plat Mobil harus diisi');
        status = false;
      }
    }

    if (isPUDO) {
      if (!detailAddress) {
        setErrorDetailAddress('Rincian Alamat harus diisi');
        status = false;
      }
    }

    if (!spkFile && serviceRequest?.length < 1) {
      setErrorSPKFile('SPK File harus diisi');
      status = false;
    }

    if (!selectedDate) {
      setErrorDate('Tanggal harus diisi');
      status = false;
    }

    if (!selectedTime) {
      setErrorTime('Waktu harus diisi');
      status = false;
    }

    if (serviceRequest?.length < 1 && !spkFile) {
      setErrorServiceRequest('Harus ada permintaan servis');
      status = false;
    }

    if (serviceRequest?.length > 1000 && !spkFile) {
      setErrorServiceRequest('maksimal 1000 karakter');
      status = false;
    }

    if (serviceRequest?.length > 1000 && spkFile) {
      setErrorServiceRequest('maksimal 1000 karakter');
      status = false;
    }

    if (spkFile && spkNumber?.length < 1) {
      setErrorSPKNumber('Nomor SPK harus diisi');
      status = false;
    }

    return status;
  };

  const mappingServices = () => {
    let allService = [];

    cart.product.length > 0
      ? map(cart.product, (item) => {
          if (item?.formType !== 'custom') {
            let result = {
              product_category_id: item?.categories?.value,
              product_id: item?.name?.product_id,
              qty: item?.qty?.formatMoney,
              product_name: item?.name?.label,
              type: 'part',
            };
            allService.push(result);
          }
        })
      : [];

    cart.service.length > 0
      ? map(cart.service, (item) => {
          if (item?.formType !== 'custom') {
            let result = {
              product_category_id: item?.categories?.value,
              product_id: item?.name?.product_id,
              qty: item?.qty?.formatMoney,
              product_name: item?.name?.label,
              type: 'work',
            };
            allService.push(result);
          }
        })
      : [];

    cart.product.length > 0
      ? map(cart.product, (item) => {
          if (item?.formType == 'custom') {
            let result = {
              qty: item?.qty?.formatMoney,
              product_name: item?.name?.label,
              type: 'part',
            };
            allService.push(result);
          }
        })
      : [];

    cart.service.length > 0
      ? map(cart.service, (item) => {
          if (item?.formType == 'custom') {
            let result = {
              product_category_id: item?.categories?.value,
              qty: item?.qty?.formatMoney,
              product_name: item?.name?.label,
              type: 'work',
            };
            allService.push(result);
          }
        })
      : [];

    return allService;
  };

  const handleSubmitBooking = async () => {
    setSubmitLoading(true);
    setDisableAll(true);

    if (!validate()) {
      setSubmitLoading(false);
      if (selectedCar) {
        setDisableAll(false);
      }
      return;
    }
    const hasChooseMap = mapValue?.latitude !== monasLocation?.lat && mapValue?.longitude !== monasLocation?.lng;

    const payload = {
      user_car_id: selectedCar?.id,
      mileage: carMilleage.formatMoney,
      latitude: hasChooseMap ? mapValue?.latitude : '',
      longitude: hasChooseMap ? mapValue?.longitude : '',
      address: hasChooseMap ? mapValue?.street_address : '',
      address_detail: detailAddress,
      // note: internalNotes,
      service_schedule: moment(bookingDateTime).local().utc().format('YYYY-MM-DD HH:mm:ss'),
      // products: mappingServices(),
      service_demand: serviceRequest ?? '',
    };

    if (spkFile?.key) {
      assign(payload, {
        spk_document: {
          filename: spkFile?.file?.name ?? '',
          key: spkFile?.key ?? '',
          spk_number: spkNumber ?? '',
        },
      });
    }

    await api
      .post('/v2/fleet/booking/', payload)
      .then((res) => {
        const data = res.data.data;
        if (isDuplicateBooking) {
          window.open(`/bookings/${data.booking_code}`, '_blank');
        } else {
          setTimeout(() => {
            handleResultNotification(true, 'success', `Berhasil - Booking/Order sukses dibuat`);
          }, 300);
          setTimeout(() => {
            history.push(`/bookings/`);
          }, 2000);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setDisableAll(false);
          setDisableCar(false);
          setSubmitLoading(false);
          handleResultNotification(true, 'failed', `Gagal - Booking/Order gagal dibuat`);
        }, 300);
      });
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) fetchCar();
  }, [userInfo]);

  useEffect(() => {
    Geocode.setApiKey(GMAPS_API_KEY);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMapValue({
        latitude: monasLocation.lat,
        longitude: monasLocation.lng,
        street_address: 'Monas, Jakarta Pusat',
      });
    }, 300);
  }, []);

  useEffect(() => {
    if (parseInt(car_id) > 0) {
      const selectedCar = find(carOptions, { id: parseInt(car_id) });
      handleChangeCar(selectedCar);
    }
  }, [carOptions, car_id]);

  const bookingDateTime =
    selectedDate || selectedTime
      ? `${selectedDate ? selectedDate : ''}${selectedTime ? `, ${selectedTime?.value}` : ''}`
      : null;

  const allowCreate = generatePermission('booking', 'create');

  useEffect(() => {
    if (!allowCreate) {
      history.push('/404');
    }
  }, [allowCreate]);

  return (
    <>
      <Wrapper
        className="wrapperContent"
        customContentClassName="px-5"
        customBreadcrumbClassName={'pb-0 pt-3'}
        breadcrumbData={breadcrumb}
        showNotification={hasNotif}
        notification={notification}
        onCloseNotification={handleCloseNotification}
      >
        <Row className="mb-3 mt-0 d-flex" xs={2}>
          <Col xs={12} md={3}>
            <h3>{'Buat Booking'}</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <BookingCustomerDetailForms
              handleMap={handleChangeMap}
              userInfo={userInfo}
              accountType={accountType}
              mapValue={mapValue}
              handleChangeUser={handleChangeUser}
              onClickCreateNewUser={onClickCreateNewUser}
              handleClickMap={handleClickMap}
              handleClickPUDO={handleClickPUDO}
              handleClickECommerce={handleClickECommerce}
              isPUDO={isPUDO}
              isECommerce={isECommerce}
              onLoadUserDetail={loadUserDetail}
              selectedUser={selectedUser}
              selectedCar={selectedCar}
              disableCar={disableCar}
              isCarLoading={carLoading}
              loadUserCar={loadUserCarOptions}
              handleChangeCar={handleChangeCar}
              disableAll={disableAll}
              disableTime={disableTime}
              defaultCarOptions={carOptions}
              bookingSourceOptions={bookingSourceOptions}
              handleChangeBookingSource={handleChangeBookingSource}
              selectedBookingSource={selectedBookingSource}
              disableECommerce={disableECommerce}
              handleChangeCarMilleage={handleChangeCarMilleage}
              carMilleage={carMilleage}
              handleChangeAddressDetail={handleChangeAddressDetail}
              detailAddress={detailAddress}
              handleChangeFreshDeskLink={handleChangeFreshDeskLink}
              freshDeskLink={freshDeskLink}
              handleChangeDate={handleChangeDate}
              selectedDate={selectedDate}
              handleChangeTime={handleChangeTime}
              selectedTime={selectedTime}
              errorUser={errorUser}
              errorCar={errorCar}
              errorMileage={errorMileage}
              errorDetailAddress={errorDetailAddress}
              errorBookingSource={errorBookingSource}
              errorDate={errorDate}
              errorTime={errorTime}
              errorMap={errorMap}
            />
          </Col>
          <Col lg={6}>
            <BookingDetailServices
              totalCart={cart}
              accountType={accountType}
              selectedUser={selectedUser}
              userInfo={userInfo}
              toggleHasModalChooseWorkshop={toggleHasModalChooseWorkshop}
              selectedWorkshop={selectedWorkshop}
              selectedCar={selectedCar}
              handleAddCart={handleAddCart}
              handleChangeSPKName={handleChangeSPKName}
              spkNumber={spkNumber}
              errorSPKNumber={errorSPKNumber}
              errorSPKFile={errorSPKFile}
              handleChangeSPKFile={handleChangeSPKFile}
              handleDeleteSPKFile={handleDeleteSPKFile}
              spkFile={spkFile}
              checkPromoCode={checkPromoCode}
              handleChangePromo={handleChangePromo}
              promo={promo}
              promoStatus={promoStatus}
              promoCodeResult={promoCodeResult}
              handleChangeOtopoints={handleChangeOtopoints}
              useOtopoints={useOtopoints}
              userOtopoints={userOtopoints}
              disableOtopoints={disableOtopoints}
              handleChangeInternalNotes={handleChangeInternalNotes}
              internalNotes={internalNotes}
              errorInternalNotes={errorInternalNotes}
              serviceRequest={serviceRequest}
              handleChangeServiceRequest={handleChangeServiceRequest}
              errorServiceRequest={errorServiceRequest}
              disableAll={disableAll}
              isECommerce={isECommerce}
              errorWorkshop={errorWorkshop}
              errorCart={errorCart}
              handleSetErrorCart={(value) => setErrorCart(value)}
              handleSetErrorSPKFile={(value) => setErrorSPKFile(value)}
            />
          </Col>
        </Row>
      </Wrapper>

      <CreateUserModal
        modal={modalCreate}
        toggle={onClickCreateNewUser}
        title={modalType == 'new-user' ? 'Tambah User Baru' : 'Tambah Mobil Baru'}
        onSubmit={handleModalSubmit}
        submitLoading={modalSubmitLoading}
        modalErrorColor={modalErrorColor}
        hasError={modalHasError}
        errorMessage={modalErrorMessage}
        submitModalSuccess={submitModalSuccess}
        modalType={modalType}
        selectedUser={selectedUser}
        phoneNumberInit={phoneNumberInit}
      />

      <ModalChooseWorkshop
        isOpen={hasModalChooseWorkshop}
        toggle={toggleHasModalChooseWorkshop}
        handleChangeWorkshop={handleChangeWorkshop}
        cart={cart}
        lat={mapValue?.latitude}
        lng={mapValue?.longitude}
        selectedUser={selectedUser}
      />

      <div
        className="d-flex justify-content-end"
        style={{
          backgroundColor: 'white',
          position: 'sticky',
          bottom: '0px',
          marginBottom: '-10px',
          padding: '10px 20px 10px 20px',
          width: '100%',
        }}
      >
        <Button
          className="mr-4 ml-2 btn-export-finance w-65"
          color="purple"
          onClick={handleSubmitBooking}
          disabled={submitLoading}
        >
          Buat Booking / Order
        </Button>
      </div>
    </>
  );
};

export default BookingCreate;
