import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import BookingReviseForm from '@components/booking/BookingReviseForm';
import BookingReviseSummary from '@components/booking/BookingReviseSummary';
import BookingReviseDocument from '@components/booking/BookingReviseDocument';

function BookingRevise() {
  const history = useHistory();
  const { id } = useParams();
  const { generatePermission } = userPermission();
  const api = new API('v2');
  const [data, setData] = useState({});
  const [formData, setFormData] = useState([]);
  const [documentData, setDocumentData] = useState({});
  const [isValidForm, setIsValidForm] = useState(false);
  const [isValidDocument, setIsValidDocument] = useState(false);
  const [dataSummary, setDataSummary] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [hasNotif, setHasNotif] = useState(true);
  const [notification, setNotification] = useState({});

  const allowPermissionRevise = generatePermission('booking', 'revise');
  const breadcrumb = [
    {
      id: 1,
      label: 'Booking / Order',
      active: false,
      url: '/bookings',
    },
    {
      id: 2,
      label: `#${data?.booking_code ?? '-'}`,
      active: false,
      url: `/bookings/${data?.booking_code ?? ''}`,
    },
    {
      id: 3,
      label: 'Revise Booking',
      active: true,
      url: '',
    },
  ];

  const getPermissionRevise = () => {
    if (allowPermissionRevise) {
      loadBookingDetail();
    } else {
      history.push(`/bookings`);
    }
  };
  async function loadBookingDetail() {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/bookings/${id}/`);
      if (response?.data?.data) {
        setData(response?.data?.data);
        handleCanReviseOrder(response?.data?.data);
      } else {
        setData({});
        history.push(`/bookings`);
      }
      setLoading(false);
    } catch (error) {
      setData({});
      setLoading(false);
      history.push(`/bookings`);
    }
  }

  const handleCanReviseOrder = (data) => {
    if (
      data?.booking_type == 'b2b' &&
      (data?.booking_status?.slug == 'payment' ||
        data?.booking_status?.slug == 'waiting' ||
        data?.booking_status?.slug == 'confirm' ||
        data?.booking_status?.slug == 'on-process' ||
        data?.booking_status?.slug == 'service-done' ||
        data?.booking_status?.slug == 'finish')
    ) {
      //pass
    } else {
      history.push(`/bookings/${data?.booking_code}`);
    }
  };

  const disabledForm = () => {
    let isDisabled = false;
    if (
      data?.booking_type == 'b2b' &&
      (data?.booking_status?.slug == 'service-done' || data?.booking_status?.slug == 'finish')
    ) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const handleChangeForm = (dataForm) => {
    let newDataSummary = { ...data };
    newDataSummary.data_form = dataForm;
    setFormData(dataForm);
    setDataSummary(newDataSummary);
  };

  const handleChangeDocument = (dataDocument) => {
    setDocumentData(dataDocument);
  };

  const handleSubmit = () => {
    setDisabled(true);
    setIsSubmit(false);
    setTimeout(() => {
      setIsSubmit(true);
    }, 300);
  };

  const handleCancel = () => {
    history.push(`/bookings/${data?.booking_code}`);
  };

  const handleValidateForm = (status) => {
    setIsValidForm(status);
  };

  const handleValidateDocument = (status) => {
    setIsValidDocument(status);
  };

  const handleProcessSubmit = () => {
    if (isValidForm && isValidDocument) {
      submitRevise();
    } else {
      setDisabled(false);
      setIsValidForm(false);
      setIsValidDocument(false);
    }
  };

  const submitRevise = () => {
    const payload = getPayload();
    api
      .put(`v2/intools/bookings/${id}/booking-revise/`, payload)
      .then(() => {
        setDisabled(false);
        setIsValidForm(false);
        setIsValidDocument(false);
        resultNotification(true, 'success', 'Berhasil Revise Booking ');
        setTimeout(() => {
          history.push(`/bookings/${data?.booking_code}`);
        }, 1000);
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        setDisabled(false);
        setIsValidForm(false);
        setIsValidDocument(false);
        resultNotification(true, 'fail', 'Gagal Revise Booking ' + message);
      });
  };

  const resultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    handleResultNotification(notification);
  };

  const getPayload = () => {
    let packages = [];
    let documents = [];
    formData.product.forEach((item) => {
      if (item?.id || (!item?.id && !item?.deleted)) {
        packages.push({
          id: item?.id ?? null,
          type_item: 'product',
          name: item?.name ?? '',
          qty: item?.qty_number ?? 0,
          price: item?.price_number ?? 0,
          nett_price: item?.priceNett_number ?? 0,
          // sub_total: item?.sub_total ?? 0,
          take_rate: item?.take_rate?.value ?? 0,
          up_rate: item?.up_rate?.value ?? 0,
          discount_percentage: item?.discount_percentage?.value ?? 0,
          discount_amount: item?.discount_amount ?? 0,
          is_deleted: item?.deleted ?? false,
        });
      }
    });
    formData.jasa.forEach((item) => {
      if (item?.id || (!item?.id && !item?.deleted)) {
        packages.push({
          id: item?.id ?? null,
          type_item: 'service',
          name: item?.name ?? '',
          qty: item?.qty_number ?? 0,
          price: item?.price_number ?? 0,
          nett_price: item?.priceNett_number ?? 0,
          // sub_total: item?.sub_total ?? 0,
          take_rate: item?.take_rate?.value ?? 0,
          up_rate: item?.up_rate?.value ?? 0,
          discount_percentage: item?.discount_percentage?.value ?? 0,
          discount_amount: item?.discount_amount ?? 0,
          is_deleted: item?.deleted ?? false,
        });
      }
    });
    if (documentData.spk_document.length) {
      documentData.spk_document.forEach((item) => {
        if (item?.url_file && item?.key && (item?.id || (!item?.id && !item?.deleted))) {
          documents.push({
            id: item?.id ?? null,
            document_type: 'spk',
            filename: item?.name ?? '',
            label: item?.name ?? '',
            key: item?.key ?? '',
            is_deleted: item?.deleted ?? false,
          });
        }
      });
    }
    if (documentData.invoice_document.length) {
      documentData.invoice_document.forEach((item) => {
        if (item?.url_file && item?.key && (item?.id || (!item?.id && !item?.deleted))) {
          documents.push({
            id: item?.id ?? null,
            document_type: 'invoice',
            filename: item?.name ?? '',
            label: item?.name ?? '',
            key: item?.key ?? '',
            is_deleted: item?.deleted ?? false,
          });
        }
      });
    }

    const payload = {
      packages: packages,
      spk_number: documentData?.spk_number ?? '',
      documents: documents,
      use_otopoints: data?.otopoints_used ? true : false,
    };

    return payload;
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleProcess = (status) => {
    setDisabled(status);
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  useEffect(() => {
    handleProcessSubmit();
  }, [isValidForm, isValidDocument]);

  useEffect(() => {
    getPermissionRevise();
  }, []);

  return (
    <React.Fragment>
      {loading && <LoadingWrapper />}
      {!loading && (
        <Wrapper
          title="Revise Booking"
          breadcrumbData={breadcrumb}
          showNotification={hasNotif}
          notification={notification}
          onCloseNotification={handleCloseNotification}
        >
          <Row>
            <Col sm={12} md={6}>
              <BookingReviseForm
                dataForm={data}
                disabled={disabled || disabledForm()}
                isSubmit={isSubmit}
                onChangeForm={handleChangeForm}
                onValidateForm={handleValidateForm}
                onResultNotification={handleResultNotification}
              ></BookingReviseForm>
            </Col>
            <Col sm={12} md={6}>
              <BookingReviseDocument
                dataDocument={data}
                disabled={disabled}
                isSubmit={isSubmit}
                onChangeDocument={handleChangeDocument}
                onProcess={handleProcess}
                onValidateDocument={handleValidateDocument}
                onResultNotification={handleResultNotification}
              ></BookingReviseDocument>
              <BookingReviseSummary dataSummary={dataSummary}></BookingReviseSummary>
            </Col>
            <Col sm={12} md={12} className="text-right mb-2 section-action">
              {allowPermissionRevise && (
                <Button className="button-action save" disabled={disabled} onClick={handleSubmit}>
                  {'Simpan'}
                </Button>
              )}
              <Button className="button-action cancel" disabled={disabled} onClick={handleCancel}>
                {'Batal'}
              </Button>
            </Col>
          </Row>
        </Wrapper>
      )}
    </React.Fragment>
  );
}

export default BookingRevise;
