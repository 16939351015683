import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FilterDateField = (props) => {
  const { data, disabled, onChange, backDate = true, futureDate = true } = props;
  const [dummyData, setDummyData] = useState(data);
  const [focusClass, setFocusClass] = useState('');

  const handleChange = (e) => {
    if (e?.length) {
      dummyData.start_date = e[0];
      dummyData.end_date = e[1];
      setDummyData({ ...dummyData });
      if ((e[0] == null && e[1] == null) || (e[0] && e[1])) {
        dummyData.value = {
          start_date: e[0],
          end_date: e[1],
        };
        setDummyData({ ...dummyData });
        onChange(dummyData);
      }
    } else {
      dummyData.selected_date = e;
      dummyData.value = {
        selected_date: e,
      };
      setDummyData({ ...dummyData });
      onChange(dummyData);
    }
  };

  const handleClickOutside = (e) => {
    if (data?.date_range) {
      dummyData.start_date =
        dummyData.start_date && dummyData.end_date ? dummyData.start_date : dummyData?.value?.start_date;
      dummyData.end_date = dummyData.start_date && dummyData.end_date ? dummyData.end_date : dummyData?.value?.end_date;
    } else {
      dummyData.selected_date = dummyData.selected_date ?? dummyData?.value?.selected_date;
    }
    setDummyData({ ...dummyData });
  };

  const getFilledClass = () => {
    const filled = dummyData.start_date || dummyData.end_date || dummyData.selected_date ? 'filled' : '';
    return filled;
  };

  const isClearable = () => {
    if (data?.date_range) {
      return dummyData?.start_date && dummyData?.end_date ? true : false;
    } else {
      return dummyData?.selected_date ? true : false;
    }
  };

  useEffect(() => {
    setDummyData(data);
  }, [data]);

  return (
    <DatePicker
      className={'date-field-filter' + ' ' + getFilledClass() + ' ' + focusClass}
      placeholderText={data?.placeholder ?? 'Tanggal'}
      selectsRange={data?.date_range ?? false}
      selected={dummyData?.selected_date ?? ''}
      startDate={dummyData?.start_date ?? ''}
      endDate={dummyData?.end_date ?? ''}
      onChange={handleChange}
      showMonthDropdown
      disabled={disabled}
      showYearDropdown
      todayButton={'Hari Ini'}
      dropdownMode={'select'}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      onClickOutside={handleClickOutside}
      onCalendarClose={() => {
        setFocusClass('');
      }}
      onCalendarOpen={() => {
        setFocusClass('focus');
      }}
      dateFormat={data?.date_format ?? 'd MMM yyyy'}
      isClearable={isClearable()}
      minDate={backDate ? '' : new Date()}
      maxDate={futureDate ? '' : new Date()}
    />
  );
};

export default FilterDateField;
