import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hasLoggedIn, getUsername } from '@reducers/Auth';
import { setActiveLink } from '@reducers/App';
import Navbar from '@components/navbar/Navbar';
import Login from '@containers/auth/Login';
import Dashboard from '@containers/dashboard/Dashboard';
import Bookings from '@containers/bookings/Bookings';
import BookingDetail from '@containers/bookings/Detail';
import BookingCreate from '@containers/booking/Create';
import BookingRevise from '@containers/bookings/Revise';
import BookingEstimation from '@containers/bookings/Estimation';
import Stnk from '@containers/stnk/Stnk';
import StnkDetail from '@containers/stnk/Detail';
import StnkCarDetail from './containers/stnk/cardetail/Detail';
import Services from '@containers/services/Services';
import ServicesDetail from '@containers/services/Detail';
import Cars from '@containers/cars/Cars';
import CarsCreate from '@containers/cars/Create';
import Customer from '@containers/customer/Customer';
import CustomerDetail from '@containers/customer/Detail';
import Referral from '@containers/referral/Referral';
import ReferralDetail from '@containers/referral/Detail';
import Workshops from '@containers/workshops/Workshops';
import WorkshopsDetail from '@containers/workshops/Detail';
import WorkshopsCreate from '@containers/workshops/Create';
import Promos from '@containers/promos/Promos';
import PromoDetail from '@containers/promos/Detail';
import Packages from '@containers/packages/Packages';
import PackageDetail from '@containers/packages/Detail';
import CarHealthDetail from '@containers/stnk/carhealth/Detail';
import ReviewRatings from '@containers/review-ratings/ReviewRatings';
import ReviewRatingsDetail from '@containers/review-ratings/Detail';
import Notifications from '@containers/notifications/Notification';
import NotificationsDetail from '@containers/notifications/Details';
import Subscriptions from '@containers/subscriptions/Subscriptions';
import SubscriptionsCreate from '@containers/subscriptions/Create';
import SubscriptionsDetail from '@containers/subscriptions/Detail';
import SubscriptionsHistory from '@containers/subscriptions/history/History';
import PushNotifications from '@containers/push-notifications/PushNotifications';
import PushNotificationCreate from '@containers/push-notifications/Create';
import PushNotificationDetail from '@containers/push-notifications/Detail';
import AccessControlLevel from '@containers/access-control-level/AccessControlLevel';
import AddUser from '@containers/access-control-level/AddUser';
import EditUser from '@containers/access-control-level/EditUser';
import ForgotPassword from '@containers/auth/ForgotPassword';
import ResetPassword from '@containers/auth/ResetPassword';
import EditAccessControlLevel from '@containers/access-control-level/Edit';
import ConfirmEmail from '@containers/auth/ConfirmEmail';
import Masters from '@containers/masters/Masters';
import MasterDetail from '@containers/masters/Detail';
import PageNotFound from './containers/error/404';
import Campaigns from '@containers/campaigns/Campaigns';
import CampaignDetail from '@containers/campaigns/Detail';
import CampaignCreate from '@containers/campaigns/Create';
import CustomerCreate from '@containers/customer/Create';
import CreateCarsCompability from '@containers/cars-compability/Create';
import DetailCarsCompability from '@containers/cars-compability/Detail';
import Fleets from '@containers/fleets/Fleets';
import FleetDetail from '@containers/fleets/Detail';
import BlankPage from './containers/dashboard/BlankPage';
import Account from '@containers/account/Account';
import AccountDetail from '@containers/account/Detail';
import AccountCreate from '@containers/account/Create';
import AccountEdit from '@containers/account/Edit';
import ServiceHistory from '@containers/service-history/ServiceHistory';
import { usePostHog } from 'posthog-js/react';

function PrivateRoute({ routeName, children, ...rest }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(hasLoggedIn);
  const username = useSelector(getUsername);
  const posthog = usePostHog();

  dispatch(setActiveLink(routeName));

  useEffect(() => {
    if (isLoggedIn) {
      posthog?.identify(username, {
        email: username,
        platform: 'FMS',
      });
    }
  }, [posthog, isLoggedIn, username]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          routeName !== 'fullframe' ? (
            <React.Fragment>
              <Navbar />
              {children}
            </React.Fragment>
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ routeName, children, ...rest }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(hasLoggedIn);

  dispatch(setActiveLink(routeName));

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

export default function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact routeName="login" path="/login">
          <Login />
        </PublicRoute>

        <PublicRoute exact routeName="forgotPassword" path="/forgot-password">
          <ForgotPassword />
        </PublicRoute>

        <PublicRoute exact routeName="resetPassword" path="/reset-password">
          <ResetPassword />
        </PublicRoute>

        <PublicRoute exact routeName="confirmEmail" path="/confirm-email">
          <ConfirmEmail />
        </PublicRoute>

        {/* <PublicRoute exact routeName="notifications" path="/pub/notifications">
          <Notifications />
        </PublicRoute> */}

        {/* <PublicRoute exact routeName="notifications" path="/pub/notifications/:id">
          <NotificationsDetail />
        </PublicRoute> */}

        <PrivateRoute exact routeName="booking" path="/bookings">
          <Bookings />
        </PrivateRoute>

        {/* <PrivateRoute exact routeName="booking" path="/bookings/page/:page">
          <Bookings />
        </PrivateRoute> */}

        <PrivateRoute exact routeName="booking" path="/bookings/create">
          <BookingCreate />
        </PrivateRoute>

        {/* <PrivateRoute exact routeName="booking" path="/bookings/create/:car_id">
          <BookingCreate />
        </PrivateRoute> */}

        <PrivateRoute exact routeName="booking" path="/bookings/:id">
          <BookingDetail />
        </PrivateRoute>

        {/* <PrivateRoute exact routeName="booking" path="/bookings/:id/revise">
          <BookingRevise />
        </PrivateRoute> */}

        <PrivateRoute exact routeName="service" path="/service-history">
          <ServiceHistory />
        </PrivateRoute>

        <PrivateRoute exact routeName="booking" path="/bookings/:id/estimation">
          <BookingEstimation />
        </PrivateRoute>

        <PrivateRoute exact routeName="fleet" path="/fleets">
          <Fleets />
        </PrivateRoute>

        <PrivateRoute exact routeName="fleet" path="/fleets/:id">
          <FleetDetail />
        </PrivateRoute>

        {/* <PrivateRoute exact routeName="stnk" path="/stnk">
          <Stnk />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="stnk" path="/stnk/:id">
          <StnkDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="stnk" path="/stnk/:id/car-health/inspections/">
          <CarHealthDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="stnk" path="/stnk/:id/car-health/inspections/detail/:inspection_id">
          <CarHealthDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="stnk" path="/stnk/:id/car-health/inspections/create/:booking_code">
          <CarHealthDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="stnk" path="/stnk/:id/car-detail/update">
          <StnkCarDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="workshops" path="/workshops">
          <Workshops />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="workshops" path="/workshops/create">
          <WorkshopsCreate />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="workshops" path="/workshops/:id">
          <WorkshopsDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="services" path="/services">
          <Services />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="services" path="/services/:id">
          <ServicesDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="packages" path="/packages">
          <Packages />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="packages" path="/packages/:id">
          <PackageDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="cars" path="/cars">
          <Cars />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="cars" path="/cars/create">
          <CarsCreate />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="cars" path="/cars/:id">
          <CarsCreate />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="cars" path="/cars/compability/create">
          <CreateCarsCompability />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="cars" path="/cars/compability/:id">
          <DetailCarsCompability />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="customer" path="/customer">
          <Customer />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="customer" path="/customer/create">
          <CustomerCreate />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="customer" path="/customer/:id">
          <CustomerDetail />
        </PrivateRoute> */}

        <PrivateRoute exact routeName="user" path="/accounts">
          <Account />
        </PrivateRoute>

        <PrivateRoute exact routeName="user" path="/accounts/create">
          <AccountCreate />
        </PrivateRoute>

        <PrivateRoute exact routeName="user" path="/accounts/:id">
          <AccountDetail />
        </PrivateRoute>

        <PrivateRoute exact routeName="user" path="/accounts/:id/edit">
          <AccountEdit />
        </PrivateRoute>

        {/* <PrivateRoute exact routeName="referral" path="/referral">
          <Referral />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="referral" path="/referral/:id">
          <ReferralDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="promos" path="/promos">
          <Promos />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="promos" path="/promos/:promo_code">
          <PromoDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="reviewRatings" path="/review-ratings">
          <ReviewRatings />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="reviewRatings" path="/review-ratings/:id">
          <ReviewRatingsDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="subscriptions" path="/subscriptions">
          <Subscriptions />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="subscriptions" path="/subscriptions/create">
          <SubscriptionsCreate />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="subscriptions" path="/subscriptions/:id">
          <SubscriptionsDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="subscriptions" path="/subscriptions/:id/history/:id">
          <SubscriptionsHistory />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="pushNotifications" path="/push-notifications">
          <PushNotifications />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="pushNotifications" path="/push-notifications/create">
          <PushNotificationCreate />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="pushNotifications" path="/push-notifications/:id">
          <PushNotificationDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="acl" path="/acl">
          <AccessControlLevel />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="acl" path="/acl/add-user">
          <AddUser />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="acl" path="/acl/edit-user/:id">
          <EditUser />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="acl" path="/acl/edit/:id">
          <EditAccessControlLevel />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="masters" path="/masters">
          <Masters />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="masters" path="/masters/:slug/detail/:id">
          <MasterDetail />
        </PrivateRoute> */}

        <PrivateRoute exact routeName="fullframe" path="/404">
          <PageNotFound />
        </PrivateRoute>

        {/* <PrivateRoute exact routeName="campaigns" path="/campaigns">
          <Campaigns />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="campaigns" path="/campaigns/:slug/detail/:id">
          <CampaignDetail />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="campaigns" path="/campaigns/campaign/edit/:id">
          <CampaignCreate />
        </PrivateRoute> */}

        {/* <PrivateRoute exact routeName="campaigns" path="/campaigns/campaign/create">
          <CampaignCreate />
        </PrivateRoute> */}

        <PrivateRoute routeName="dashboard" path="/dashboard">
          <Dashboard />
        </PrivateRoute>

        <PrivateRoute routeName="empty" path="/">
          <BlankPage />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
