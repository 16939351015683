import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Button,
  Form,
  Input,
  FormFeedback,
  Alert,
  FormText,
  Spinner,
} from 'reactstrap';
import Logo from '@assets/images/logo.svg';
import API from '@utils/API';
import { useHistory, useLocation } from 'react-router-dom';
import { regexWithMinimumNumberCharacter } from '@utils/Constants';
import Popup from 'react-popup';

function ResetPassword() {
  const api = new API('v2');
  const history = useHistory();
  const queryParams = useLocation().search;
  const token = new URLSearchParams(queryParams).get('token');

  const [isDisabled, setIsDisabled] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [password, setPassword] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isRepeatPasswordInvalid, setIsRepeatPasswordInvalid] = useState(false);
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState();

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    const isPasswordCorrect = regexWithMinimumNumberCharacter.test(password);
    const isPasswordMatch = repeatPassword === password;
    if (isPasswordCorrect) {
      setPasswordErrorMessage();
      setIsPasswordInvalid(false);
      if (!isPasswordMatch) {
        setRepeatPasswordErrorMessage('Passwords are not matching');
        setIsRepeatPasswordInvalid(true);
      } else {
        setRepeatPasswordErrorMessage();
        setIsRepeatPasswordInvalid(false);
      }
    } else if (!password) {
      setPasswordErrorMessage('Password is empty or invalid format');
      setIsPasswordInvalid(true);
    } else if (!isPasswordCorrect) {
      setPasswordErrorMessage('Use 8 or more characters with a mix of letters and numbers');
      setIsPasswordInvalid(true);
    }

    setPassword(password);
    setErrorMessage();
    setHasError(false);
  };

  const handleRepeatPasswordChange = (event) => {
    let repeatPassword = event.target.value;
    const isRepeatPasswordCorrect = repeatPassword === password;
    if (!repeatPassword) {
      setRepeatPasswordErrorMessage('Repeat Password is empty or invalid format');
      setIsRepeatPasswordInvalid(true);
    } else if (!isRepeatPasswordCorrect) {
      setRepeatPasswordErrorMessage('Passwords are not matching');
      setIsRepeatPasswordInvalid(true);
    } else {
      setRepeatPasswordErrorMessage();
      setIsRepeatPasswordInvalid(false);
    }

    setRepeatPassword(repeatPassword);
    setErrorMessage();
    setHasError(false);
  };

  const handleGetToken = async () => {
    if (token) {
      await api
        .get(`v2/fleet/auth/reset-password/?token=${token}`)
        .then((res) => {
          console.log(res?.data?.data);
        })
        .catch((e) => {
          Popup.create({ title: 'Error', content: e.response?.data?.error?.message ?? 'invalid' });
          setIsDisabled(true);
        });
    } else {
      Popup.create({ title: 'Error', content: 'token not found' });
      setIsDisabled(true);
    }
  };

  const validation = () => {
    let status = true;

    if (!password) {
      setPasswordErrorMessage('Password is empty or invalid format');
      setIsPasswordInvalid(true);
      status = false;
    }

    if (!repeatPassword) {
      setRepeatPasswordErrorMessage('Repeat Password is empty or invalid format');
      setIsRepeatPasswordInvalid(true);
      status = false;
    }

    if (password !== repeatPassword) {
      setRepeatPasswordErrorMessage('Passwords are not matching');
      setIsRepeatPasswordInvalid(true);
    }

    return status;
  };

  const handleChangePassword = (e) => {
    if (!isPasswordInvalid && !isRepeatPasswordInvalid && validation()) {
      setIsLoading(true);
      e.preventDefault();

      setIsSucceed(false);
      setIsDisabled(true);

      const params = {
        new: password,
        confirm: repeatPassword,
      };

      api
        .post(`v2/fleet/auth/reset-password/?token=${token}`, params)
        .then((res) => {
          setAlertMessage(res.data?.data);
          setIsLoading(false);
          setIsSucceed(true);
          setIsDisabled(false);
          setHasError(false);
          setPassword('');
          setRepeatPassword('');
          setTimeout(() => {
            history.push('/login');
          }, 3000);
        })
        .catch((e) => {
          setIsLoading(false);
          setIsSucceed(false);
          setHasError(true);
          setIsDisabled(false);
          setErrorMessage(e.response.data?.error?.message ?? 'Error');
        });
    }
  };

  useEffect(() => {
    handleGetToken();
  }, []);

  return (
    <Container fluid={true}>
      <Row className="vh-100">
        <Col md={6} className="p-0 h-100vh d-flex justify-content-center change-pwd-bg">
          <div className="accountbg d-flex align-items-end p-5">
            <div className="account-title text-left p-2">
              <h2 className="font-40 text-white">
                Fleet Repairs <div className="text-white">Management System</div>
              </h2>
              <div className="text-white">
                Track and manage fleet transparently from the price, SKU, service details, service status, service
                history and the inspection result of their each vehicle.
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} className="justify-content-center">
          <div className="auth-page px-5 py-3">
            <Card body className="border-0 mb-0 h-100">
              <div className="mb-5 align-items-left">
                <a href="/" className="logo logo-admin">
                  <span>
                    <img src={Logo} height={40} alt="logo" className="my-3" />
                  </span>
                </a>
              </div>
              <div className="align-items-end">
                {isSucceed && <Alert color="success">{alertMessage}</Alert>}

                <Form className="form-horizontal auth-form my-4">
                  <FormGroup>
                    <Input
                      className="input-login"
                      type="password"
                      placeholder="Password"
                      value={password}
                      disabled={isDisabled}
                      invalid={isPasswordInvalid}
                      onChange={handlePasswordChange}
                    />
                    <FormFeedback>{passwordErrorMessage}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Input
                      className="input-login"
                      type="password"
                      value={repeatPassword}
                      disabled={isDisabled}
                      placeholder="Repeat Password"
                      invalid={isRepeatPasswordInvalid}
                      onChange={handleRepeatPasswordChange}
                    />
                    <FormFeedback>{repeatPasswordErrorMessage}</FormFeedback>
                    <FormText className="text-left mb-5">
                      <span className="text-black">{'Min. 8 Characters'}</span>
                    </FormText>
                  </FormGroup>

                  {hasError && <div className="alert alert-danger">{errorMessage}</div>}
                  <FormGroup>
                    <Button
                      block
                      outline
                      className="btn-login"
                      size="md"
                      color="black"
                      disabled={isDisabled}
                      onClick={handleChangePassword}
                    >
                      {isLoading && (
                        <>
                          <Spinner color="light" size="sm">
                            Loading...
                          </Spinner>{' '}
                          &nbsp;
                        </>
                      )}
                      Change Password
                    </Button>
                  </FormGroup>
                </Form>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
