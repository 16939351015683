import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import SelectField from '@components/field/SelectField';
import API from '@utils/API';
import { assign, debounce, map, toNumber } from 'lodash';
import Filter from '@assets/icons/filter.svg';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import WorkshopCard from '../card/WorkshopCard';
import InputField from '../field/InputField';
import Divider from '@components/divider/Divider';

const ModalChooseWorkshop = (props) => {
  const { isOpen, toggle, bookingDatetime, accountType, lat, lng, handleChangeWorkshop, cart, selectedUser } = props;
  const api = new API('v2');

  const [workshops, setWorkshops] = useState([]);
  const [hasMoreWorkshop, setHasMoreWorkshop] = useState(true);
  const [capabilitiesFilter, setCapabilitiesFilter] = useState([]);
  const [selectedCapabilities, setSelectedCapabilities] = useState([]);
  const [specialitiesFilter, setSpecialitiesFilter] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [fboFilter, setFBOFilter] = useState([
    { value: '0', label: 'All' },
    { value: '1', label: 'FBO' },
    { value: '2', label: 'NON FBO' },
  ]);
  const [selectedFBO, setSelectedFBO] = useState({ value: '0', label: 'All' });
  const [pudoFilter, setPUDOFilter] = useState([
    { value: '0', label: 'All' },
    { value: '1', label: 'PUDO' },
    { value: '2', label: 'NON PUDO' },
  ]);
  const [selectedPUDO, setSelectedPUDO] = useState({ value: '0', label: 'All' });
  const [sort, setSort] = useState('asc');
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const getFilterCapabilities = async () => {
    await api
      .get('v2/fleet/workshops/capabilities')
      .then((res) => {
        const transformedData = map(res.data.data, (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setCapabilitiesFilter(transformedData);
      })
      .catch((err) => console.log(err));
  };

  const getFilterSpecialities = async () => {
    await api
      .get('v2/fleet/workshops/specialities')
      .then((res) => {
        const transformedData = map(res.data.data, (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setSpecialitiesFilter(transformedData);
        setSelectedSpecialities(transformedData[0]);
      })
      .catch((err) => console.log(err));
  };

  const generateExistingProductId = () => {
    const packageId = map(cart?.package, (item) => {
      return item?.name?.id;
    });
    const productId = map(cart?.product, (item) => {
      return item?.name?.id;
    });
    const serviceId = map(cart?.service, (item) => {
      return item?.name?.id;
    });

    // console.log('list productID => ', [...packageId, ...productId, ...serviceId]);

    return [...packageId, ...productId, ...serviceId] ?? [];
  };

  const getWorkshopList = async (
    page = 1,
    search = query,
    restart,
    PUDO = selectedPUDO,
    FBO = selectedFBO,
    speciality = selectedSpecialities,
    capability = selectedCapabilities,
    sorting = sort
  ) => {
    const capabilities = map(capability, (item) => {
      return item?.value;
    });
    const datetime = bookingDatetime
      ? moment(bookingDatetime).format('YYYY-MM-DD HH:mm:ss')
      : moment().format('YYYY-MM-DD HH:mm:ss');
    let params = {
      product_id: generateExistingProductId(),
      capabilities_id: capabilities,
      speciality: toNumber(speciality.value),
      query: search,
      latitude: lat,
      longitude: lng,
      booking_datetime: datetime,
      pudo: toNumber(PUDO.value),
      fbo: toNumber(FBO.value),
      page,
      limit: 10,
      sort_alpha: sorting,
      sort_distance: 'asc',
    };

    if (accountType === 'b2b') assign(payload, { customer_id: selectedUser.id });

    await api
      .post(`v2/fleet/workshops/inventory-related/`, params)
      .then((res) => {
        console.log('success get ws => ', res.data.data);
        if (res.data.data?.length < 1) setHasMoreWorkshop(false);
        if (restart) {
          setWorkshops(res.data.data);
        } else {
          setWorkshops([...workshops, ...res.data.data]);
        }
      })
      .catch((err) => console.log('failed get ws => ', err.response.data));

    setCurrentPage(page);
  };

  const handleSorting = () => {
    const toSort = sort === 'asc' ? 'dsc' : 'asc';
    setSort(toSort);
    getWorkshopList(
      currentPage,
      query,
      true,
      selectedPUDO,
      selectedFBO,
      selectedSpecialities,
      selectedCapabilities,
      toSort
    );
  };

  const handleChangeCapabilities = (value) => {
    setSelectedCapabilities(value);
    getWorkshopList(1, '', true, selectedPUDO, selectedFBO, selectedSpecialities, value, sort);
  };

  const handleChangeSpeciabilities = (value) => {
    setSelectedSpecialities(value);
    getWorkshopList(1, '', true, selectedPUDO, selectedFBO, value, selectedCapabilities, sort);
  };

  const handleChangePUDO = (value) => {
    setSelectedPUDO(value);
    getWorkshopList(1, '', true, value, selectedFBO, selectedSpecialities, selectedCapabilities, sort);
  };

  const handleChangeFBO = (value) => {
    setSelectedPUDO(value);
    getWorkshopList(1, '', true, selectedPUDO, value, selectedSpecialities, selectedCapabilities, sort);
  };

  const handleInputQuery = (e) => {
    console.log('handleChangeQuery => ', e.target);
    setQuery(e.target.value);
    getWorkshopList(1, e.target.value, true);
  };

  const handleChangeQuery = useCallback(debounce(handleInputQuery, 1500), []);

  useEffect(() => {
    // getFilterCapabilities();
    // getFilterSpecialities();
  }, []);

  // useEffect(() => {
  //   getWorkshopList(1, '', true);
  // }, [cart]);

  // console.log('workshop list => ', workshops);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <ModalBody>
        <Row className="justify-content-center">
          <span style={{ fontWeight: 700, fontSize: 24 }}>Cari Bengkel</span>
        </Row>

        <Row
          style={{
            border: '1px solid #6610F2',
            boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.05)',
            borderRadius: 4,
            padding: 8,
            margin: '0px 32px',
          }}
        >
          <Row style={{ width: '100%' }}>
            <SelectField
              options={capabilitiesFilter}
              isMulti
              onChange={handleChangeCapabilities}
              value={selectedCapabilities}
              placeholder="Select Capabilities"
            />
            <SelectField
              options={pudoFilter}
              onChange={handleChangePUDO}
              value={selectedPUDO}
              placeholder="Select PUDO"
            />
            <SelectField
              options={specialitiesFilter}
              onChange={handleChangeSpeciabilities}
              value={selectedSpecialities}
              placeholder="Select Specialities"
            />
            <SelectField options={fboFilter} onChange={handleChangeFBO} value={selectedFBO} placeholder="Select FBO" />
          </Row>

          <Row className="w-100 mt-2">
            <Col className="d-flex p-0 align-items-center">
              <InputField placeholder="Cari Bengkel" onChange={handleChangeQuery} />
              <div onClick={handleSorting} className="cursor-pointer">
                Urutkan
                <img src={Filter} />
              </div>
            </Col>
          </Row>

          <Row className="w-100">
            <hr />
          </Row>

          <Row className="w-100">
            <Col style={{ height: 300, overflow: 'auto' }}>
              <InfiniteScroll
                pageStart={1}
                loadMore={getWorkshopList}
                hasMore={hasMoreWorkshop}
                loader={
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                }
                useWindow={false}
              >
                {map(workshops, (item, index) => {
                  return <WorkshopCard key={index} data={item} handleChangeWorkshop={handleChangeWorkshop} />;
                })}
              </InfiniteScroll>
            </Col>
          </Row>
        </Row>

        <Row>
          <Col className="section-action justify-content-center mt-2">
            {/* <Button className="button-action save">Simpan</Button> */}
            <Button className="button-action cancel" onClick={toggle}>
              Batal
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalChooseWorkshop;
