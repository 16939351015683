import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Card, CardBody, CardHeader, FormGroup, Label, Spinner } from 'reactstrap';
import { assign, map } from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import SelectField from '../field/SelectField';
import RadioInput from '../field/RadioInput';
import InputField from '../field/InputField';
import Avatar from '@assets/images/avatar.png';
import UploadImage from '../uploads/UploadImage';
import API from '@utils/API';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PRICE_AGREEMENT_VALUE, userTypeOption, customerTypeOptions, flagTypeOption } from '@utils/Constants';
import ConfirmationModal from '../modal/ConfirmationModal';
import { validate } from 'react-email-validator';
import userPermission from '@utils/userPermission';
import moment from 'moment';
import { regexWithMinimumNumberCharacter } from '@utils/Constants';

const CustomerForms = (props) => {
  const {
    id,
    origin,
    disabledAll,
    handleResultNotification,
    activeCustomerType,
    handleEditForm,
    handleChangeOrigin,
    initialValues,
    loadUserDetail,
  } = props;
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();

  const [userInfo, setUserInfo] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [customerType, setCustomerType] = useState(activeCustomerType ?? 'b2c');
  const [submitStatus, setSubmitStatus] = useState(null);
  const [disableForm, setDisableForm] = useState(origin === 'add' || origin === 'edit' ? false : true);
  const [disableButton, setDisableButton] = useState(false);
  const [toggleAddCarModal, setToggleAddCarModal] = useState(false);
  const [isGetDetailSuccess, setIsGetDetailSuccess] = useState(false);
  const [hasModalConfirmation, setHasModalConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState('Simpan');
  const [hasLocationDetail, setHasLocationDetail] = useState(false);
  const [branchOptions, setBranchOptions] = useState({});
  const [userTypeOptions, setUserTypeOptions] = useState({});

  const [uploadLoading, setUploadLoading] = useState(false);
  const [errorImage, setErrorImage] = useState('');

  const handleUploadImage = (image) => {
    // console.log('handleUploadImage => ', image);
    handleChangeImage(image);
  };

  const handleLoadingImage = (loading) => {
    setUploadLoading(loading);
    if (errorImage) setErrorImage('');
  };

  const onImageError = (msg) => {
    if (msg) handleErrorImage(msg);
    setErrorImage(msg);
    // console.log('image error => ', msg);
  };

  const errorToDelete = [
    'error_customer_name',
    'error_phone_number',
    'error_email',
    'error_user_type',
    'error_flag',
    'error_nickname',
    'error_pic_name',
    'error_pic_phone',
    'error_pic_email',
    'error_b2b_customer_status',
    'error_product_markup',
    'error_service_markup',
    'error_product_discount',
    'error_service_discount',
    'error_internal_notes',
    'error_workshop_blacklist',
    'error_photo',
  ];

  const mappingForm = () => {
    let dataForm = {
      id: initialValues?.id ?? '',
      photo: initialValues?.photo ?? '',
      name: initialValues?.name ?? '',
      email: initialValues?.email ?? '',
      phone: initialValues?.phone_number ? initialValues?.phone_number?.slice(3) : '',
      group: initialValues?.user_type
        ? {
            value: initialValues?.user_type?.id ?? '',
            label: initialValues?.user_type?.name ?? '-',
          }
        : '',
      city: initialValues?.branch
        ? {
            value: initialValues?.branch?.id ?? '',
            label: initialValues?.branch?.name ?? '-',
          }
        : '',
      status: initialValues?.is_active ? true : false,
      password: '',
      confirm_password: '',
      error_name: '',
      error_email: '',
      error_phone: '',
      error_group: '',
      error_city: '',
      error_status: '',
      error_password: '',
      error_confirm_password: '',
    };

    setFormValues(dataForm);
  };

  const handleChangeImage = (image) => {
    // console.log('handleChangeImage => ', image);
    handleChangeFormValues('photo', image, 'error_photo', '');
  };

  const handleErrorImage = (errMsg) => {
    let notification = {
      status: true,
      type: 'fail',
      message: errMsg ?? 'error!',
    };

    handleResultNotification(notification);
  };

  const handleChangeFormValues = (key, value, errorKey, errorMsg, key2, value2) => {
    formValues[key] = value;
    if (errorKey) formValues[errorKey] = errorMsg;
    if (key2) formValues[key2] = value2;
    setFormValues({ ...formValues });
  };

  const handleChangeName = (e) => {
    const valueCustomerName = e?.target?.value.replace(/[^a-zA-Z\s]+/g, '');
    let errorMsg = valueCustomerName.length < 1 ? 'Nama tidak boleh kosong' : '';

    handleChangeFormValues('name', valueCustomerName, 'error_name', errorMsg);
  };

  const handleChangePhoneNumber = (e) => {
    const { name, value } = e.target;
    let phoneValue = value.replace(/[^0-9+()]/g, '');
    phoneValue.replace(/(?!^\+)\+/g, '');

    validatePhoneNumber(phoneValue);
  };

  const handleChangePassword = (e) => {
    const { name, value } = e.target;

    validatePassword(value);
  };

  const handleChangeConfirmPassword = (e) => {
    const { name, value } = e.target;

    validateConfirmPassword(value);
  };

  const handleChangeEmail = (e) => {
    let emailValue = e?.target?.value;
    validateEmail(emailValue);
  };

  const handleChangeGroup = (e) => {
    handleChangeFormValues('group', e, 'error_group', '');
  };

  const handleChangeBranch = (e) => {
    handleChangeFormValues('city', e, 'error_city', '');
  };

  const handleChangeStatus = (e) => {
    let value = e?.target?.value == 'true' ? true : false;
    handleChangeFormValues('status', value, 'error_status', '');
  };

  const handleChangeInternalNotes = (e) => {
    let errMsg = '';
    if (e.target.value.length > 500) {
      errMsg = 'Maksimal karakter adalah 500';
    }

    handleChangeFormValues('internal_notes', e.target.value, 'error_internal_notes', errMsg);
  };

  const handleChangePriceAgreement = (type, value) => {
    if (type === 'markup-product') handleChangeFormValues('product_markup', value, 'error_product_markup', '');
    if (type === 'markup-jasa') handleChangeFormValues('service_markup', value, 'error_service_markup', '');
    if (type === 'discount-product') handleChangeFormValues('product_discount', value, 'error_product_discount', '');
    if (type === 'discount-jasa') handleChangeFormValues('service_discount', value, 'error_service_discount', '');
  };

  const handleChangeBlacklistWorkshop = (value) => {
    handleChangeFormValues('workshop_blacklist', value, 'error_workshop_blacklist', '');
  };

  const validatePhoneNumber = (valuePhoneNumber) => {
    let errorMsg = '';
    const validateLength = valuePhoneNumber?.length == 0 || !valuePhoneNumber;
    const validateMinLength = valuePhoneNumber?.length > 0 && valuePhoneNumber?.length < 10;

    if (validateLength) errorMsg = 'No. Telepon tidak boleh kosong';
    if (validateMinLength) errorMsg = 'No. Telepon kurang dari 9 digit';

    handleChangeFormValues('phone', valuePhoneNumber, 'error_phone', errorMsg);
    return errorMsg ? false : true;
  };

  const validatePassword = (valuePassword, submit) => {
    let errorMsg = '';
    let errMsgConfirm = '';
    const isPasswordCorrect = regexWithMinimumNumberCharacter.test(valuePassword);

    if (isPasswordCorrect && valuePassword) {
      errorMsg = '';
      if (formValues?.confirm_password !== valuePassword) {
        errMsgConfirm = 'Passwords are not matching';
      } else {
        errMsgConfirm = '';
      }
    }

    if (valuePassword?.length < 1) {
      errorMsg = 'Password is empty or invalid format';
    }

    if (!isPasswordCorrect && valuePassword) {
      errorMsg = 'Use 8 or more characters with a mix of letters and numbers';
    }

    if (submit) {
      handleChangeFormValues(
        'password',
        valuePassword,
        `error_password`,
        errorMsg,
        `error_password`,
        errorMsg,
        `error_confirm_password`,
        errMsgConfirm
      );
    } else {
      setFormValues({
        ...formValues,
        password: valuePassword,
        error_password: errorMsg,
        error_confirm_password: errMsgConfirm,
      });
    }

    return errorMsg ? false : true;
  };

  const validateConfirmPassword = (valuePassword) => {
    let errorMsg = '';

    if (!valuePassword) {
      errorMsg = 'Confirm Password is empty or invalid format';
    } else if (formValues?.password !== valuePassword) {
      errorMsg = 'Passwords are not matching';
    } else {
      errorMsg = '';
    }

    handleChangeFormValues('confirm_password', valuePassword, `error_confirm_password`, errorMsg);
    return errorMsg ? false : true;
  };

  const validateEmail = (valueEmail) => {
    let errorMsg = '';
    if (valueEmail?.length < 1 || !valueEmail) {
      errorMsg = `Email tidak boleh kosong`;
    }
    // if (valueEmail?.length > 1 && valueEmail.includes('@')) {
    //   errorMsg = 'Email tidak valid';
    // }

    if (!validate(valueEmail) && valueEmail?.length > 0) {
      errorMsg = 'Format Email salah';
    }

    handleChangeFormValues('email', valueEmail, `error_email`, errorMsg);
    return errorMsg ? false : true;
  };

  const toggleModalAddCar = () => setToggleAddCarModal(!toggleAddCarModal);

  const validateForm = () => {
    const { name, email, password, confirm_password, phone, group, city, status } = formValues;
    let isValid = true;

    if (!name) {
      handleChangeFormValues('name', name, 'error_name', 'Nama tidak boleh kosong');
      isValid = false;
    } else {
      handleChangeFormValues('name', name, 'error_name', '');
    }

    if (!validateEmail(email)) isValid = false;

    if (!validatePhoneNumber(phone)) isValid = false;

    if (!validatePassword(password, true) && origin === 'add') isValid = false;

    if (!validateConfirmPassword(confirm_password) && origin === 'add') isValid = false;

    if (!group) {
      handleChangeFormValues('group', group, 'error_group', 'tipe user tidak boleh kosong');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    const generatePhoneNumber = formValues.phone ? parsePhoneNumber(formValues?.phone, 'ID') : { number: null };

    if (!validateForm()) {
      handleResultNotification(true, 'fail', `Gagal - field mandatory harus diisi!`);
      return;
    }

    const profilePicturePath = () => {
      let pict = payload?.photo?.key ?? initialValues?.photo;
      if (pict?.includes('.net/')) {
        return pict.split('.net/')[1];
      }

      return pict;
    };

    let payload = {
      name: formValues?.name ?? '',
      is_active: formValues?.status ?? '',
      email: formValues?.email ?? '',
      phone_number: generatePhoneNumber.number,
      user_type_id: formValues?.group?.value ?? '',
      // branch_id: formValues?.city?.value ?? '',
      // photo: profilePicturePath(),
    };

    if (origin === 'edit') {
      assign(payload, {
        id: formValues?.id ?? '',
      });
    }

    if (origin === 'add') {
      assign(payload, {
        password: formValues?.password ?? null,
        confirm: formValues?.confirm_password ?? null,
      });
    }

    console.log('payload to send => ', payload);
    // return;
    if (origin === 'add') {
      setSubmitStatus(false);
      setDisableForm(true);
      setDisableButton(true);
      // console.log(payload);
      // return;
      api
        .post('v2/fleet/account/', payload)
        .then((res) => {
          handleResultNotification(true, 'success', `Berhasil - User sukses ditambahkan`);
          setTimeout(() => {
            history.push(`/accounts`);
          }, 1000);
        })
        .catch((err) => {
          let errRes = err?.response?.data?.error?.message;

          if (errRes?.toLowerCase().includes('duplicate entry')) {
            errRes = 'Email atau Nomor Telepon Sudah Digunakan';
          }

          handleResultNotification(true, 'failed', `Gagal - User baru gagal ditambahkan, ` + errRes);
          setSubmitStatus(true);
          setDisableForm(false);
          setDisableButton(false);
        });
    }

    if (origin === 'edit') {
      setSubmitStatus(false);
      setDisableButton(true);

      api
        .put(`v2/fleet/account/${id}/`, payload)
        .then((res) => {
          setDisableForm(true);
          handleResultNotification(true, 'success', `Berhasil - update detail user berhasil`);
          // loadUserDetail();
          // handleChangeOrigin(origin === 'detail' ? 'edit' : 'detail');
          setSubmitStatus(true);
          setTimeout(() => {
            history.push('/accounts');
          }, 2000);
        })
        .catch((err) => {
          let errRes = err?.response?.data?.error?.message;

          if (errRes?.toLowerCase().includes('duplicate entry')) {
            errRes = 'Email atau Nomor Telepon Sudah Digunakan';
          }

          handleResultNotification(true, 'failed', `Gagal - update detail user gagal, ` + errRes);
          setSubmitStatus(true);
          setDisableButton(false);
        });
    }
  };

  const handleEdit = () => {
    if (origin == 'detail') {
      history.push(`/accounts/${id}/edit`);
    } else if (origin == 'edit') {
      history.push(`/accounts/${id}`);
    } else if (origin == 'add') {
      history.push(`/accounts`);
    }
    // if (submitStatus) {
    //   loadUserDetail();
    // } else {
    //   handleEditForm(customerType);
    // }
    // handleChangeOrigin(origin === 'detail' ? 'edit' : 'detail');
    // setDisableForm(!disableForm);
  };

  const handleCancel = () => {
    if (origin === 'add') {
      history.push('/accounts');
    } else {
      handleEdit();
    }
  };

  const toggleModalConfirmation = (status) => {
    setConfirmationType(status);
    setHasModalConfirmation(!hasModalConfirmation);
  };

  const allowPermission = (menu) => {
    return !!(generatePermission('user', menu) || generatePermission('user', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowShow = allowPermission('all') || allowPermission('show');
  const allowAdd = allowPermission('all') || allowPermission('add');
  const allowEdit = allowPermission('all') || allowPermission('edit');

  const statusOptions = [
    { id: 1, value: true, label: 'Aktif' },
    { id: 2, value: false, label: 'Nonaktif' },
  ];

  const fetchUserInfo = () => {
    return api
      .get(`v2/fleet/auth/`)
      .then((response) => {
        setUserInfo(response?.data?.data);
      })
      .catch(() => {
        handleResultNotification(true, 'failed', `Gagal - User info gagal didapatkan`);
      });
  };

  const loadUserTypeList = async () => {
    await api
      .get(`v2/fleet/account/user-type/options`)
      .then((response) => {
        let result = map(response?.data?.data, (item) => {
          return { value: item?.id, label: item?.name ?? '-' };
        });
        setUserTypeOptions(result);
      })
      .catch((err) => {
        const error = err?.response?.data?.error?.message;
        setUserTypeOptions();
        // setDisableForm(true);
        // setDisableButton(true);
        handleResultNotification(true, 'failed', `Gagal mendapatkan data Tipe User, ` + error);
      });
  };

  const loadBranchList = async () => {
    await api
      .get(`v2/fleet/city/options/?customer_id=${userInfo?.company?.id}`)
      .then((response) => {
        let resultBranchList = map(response?.data?.data, (item) => {
          return { value: item?.id, label: item?.name ?? '-' };
        });
        setBranchOptions(resultBranchList);
      })
      .catch((err) => {
        const error = err?.response?.data?.error?.message;
        setBranchOptions();
        // setDisableForm(true);
        // setDisableButton(true);
        handleResultNotification(true, 'failed', `Gagal mendapatkan data Region, ` + error);
      });
  };

  useEffect(() => {
    if (origin) mappingForm();
  }, [origin]);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if ((initialValues?.id && origin == 'edit') || origin == 'add') {
      if (userInfo) {
        loadBranchList();
        loadUserTypeList();
      }
    }
  }, [userInfo]);

  const disabledUpload = disableForm || uploadLoading;

  useEffect(() => {
    onImageError('');
  }, [disabledUpload]);

  useEffect(() => {
    if (!allowShow && (origin == 'edit' || origin == 'detail')) {
      history.push('/404');
    } else if (!allowShow && origin == 'add') {
      history.push('/404');
    }
  }, [allowShow]);

  useEffect(() => {
    if (!allowList && id !== 'me') {
      history.push('/404');
    }
  }, []);

  return (
    <>
      <Row>
        <Col sm={12} md={7}>
          <Card>
            <CardHeader className="bg-primary text-white mt-0">Detail</CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <div className="profile-picture-wrapper mx-auto">
                    <img src={formValues?.photo?.preview || formValues?.photo || Avatar} alt="" />
                  </div>
                  <UploadImage
                    error={errorImage}
                    nameSpace="profile_picture_image"
                    identifier={id ?? moment().format('HHmmss')}
                    onLoading={handleLoadingImage}
                    onError={onImageError}
                    onUpload={handleUploadImage}
                    simplified
                    maxSize={3000}
                    maxSizeString="3MB"
                    // disabled={disabledUpload || disabledAll}
                    disabled={true}
                    uploadText={''}
                    // uploadText={
                    //   <>
                    //     <div className="my-2 d-flex justify-content-center align-items-center">
                    //       <i className="mdi mdi-border-color mr-1 align-self-center vertical-menu-icon"></i>
                    //       <span className={disabledUpload ? '' : 'cursor-pointer link'}>
                    //         {uploadLoading ? (
                    //           <div className="text-center">
                    //             <Spinner size="sm" />
                    //           </div>
                    //         ) : (
                    //           `${origin === 'add' ? 'Upload' : 'Edit'} Foto Profile`
                    //         )}
                    //       </span>
                    //     </div>
                    //     {errorImage ? <span className="text-danger">{errorImage}</span> : null}
                    //   </>
                    // }
                  ></UploadImage>
                </Col>
                <Col md={8}>
                  <FormGroup row>
                    <Label lg={4} className="label-required">
                      {'Nama'}
                    </Label>
                    <InputField
                      colWidth={8}
                      placeholder="name"
                      value={formValues?.name}
                      invalid={!!formValues?.error_name}
                      errorMessage={formValues?.error_name}
                      disabled={disableForm || disabledAll}
                      onChange={handleChangeName}
                    />
                  </FormGroup>

                  <FormGroup row>
                    <Label lg={4} className="label-required">
                      {'Email'}
                    </Label>
                    <InputField
                      colWidth={8}
                      placeholder="email"
                      value={formValues?.email}
                      invalid={!!formValues?.error_email}
                      errorMessage={formValues?.error_email}
                      disabled={disableForm || disabledAll}
                      onChange={handleChangeEmail}
                    />
                  </FormGroup>

                  <FormGroup row>
                    <Label lg={4} className={'label-required'}>
                      {'No. HP'}
                    </Label>
                    <InputField
                      colWidth={8}
                      placeholder="phone"
                      inputName="phone"
                      preffix={'+62'}
                      value={formValues?.phone}
                      invalid={!!formValues?.error_phone}
                      errorMessage={formValues?.error_phone}
                      disabled={disableForm || disabledAll}
                      onChange={handleChangePhoneNumber}
                    />
                  </FormGroup>

                  {origin == 'add' && (
                    <>
                      <FormGroup row>
                        <Label lg={4} className={'label-required'}>
                          {'Password'}
                        </Label>
                        <InputField
                          colWidth={8}
                          placeholder="password"
                          inputName="password"
                          inputType="password"
                          value={formValues?.password}
                          invalid={!!formValues?.error_password}
                          errorMessage={formValues?.error_password}
                          disabled={disableForm || disabledAll}
                          onChange={handleChangePassword}
                        />
                      </FormGroup>
                      <FormGroup row>
                        <Label lg={4} className={'label-required'}>
                          {'Confirm Password'}
                        </Label>
                        <InputField
                          colWidth={8}
                          placeholder="confirm password"
                          inputName="confirm_password"
                          inputType="password"
                          value={formValues?.confirm_password}
                          invalid={!!formValues?.error_confirm_password}
                          errorMessage={formValues?.error_confirm_password}
                          disabled={disableForm || disabledAll}
                          onChange={handleChangeConfirmPassword}
                        />
                      </FormGroup>
                    </>
                  )}

                  <FormGroup row>
                    <Label lg={4} className={'label-required'}>
                      Tipe User
                    </Label>
                    <SelectField
                      colWidth={8}
                      placeholder={'group'}
                      disabled={disableForm || disabledAll || !userTypeOptions}
                      value={formValues.group}
                      invalid={formValues.error_group ? true : false}
                      errorMessage={formValues.error_group}
                      closeMenuOnSelect={true}
                      options={userTypeOptions}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      onChange={handleChangeGroup}
                    />
                  </FormGroup>

                  <RadioInput
                    required
                    labelWidth={4}
                    colWidth={8}
                    label="Status"
                    options={statusOptions}
                    checked={formValues?.status}
                    onClick={handleChangeStatus}
                    disabled={disableForm || disabledAll}
                  />
                </Col>
              </Row>
              <Row className="justify-content-end">
                {id !== 'me' && (
                  <Col className="section-action mb-2">
                    {(origin === 'add' && allowAdd) || (origin === 'edit' && allowEdit) ? (
                      <>
                        <Button
                          color="purple"
                          disabled={disableButton || disabledAll}
                          className="btn-export-finance w-120"
                          onClick={() => {
                            if (origin === 'add') handleSubmit();
                            if (origin === 'edit') toggleModalConfirmation('Simpan');
                          }}
                        >
                          Simpan
                        </Button>
                        <Button
                          // color="link"
                          disabled={disableButton || disabledAll}
                          className="button-outline red w-120"
                          onClick={() => {
                            if (origin === 'add') toggleModalConfirmation('Batalkan');
                            if (origin === 'edit') toggleModalConfirmation('Batalkan');
                          }}
                        >
                          Batalkan
                        </Button>
                      </>
                    ) : null}
                    {origin === 'detail' && allowEdit ? (
                      <Button
                        color="purple"
                        disabled={disableButton || disabledAll}
                        className="btn-export-finance w-120"
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                    ) : null}
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ConfirmationModal
        toggle={toggleModalConfirmation}
        toggleClose={toggleModalConfirmation}
        onConfirm={() => {
          if (confirmationType === 'Simpan') {
            handleSubmit();
          } else {
            handleEdit();
            mappingForm(customerType);
          }
          toggleModalConfirmation();
        }}
        buttonClose={'Kembali'}
        buttonConfirm={confirmationType === 'Simpan' ? 'Simpan' : 'Yakin'}
        modal={hasModalConfirmation}
        header={
          confirmationType == 'Batalkan'
            ? `Yakin ingin membatalkan ${origin == 'add' ? 'penambahan user baru' : 'perubahan data user'}?`
            : 'Simpan data user baru? Pastikan informasi yang kamu masukkan sudah benar.'
        }
      />
    </>
  );
};

export default CustomerForms;
