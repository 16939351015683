import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Spinner, Label, Row } from 'reactstrap';
import DropDownIndicatorInactive from '@assets/icons/chevron-down.svg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import FilePurpleFill from '@assets/icons/file-purple.svg';
import TrashIcon from '@assets/icons/trash.svg';
import InputField from '@components/field/InputField';
import Divider from '@components/divider/Divider';
import BookingDetailServicesTabForms from './BookingDetailServicesTabForms';
import BookingCreateDetailOrder from './BookingCreateDetailOrder';
import WorkshopCard from '../card/WorkshopCard';
import Helper from '@helpers/Helper';
import API from '@utils/API';

const BookingDetailServices = (props) => {
  const {
    totalCart,
    toggleHasModalChooseWorkshop,
    selectedUser,
    selectedWorkshop,
    selectedCar,
    accountType,
    handleAddCart,
    handleChangeSPKName,
    spkNumber,
    handleChangeSPKFile,
    handleDeleteSPKFile,
    spkFile,
    checkPromoCode,
    handleChangePromo,
    promo,
    promoStatus,
    promoCodeResult,
    handleChangeOtopoints,
    useOtopoints,
    userOtopoints,
    disableOtopoints,
    handleChangeInternalNotes,
    internalNotes,
    serviceRequest,
    handleChangeServiceRequest,
    errorServiceRequest,
    disableAll,
    isECommerce,
    errorWorkshop,
    errorCart,
    handleSetErrorCart,
    handleSetErrorSPKFile,
    errorSPKNumber,
    errorSPKFile,
    errorInternalNotes,
    userInfo,
  } = props;

  const api = new API('v2');
  const [loading, setLoading] = useState(false);
  const [detailOrder, setDetailOrder] = useState({
    package: [],
    product: [],
    service: [],
  });
  const [isClicked, setIsClicked] = useState(false);

  const handleChangeDetailOrder = (value) => {
    setDetailOrder({ ...detailOrder, [value.type]: [...detailOrder[value.type], value] });
    handleSetErrorCart();
    handleSetErrorCart();
  };

  const handleDeleteDetailOrder = (type, index) => {
    let newDetailData = { ...totalCart };
    newDetailData[type].splice(index, 1);

    setDetailOrder({ ...newDetailData, totalCart });
  };

  const handleOpenFinder = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 500);

    var input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png, image/jpeg, image/jpg, .xlsx, .xls, .pdf';
    input.onchange = (e) => handleAttachFile(e);
    input.click();
  };

  const handleAttachFile = (e) => {
    setLoading(true);
    if (e?.target?.files[0]) {
      const sizeLabel = '5 MB';
      const fileSize = Math.round(e.target.files[0].size / 1024);
      if (fileSize > 5120) {
        setLoading(false);
        e.target.value = '';
        handleChangeSPKFile(undefined);
        handleSetErrorSPKFile('File tidak boleh lebih dari ' + sizeLabel);
      } else {
        passValidation(e);
      }
    } else {
      setLoading(false);
      handleSetErrorSPKFile('File tidak ditemukan');
    }
  };

  const passValidation = (e) => {
    const uploadFile = e.target.files;

    if (uploadFile) {
      const result = { file: uploadFile[0] };
      resultUploadFile(result);
    }
  };

  const resultUploadFile = (result) => {
    const file = result?.file;

    if (file?.name) {
      const payloadUploadFile = new FormData();
      payloadUploadFile.append('namespace', 'booking-document');
      payloadUploadFile.append('identifier', selectedCar?.id);
      payloadUploadFile.append('file', file);
      handleUploadImage(payloadUploadFile, file);
    } else {
      setLoading(false);
      // handleResultNotification(true, 'fail', 'Gagal upload file ' + selectedFile?.label + ' file tidak diketahui');
      handleSetErrorSPKFile('Gagal upload file SPK, file tidak diketahui');
    }
  };

  async function handleUploadImage(payload, file) {
    api
      .post(`v2/fleet/file/upload/`, payload)
      .then((response) => {
        const keyFile = response?.data?.data[0]?.key ?? '';
        if (keyFile !== '') {
          const resultFile = { key: keyFile, file };
          handleChangeSPKFile(resultFile);
        } else {
          handleSetErrorSPKFile('Gagal upload file SPK');
          // handleResultNotification(true, 'fail', 'Gagal upload file ' + selectedFile?.label);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleSetErrorSPKFile('Gagal upload file SPK, ' + message);
        // handleResultNotification(true, 'fail', 'Gagal upload file ' + selectedFile?.label + ' ' + message);
      });
  }

  useEffect(() => {
    if (detailOrder?.product?.length + detailOrder?.service?.length > 20) {
      handleSetErrorCart('max 20 items');
      setTimeout(() => {
        handleSetErrorCart();
      }, 1000);
    } else if (detailOrder?.product?.length + detailOrder?.service?.length <= 20) {
      handleAddCart(detailOrder);
    }
  }, [detailOrder]);

  return (
    <Card>
      <CardHeader className="text-white mt-0">Detail Servis</CardHeader>
      <CardBody>
        {/* <FormGroup row>
          <Label lg={3} className="label-required">
            Bengkel
          </Label>
          <Col lg={9}>
            <div
              className={`workshop-select${disableAll ? '-disabled' : ''}`}
              onClick={disableAll ? null : () => toggleHasModalChooseWorkshop()}
            >
              {selectedWorkshop ? (
                <div className="px-2">
                  <WorkshopCard data={selectedWorkshop} />
                </div>
              ) : (
                <div className="px-2 text-muted">Pilih bengkel...</div>
              )}
              <img src={DropDownIndicatorInactive} className="arrow" />
            </div>
            {errorWorkshop && <span className="text-danger">{errorWorkshop}</span>}
          </Col>
        </FormGroup> */}

        <FormGroup row>
          <Label lg={3}>No. SPK</Label>
          <InputField
            colWidth={9}
            onChange={handleChangeSPKName}
            value={spkNumber}
            placeholder="Ex: SPK/123.123..."
            disabled={disableAll}
            invalid={!!errorSPKNumber}
            errorMessage={errorSPKNumber}
          />
        </FormGroup>

        <FormGroup row>
          <Label lg={3}>Dokumen SPK</Label>
          <Col lg={9} className="d-flex justify-content-between">
            {loading && (
              <div className="mt-2">
                <Spinner color="purple" size="sm"></Spinner>
                <span className="ml-1">Upload file . . .&nbsp;</span>
              </div>
            )}
            {spkFile?.file && !loading ? (
              <div>
                <img src={FilePurpleFill} alt="" width={24} height={24} />
                <span className="mx-2">{Helper.decreaseSPKName(spkFile.file?.name)}</span>
                <img
                  src={TrashIcon}
                  alt=""
                  style={{ padding: 8, border: '1px solid #D6D8E7', borderRadius: 4, height: 'fit-content' }}
                  onClick={handleDeleteSPKFile}
                />
              </div>
            ) : null}
            {!spkFile?.file?.name && !loading && (
              <Button
                color="primary"
                size="lg"
                className="btn-add-item"
                style={{ marginBottom: '10px' }}
                onClick={handleOpenFinder}
                disabled={disableAll || loading || isClicked}
              >
                <img src={PlusFill} className="mr-2" />
                Browse
              </Button>
            )}
          </Col>
          <Col lg={3}></Col>
          <Col lg={9}>{errorSPKFile && <span className="text-danger">{errorSPKFile}</span>}</Col>
        </FormGroup>

        <Divider title={'Atau'} textcenter />

        <FormGroup row>
          <Label lg={3}>Permintaan Servis</Label>
          <InputField
            colWidth={9}
            inputType="textarea"
            placeholder="contoh: Servis rutin ganti oli"
            onChange={handleChangeServiceRequest}
            value={serviceRequest}
            disabled={disableAll}
            invalid={!!errorServiceRequest}
            errorMessage={errorServiceRequest}
          />
        </FormGroup>

        {/* <Row className="">
          <BookingDetailServicesTabForms
            selectedWorkshop={selectedWorkshop}
            selectedCar={selectedCar}
            selectedUser={selectedUser}
            accountType={accountType}
            handleChangeDetailOrder={handleChangeDetailOrder}
            detailOrder={detailOrder}
            disableAll={disableAll || detailOrder?.product?.length + detailOrder?.service?.length > 20}
            isECommerce={isECommerce}
            errorCart={errorCart}
          />
        </Row> */}

        {/* {totalCart?.product?.length > 0 && (
          <>
            <Divider />

            <Row className="justify-content-end">
              <BookingCreateDetailOrder data={totalCart} handleDeleteDetailOrder={handleDeleteDetailOrder} />
            </Row>
          </>
        )} */}

        {/* <Divider /> */}

        {/* {accountType !== 'b2b' && (
          <>
            <Row>
              <Col>
                <FormGroup row>
                  <Label lg={3}>Kode Promo</Label>
                  <InputField
                    colWidth={9}
                    onChange={handleChangePromo}
                    value={promo}
                    placeholder="Ex: PROMOINTOOLS"
                    prepend={
                      <Button color="purple" onClick={checkPromoCode}>
                        Check
                      </Button>
                    }
                    disabled={disableAll}
                  />
                </FormGroup>

                <FormGroup row>
                  <Label lg={3}>Otopoints</Label>
                  <InputField
                    colWidth={1}
                    inputType="checkbox"
                    onChange={handleChangeOtopoints}
                    checked={useOtopoints}
                    disabled={disableOtopoints || disableAll}
                  />
                  <Label style={{ display: 'flex', alignItems: 'end', marginLeft: '-24px' }}>{`${Helper.formatMoney(
                    userOtopoints
                  )} Points`}</Label>
                </FormGroup>
              </Col>
            </Row>

            <Divider />
          </>
        )} */}

        {/* <FormGroup row>
          <Label lg={3}>Catatan (Internal)</Label>
          <InputField
            colWidth={9}
            inputType="textarea"
            placeholder="Ex: Tambahan di sini..."
            onChange={handleChangeInternalNotes}
            value={internalNotes}
            disabled={disableAll}
            invalid={!!errorInternalNotes}
            errorMessage={errorInternalNotes}
          />
        </FormGroup> */}
      </CardBody>
    </Card>
  );
};

export default BookingDetailServices;
