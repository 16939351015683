import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import ExportButton from '@components/export/ExportButton';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import userPermission from '@utils/userPermission';

function PushNotifications() {
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [defaultPage, setDefaultPage] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  if (!generatePermission('pushnotification', 'all')) {
    history.push(`/`);
  }

  const columns = useMemo(
    () => [
      {
        name: 'PN ID',
        selector: (row) => row.id,
        minWidth: '10%',
      },
      {
        name: 'Publish at',
        selector: (row) => moment(row.published_at).format('MMM DD YYYY, HH:mm:ss'),
        wrap: true,
        sortable: true,
        minWidth: '20%',
      },
      {
        name: 'Title Messages',
        selector: (row) => row?.title ?? '-',
        wrap: true,
        minWidth: '50%',
      },
      {
        name: 'Creator',
        selector: (row) => row?.creator ?? '-',
        wrap: true,
        width: '20%',
      },
    ],
    []
  );

  async function loadPushNotifications() {
    setLoading(true);

    const response = await api.get(`v2/intools/fcm-notifications/blast/histories?page=${page}&limit=${pageLimit}`);
    setData(response?.data?.data ?? []);
    setTotalRows(response?.data?.pagination?.total_rows ?? 0);
    setLoading(false);
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPageLimit, page) => {
    setPage(page);
    setPageLimit(newPageLimit);
  };

  const openNotificationsDetail = (notifications) => {
    if (generatePermission('pushnotification', 'show')) {
      history.push(`/push-notifications/${notifications.id}`);
    }
  };

  useEffect(() => {
    if (page && pageLimit) {
      loadPushNotifications();
    }
  }, [page, pageLimit]);

  return (
    <Wrapper title="Push Notifications" className="wrapperContent">
      <DataTable
        persistTableHead
        highlightOnHover
        pagination
        subHeader
        progressPending={loading}
        data={data}
        columns={generatePermission('pushnotification', 'list') ? columns : []}
        className="table table-bordered dt-responsive"
        paginationServer
        paginationTotalRows={totalRows}
        paginationResetDefaultPage={resetPaginationToggle}
        paginationDefaultPage={defaultPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressComponent={generatePermission('pushnotification', 'list') ? <Loading /> : <></>}
        actions={
          <ExportButton
            hasActionExport={false}
            onClickCreate={() => history.push('/push-notifications/create')}
            hasActionCreate={generatePermission('pushnotification', 'submit')}
            createTitle="+ Push Notifications"
          />
        }
        onRowClicked={(row) => openNotificationsDetail(row)}
      />
    </Wrapper>
  );
}

export default PushNotifications;
