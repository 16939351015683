import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, Tooltip } from 'reactstrap';
import userPermission from '@utils/userPermission';
import Info from '@assets/icons/infoCircleFill.svg';

function BookingServiceRequest(props) {
  const { detailData, detailService, title } = props;
  const { generatePermission } = userPermission();

  const [infoTooltip, setInfoTooltip] = useState(false);

  return (
    <React.Fragment>
      <Card>
        <Tooltip isOpen={infoTooltip} target="infoTooltip" toggle={() => setInfoTooltip(!infoTooltip)}>
          <p>Permintaan servis awal yang diajukan oleh pelanggan.</p>
        </Tooltip>
        <CardHeader className="bg-primary text-white mt-0">
          <div className="ml-3">
            <img className="icon-quest" id="infoTooltip" src={Info} width="15" />
            {title}
          </div>
        </CardHeader>
        <CardBody>
          <FormGroup row className="mb-0">
            <Col lg={12} sm={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: detailData?.service_demand?.replace(/\n/g, '<br />') ?? '-',
                }}
              />
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default BookingServiceRequest;
