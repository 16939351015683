import API from '@utils/API';

export const authByUsername = (email, password) => () => {
  const formData = new FormData();

  formData.append('email', email);
  formData.append('password', password);

  const api = new API('v2');
  const callAuth = new Promise((resolve, reject) => {
    api
      .post('v2/fleet/auth/login/', formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });

  return callAuth;
};

export const getJWTToken = (token) => () => {
  const formData = new FormData();

  formData.append('token', token);

  const api = new API('v2');
  const callGetJWT = new Promise((resolve, reject) => {
    api
      .post('v2/auth/seamless-login/', formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

  return callGetJWT;
};

export const getUserPermission = () => {
  const api = new API('v2');
  const callUserPermission = new Promise((resolve, reject) => {
    api
      .get('v2/fleet/account/permissions/')
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

  return callUserPermission;
};

export const getAuthInfo = () => {
  const api = new API('v2');
  const callUserPermission = new Promise((resolve, reject) => {
    api
      .get('v2/fleet/auth/')
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

  return callUserPermission;
};

export const authRefreshToken = (refresh_token) => () => {
  const api = new API('refresh');

  const callAuthRefreshToken = new Promise((resolve, reject) => {
    api
      .post('v2/fleet/auth/refresh-token/')
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });

  return callAuthRefreshToken;
};

export const authLogout = () => () => {
  const api = new API('v2');
  const callAuthLogout = new Promise((resolve, reject) => {
    api
      .post('v2/fleet/auth/logout/')
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });

  return callAuthLogout;
};
