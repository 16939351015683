import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { useDropzone } from 'react-dropzone';
import API from '@utils/API';
import FolderIcon from '@assets/icons/folder.svg';
import NoImage from '@assets/images/no-image.jpeg';

const UploadImage = (props) => {
  const {
    isView,
    image,
    error,
    nameSpace,
    identifier,
    maxSize = 10240,
    maxSizeString = '10MB',
    allowTypesString = 'jpeg, jpg, png',
    allowTypes = ['jpeg', 'jpg', 'png'],
    onLoading,
    onError,
    onDelete,
    onPreview,
    onUpload,
    uploadText = 'Upload Image',
    simplified = false,
    disabled = false,
  } = props;

  const api = new API('v2');
  const [loading, setLoading] = useState(false);
  const [dragOver, setDragOver] = useState(false);

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: loading || disabled,
    onDrop: (files) => {
      handleDropFileImage(files[0]);
      setDragOver(false);
    },
    onDragOver: () => {
      onError('');
      setDragOver(true);
    },
    onDragLeave: () => {
      setDragOver(false);
    },
    onFileDialogOpen: () => {
      onError('');
    },
  });
  const handlePreviewImage = () => onPreview();
  const handleDeleteImage = () => onDelete();

  const handleDropFileImage = (file) => {
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      onError('Format file tidak diketahui');
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        onError('File tidak sesuai dengan format');
      } else {
        if (fileSize == null) {
          onError('Ukuran file tidak diketahui');
        } else if (fileSize > maxSize) {
          onError('Ukuran file maksimal ' + maxSizeString);
        } else {
          const previewImage = URL.createObjectURL(file);
          handleUploadImage(file, previewImage);
        }
      }
    }
  };

  const handleUploadImage = (file, preview) => {
    if (file?.name) {
      setLoading(true);
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', nameSpace);
      paramsImagedUpload.append('identifier', identifier);
      paramsImagedUpload.append('image', file);

      api
        .post(`v2/intools/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            onUpload({ preview: preview, key: keyFile });
            onError('');
          } else {
            onError('Gagal upload image');
          }
          setLoading(false);
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          onError(message);
          setLoading(false);
        });
    } else {
      onError('Gagal upload image, file tidak diketahui');
    }
  };

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  return (
    <div className={`${simplified ? '' : 'gallery-container-workshop '}mb-0 mt-0`}>
      {isView && (
        <div className="box" onClick={handlePreviewImage}>
          <img src={image ? image : NoImage} height={150} />
        </div>
      )}
      {!isView && (
        <>
          {image && (
            <div className="box">
              <div className="icon-trash" onClick={handleDeleteImage}></div>
              <img src={image} height={150} />
            </div>
          )}
          {!image && (
            <>
              {simplified ? (
                <div {...getRootProps({ className: 'text-center' })}>{uploadText}</div>
              ) : (
                <div {...getRootProps({ className: dragOver ? 'box-upload drag-over' : 'box-upload' })}>
                  <img src={FolderIcon}></img>
                  <div>
                    <div className="title mt-3">
                      {loading
                        ? 'Please wait...'
                        : dragOver
                        ? 'Release File'
                        : 'Click or drag file to this area to upload'}
                    </div>
                    <div className="detail mt-2">Maximum file size {maxSizeString}.</div>
                    <div className="detail">{allowTypesString}</div>
                  </div>
                </div>
              )}
              {/* <div className="invalid-document">{error}</div> */}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UploadImage;
