import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

const ConfirmationModal = (props) => {
  const {
    modal,
    modalWidth,
    buttonClassName,
    buttonWidth = '30',
    titleColor = 'black',
    toggle,
    header,
    subHeader,
    toggleClose,
    onConfirm,
    isDisabled,
    withButtonClose = true,
    withButtonConfirm = true,
    buttonClose = 'Tidak',
    buttonConfirm = 'Ya',
  } = props;

  // console.log('modal konfirmasi => ', props);

  return (
    <Modal
      className={
        modalWidth
          ? `modal-dialog-centered modal-confirmation w-${modalWidth}`
          : `modal-dialog-centered modal-confirmation w-374`
      }
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalBody className="body">
        <div className="header" style={{ color: titleColor }}>
          {header}
        </div>
        {subHeader && <div className="sub-header">{subHeader}</div>}
        <div className="action">
          {withButtonClose && (
            <Button
              className={
                buttonClassName
                  ? `button-outline purple-outline w-${buttonWidth} ${buttonClassName}`
                  : `button-outline purple-outline w-${buttonWidth}`
              }
              color="purple"
              onClick={toggleClose}
              disabled={isDisabled}
            >
              {buttonClose}
            </Button>
          )}
          {withButtonConfirm && (
            <Button
              className={
                buttonClassName ? `btn-export-finance w-30 ${buttonClassName}` : `btn-export-finance w-${buttonWidth}`
              }
              color="purple"
              onClick={onConfirm}
              disabled={isDisabled}
            >
              {buttonConfirm}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
