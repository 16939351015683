import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import map from 'lodash/map';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import FleetDetailCar from '@components/fleet/FleetDetailCar';
import FleetServiceLog from '@components/fleet/FleetServiceLog';
import FleetLogHistory from '@components/fleet/FleetLogHistory';

const serviceDummy = [
  {
    booking_code: '746745674565',
    workshop: 'AA Motor',
    category: 'Oli',
    detail: 'Shell helix',
  },
];

function FleetDetail() {
  const history = useHistory();
  const { id } = useParams();
  const { generatePermission } = userPermission();
  const [data, setData] = useState(null);
  const [carHealth, setCarHealth] = useState(null);
  const [serviceHistory, setServiceHistory] = useState([]);
  const [logHistory, setLogHistory] = useState([]);
  const [disableForm, setDisableForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activityLogs, setActivityLogs] = useState([]);
  const [dataBillSettlement, setDataBillSettlement] = useState({});
  const [hasNotif, setHasNotif] = useState(true);
  const [notification, setNotification] = useState({});

  const allowPermission = (menu) => {
    return !!(generatePermission('garage', menu) || generatePermission('garage', 'all'));
  };

  const allowShow = allowPermission('all') || allowPermission('show');
  const allowEdit = allowPermission('all') || allowPermission('edit');

  useEffect(() => {
    loadFleetDetail(id);
    // loadFleetCarHealth(id);
    loadFleetServiceHistory(id);
  }, []);

  const gotoCarList = () => {
    history.push(`/fleets`);
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  async function loadFleetDetail(carId) {
    setLoading(true);

    const api = new API('v2');
    try {
      const response = await api.get(`v2/fleet/garage/${carId}/`);
      setData(response?.data?.data ?? {});
      setLogHistory(response?.data?.data?.log_history ?? []);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setData();
      setLogHistory([]);
      setDisableForm(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan detail Mobil, ` + error);
    }
    setLoading(false);
  }

  async function loadFleetCarHealth(carId) {
    setLoading(true);

    const api = new API('v2');
    try {
      const response = await api.get(`v2/fleet/garage/${carId}/inspection/`);
      setCarHealth(response?.data?.data ?? {});
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setCarHealth({});
      // setTimeout(() => {
      //   setDisableForm(false);
      //   handleResultNotification(true, 'failed', `Gagal mendapatkan Detail Car, ` + error);
      // }, 300);
    }
    setLoading(false);
  }

  async function loadFleetServiceHistory(carId) {
    setLoading(true);

    const api = new API('v2');
    try {
      const response = await api.get(`v2/fleet/garage/${carId}/booking/`);
      setServiceHistory(response?.data?.data ?? []);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setServiceHistory([]);
      setDisableForm(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan Detail Car, ` + error);
    }
    setLoading(false);
  }

  async function loadFleetLogHistory(carId) {
    setLoading(true);

    const api = new API('v2');
    try {
      const response = await api.get(`v2/fleet/garage/${carId}/log/`);
      setLogHistory(response?.data?.data ?? []);
    } catch (error) {
      setLogHistory([]);
      handleResultNotification(true, 'failed', `Gagal mendapatkan Detail Car, ` + error);
    }
    setLoading(false);
  }

  const mappingDataBillSettlement = (detailBooking) => {
    const dataBillSettlement = {
      packages: getDataPackages(detailBooking),
      otopoints: detailBooking?.total_point_spent >= 0 ? detailBooking?.total_point_spent : 0,
      discount: {
        code: detailBooking?.promo?.promo_code ?? '',
        nominal: detailBooking?.discount_promo ?? 0,
      },
      commission_otoklix: detailBooking?.commission_otoklix ?? 0,
      commission_workshop: detailBooking?.commission_workshop ?? 0,
      amount: detailBooking?.total_price ?? 0,
      amount_settlement_otoklix: detailBooking?.total_price_settlement_otoklix ?? 0,
      amount_settlement_workshop: detailBooking?.total_price_settlement_workshop ?? 0,
      booking_type: detailBooking?.booking_type ?? '',
      margin: detailBooking?.margin ?? 0,
    };

    setBookingType(detailBooking?.booking_type ?? '');
    setBookingTypeTitle(
      detailBooking?.booking_type
        ? detailBooking.booking_type !== 'custom'
          ? detailBooking.booking_type.toUpperCase()
          : 'Custom'
        : ''
    );
    // setDataBillSettlement(dataBillSettlement);

    const billSettlementTest = {
      status: 'active',
      groups: [
        {
          name: 'Paket',
          group_items: [
            {
              position: 'top',
              name: 'Paket Ganti Oli Fuchs 10W40',
              rate_otoklix: 0,
              rate_workshop: 100,
              nett_price_otoklix: 380000,
              nett_price_workshop: 380000,
              sub_total_bill: 380000,
              sub_total_otoklix: 0,
              sub_total_workshop: 380000,
              group_items_details: [
                {
                  name: 'Oli Fusch 10W40',
                  qty: 4,
                  markup: 0,
                  disc: 0,
                  price: 85000,
                  sub_total: 340000,
                },
                {
                  name: 'Filter Oli',
                  qty: 1,
                  markup: 0,
                  disc: 0,
                  price: 40000,
                  sub_total: 40000,
                },
                {
                  name: 'Jasa Ganti Oli',
                  qty: 1,
                  markup: 0,
                  disc: 0,
                  price: 0,
                  sub_total: 0,
                },
              ],
            },
          ],
        },
        {
          name: 'Produk',
          group_items: [
            {
              position: 'bottom',
              name: 'Subtotal Produk',
              sub_total_bill: 2600000,
              sub_total_otoklix: 0,
              sub_total_workshop: 2600000,
              group_items_details: [
                {
                  name: 'Ban Tempur',
                  qty: 4,
                  markup: 0,
                  disc: 0,
                  nett_price_otoklix: 800000,
                  nett_price_workshop: 800000,
                  rate_otoklix: 0,
                  rate_workshop: 100,
                  price: 200000,
                  sub_total: 800000,
                  sub_total_otoklix: 0,
                  sub_total_workshop: 800000,
                },
                {
                  name: 'Velg Racing',
                  qty: 4,
                  markup: 0,
                  disc: 0,
                  nett_price_otoklix: 1800000,
                  nett_price_workshop: 1800000,
                  rate_otoklix: 0,
                  rate_workshop: 100,
                  price: 450000,
                  sub_total: 1800000,
                  sub_total_otoklix: 0,
                  sub_total_workshop: 1800000,
                },
              ],
            },
          ],
        },
        {
          name: 'Jasa',
          group_items: [
            {
              position: 'bottom',
              name: 'Subtotal Jasa',
              sub_total_bill: 560000,
              sub_total_otoklix: 0,
              sub_total_workshop: 560000,
              group_items_details: [
                {
                  name: 'Jasa Bongkar Pasang dan Velg',
                  qty: 1,
                  markup: 0,
                  disc: 0,
                  nett_price_otoklix: 560000,
                  nett_price_workshop: 560000,
                  rate_otoklix: 0,
                  rate_workshop: 100,
                  price: 560000,
                  sub_total: 560000,
                  sub_total_otoklix: 0,
                  sub_total_workshop: 560000,
                },
              ],
            },
          ],
        },
      ],
      customer_type: detailBooking?.customer?.customer_type,
      pph: 60000,
      margin: 0,
      otopoints: 40000,
      discount: {
        code: 'PAKSETO',
        nominal: 10000,
      },
      sub_total_bill: 3540000,
      sub_total_otoklix: 0,
      sub_total_workshop: 3540000,
      total_amount_bill: 3550000,
      total_amount_otoklix: -10000,
      total_amount_workshop: 3540000,
    };
    setDataBillSettlement(billSettlementTest);
  };

  const getDataPackages = (detailBooking) => {
    let packages = [];

    detailBooking?.packages &&
      detailBooking?.packages.forEach((item) => {
        let packageItem = {
          category: item?.category ?? '-',
          name: Helper.shortenByDash(item?.name) ?? '-',
          price_rate_otoklix: item?.total_price_rate_otoklix ?? 0,
          price_rate_workshop: item?.total_price_rate_workshop ?? 0,
          sub_total: item?.total_price ?? 0,
          sub_total_rate_otoklix: item?.total_price_rate_otoklix ?? 0,
          sub_total_rate_workshop: item?.total_price_rate_workshop ?? 0,
          package_details: [],
          package_services: [],
        };

        item?.package_details &&
          item?.package_details.forEach((value) => {
            let key = 'package_details';
            let valueName = value?.name ?? '';
            let valueItemName = value?.line_item?.items?.name ?? '';
            let namePackageItem = valueItemName ? valueName + ' - ' + valueItemName : valueName;

            if (value?.category == 'custom') {
              packageItem.name = value?.name ?? '';
              namePackageItem = item?.description ?? '-';
            } else if (value?.category == 'b2b') {
              key = value?.type_item == 'service' ? 'package_services' : 'package_details';
            } else {
              key = /jasa/gi.test(valueItemName ? valueItemName : valueName) ? 'package_services' : 'package_details';
            }

            packageItem[key].push({
              name: namePackageItem,
              qty: value?.qty ?? 0,
              price: value?.price ?? 0,
              sub_total: value?.subtotal ?? 0,
              nett_sub_total: value?.nett_subtotal ?? 0,
              rate_otoklix: value?.rate_otoklix ?? 0,
              rate_workshop: value?.rate_workshop ?? 100,
              price_rate_otoklix: value?.price_rate_otoklix ?? 0,
              price_rate_workshop: value?.price_rate_workshop ?? 0,
              up_rate: value?.up_rate ?? 0,
              discount_amount: value?.discount_amount ?? 0,
              discount_percentage: value?.discount_percentage ?? 0,
              nett_price: value?.nett_price ?? 0,
            });
          });

        packages.push(packageItem);
      });

    return packages;
  };

  async function loadLogHistory(id) {
    const api = new API('v2');
    const url = `v2/fleet/activity-logs/booking/${id}`;
    const params = {
      params: {
        page: 1,
        limit: 100,
      },
    };
    const response = await api.get(url, params);
    setActivityLogs(response?.data?.data?.activity_logs ?? []);
  }

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Mobil',
        active: false,
        url: '/fleets',
      },
      {
        id: 2,
        label: `Daftar Mobil`,
        active: false,
        url: `/fleets`,
      },
      {
        id: 2,
        label: `Detail Mobil`,
        active: true,
        url: ``,
      },
    ];
  };

  const handleLoading = (status) => {
    setLoading(status);
  };

  const goToRevisePage = () => {
    history.push(`/bookings/${id}/revise`);
  };

  const handleCanReviseOrder = () => {
    if (
      data?.booking_type == 'b2b' &&
      (data?.booking_status?.slug == 'payment' ||
        data?.booking_status?.slug == 'waiting' ||
        data?.booking_status?.slug == 'confirm' ||
        data?.booking_status?.slug == 'on-process' ||
        data?.booking_status?.slug == 'service-done' ||
        data?.booking_status?.slug == 'finish')
    ) {
      setCanReviseorder(true);
    } else {
      setCanReviseorder(false);
    }
  };

  const handleSubmitEditFleet = () => {
    loadFleetDetail(id);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  useEffect(() => {
    if (!allowShow) {
      history.push('/404');
    }
  }, [allowShow]);

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        {!data && (
          <Wrapper
            className="wrapperContent"
            customContentClassName="px-5"
            breadcrumbData={generateBreadcrumb()}
            customBreadcrumbClassName={'pb-0 pt-3'}
            showNotification={hasNotif}
            notification={notification}
            onCloseNotification={handleCloseNotification}
          >
            <Row className="mb-3 mt-0 d-flex" xs={2}>
              <Col xs={12} md={3}>
                <h3>{'Detail Mobil'}</h3>
              </Col>
            </Row>
          </Wrapper>
        )}
        {data && (
          <Wrapper
            customContentClassName="px-5"
            breadcrumbData={generateBreadcrumb()}
            customBreadcrumbClassName={'pb-0 pt-3'}
            showNotification={hasNotif}
            notification={notification}
            onCloseNotification={handleCloseNotification}
          >
            <Row className="mb-3 mt-0 d-flex" xs={2}>
              <Col xs={12} md={3}>
                <h3>{'Detail Mobil'}</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <FleetDetailCar
                  detailData={data}
                  handleResultNotification={handleResultNotification}
                  onSubmit={handleSubmitEditFleet}
                  onLoading={handleLoading}
                  allowEdit={allowEdit}
                ></FleetDetailCar>
                <FleetLogHistory detailData={logHistory}></FleetLogHistory>
                {/* <FleetInspectionResult detailData={carHealth} carID={data?.id}></FleetInspectionResult> */}
              </Col>
              <Col sm={12} md={6}>
                <FleetServiceLog detailData={serviceHistory}></FleetServiceLog>
              </Col>
            </Row>
          </Wrapper>
        )}
      </React.Fragment>
    );
  };

  return renderContent();
}

export default FleetDetail;
