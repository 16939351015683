import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Wrapper from '@components/wrapper/Wrapper';
import BookingFleetBadges from '@components/badge/BookingFleetBadges';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import {
  Col,
  Button,
  Row,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Tooltip,
} from 'reactstrap';
import map from 'lodash/map';
import TickBold from '@assets/icons/tick-bold.svg';
import TickBorder from '@assets/icons/tick-border.svg';
import CloseBold from '@assets/icons/close-bold.svg';
import CloseBorder from '@assets/icons/close-border.svg';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import { assign } from 'lodash';
import Popup from 'react-popup';
import Filter from '@assets/icons/filter.svg';
import ChevronUp from '@assets/icons/chevron-up-white.svg';
import ChevronDown from '@assets/icons/chevron-down-white.svg';
import Info from '@assets/icons/infoCircleFill.svg';

function ServiceHistory() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);

  const keys = [
    'service_start_date',
    'service_end_date',
    'booking_code',
    'cars',
    'license_plate',
    'service_category',
    'tags',
    'workshop',
    'location',
    'page',
    'limit',
    'data',
  ];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [carOptions, setcarOptions] = useState([]);
  const [bookingCodeOptions, setBookingCodeOptions] = useState([]);
  const [licensePlateOptions, setLicensePlateOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tagsOptions, setTagOptions] = useState([]);
  const [workshopOptions, setWorkshopOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [statusApprovement, setStatusApprovement] = useState(false);
  const [selectedBookingCode, setSelectedBookingCode] = useState(false);
  const [loadOptions, setLoadOptions] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const [openDownload, setOpenDownload] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [infoTooltip, setInfoTooltip] = useState(false);

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Riwayat Servis',
        active: false,
        url: '/service-history',
      },
      {
        id: 2,
        label: `Daftar Riwayat Servis`,
        active: true,
        url: ``,
      },
    ];
  };

  const allowPermission = (menu) => {
    return !!(generatePermission('booking', menu) || generatePermission('booking', 'all'));
  };

  const allowPermissionFleet = (menu) => {
    return !!(generatePermission('garage', menu) || generatePermission('garage', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowCreate = allowPermission('all') || allowPermission('create');
  const allowShow = allowPermission('all') || allowPermission('show');
  const allowShowFleet = allowPermissionFleet('all') || allowPermissionFleet('show');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
        textAlign: 'right',
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.service_details?.length > 3,
      style: {
        minHeight: '180px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 3,
      style: {
        minHeight: '150px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 2,
      style: {
        minHeight: '100px',
      },
    },
  ];

  const typeOptions = [
    { value: '', label: 'Semua' },
    { value: 'b2c', label: 'B2C' },
    { value: 'b2b', label: 'B2B' },
    { value: 'custom', label: 'Custom' },
  ];

  const queueTypeOptions = [
    { value: '', label: 'Semua Tipe Servis' },
    { value: 'priority', label: 'Prioritas' },
    { value: 'low-priority', label: 'Reguler' },
  ];

  const dataFilter = [
    {
      id: 'filter_select_booking_code',
      type: 'select',
      title: '',
      name: 'booking_code',
      name_field: 'booking_code',
      options: bookingCodeOptions,
      value: tempFiltered?.booking_code
        ? bookingCodeOptions.filter((type) => type?.value === tempFiltered?.booking_code)?.[0]
        : '',
      placeholder: 'Kode Booking',
    },
    {
      id: 'filter_date_service_date',
      type: 'date',
      title: '',
      name: 'service_date',
      name_field: 'service_date',
      date_range: true,
      value:
        tempFiltered?.service_start_date && tempFiltered?.service_end_date
          ? { start_date: tempFiltered?.service_start_date, end_date: tempFiltered?.service_end_date }
          : '',
      placeholder: 'Tanggal Servis',
    },
    {
      id: 'filter_select_license_plate',
      type: 'select',
      title: '',
      name: 'license_plate',
      name_field: 'license_plate',
      options: licensePlateOptions,
      value: tempFiltered?.license_plate
        ? licensePlateOptions.filter((type) => type?.value === tempFiltered?.license_plate)?.[0]
        : '',
      placeholder: 'Plat Nomor',
    },
    {
      id: 'filter_select_cars',
      type: 'select',
      title: '',
      name: 'cars',
      name_field: 'cars',
      options: carOptions,
      value: tempFiltered?.cars ? carOptions.filter((type) => type?.value === tempFiltered?.cars)?.[0] : '',
      placeholder: 'Mobil',
    },
    {
      id: 'filter_select_service_category',
      type: 'select',
      title: '',
      name: 'service_category',
      name_field: 'service_category',
      options: categoryOptions,
      value: tempFiltered?.service_category
        ? categoryOptions.filter((type) => type?.value === tempFiltered?.service_category)?.[0]
        : '',
      placeholder: 'Kategori Servis',
    },
    {
      id: 'filter_select_workshop',
      type: 'select',
      title: '',
      name: 'workshop',
      name_field: 'workshop',
      options: workshopOptions,
      value: tempFiltered?.tags ? workshopOptions.filter((type) => type?.value === tempFiltered?.workshop)?.[0] : '',
      placeholder: 'Bengkel',
    },
    {
      id: 'filter_select_location',
      type: 'select',
      title: '',
      name: 'location',
      name_field: 'location',
      options: locationOptions,
      value: tempFiltered?.tags ? locationOptions.filter((type) => type?.value === tempFiltered?.location)?.[0] : '',
      placeholder: 'Lokasi',
    },
    {
      id: 'filter_select_tags',
      type: 'select',
      title: '',
      name: 'tags',
      name_field: 'tags',
      options: tagsOptions,
      value: tempFiltered?.tags ? tagsOptions.filter((type) => type?.value === tempFiltered?.tags)?.[0] : '',
      placeholder: 'Tags',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Kode Booking',
        selector: (row) => row?.booking_code ?? '-',
        minWidth: '160px',
        sortable: true,
      },
      {
        name: 'Tanggal Servis',
        selector: (row) => row?.booking_datetime ?? '-',
        cell: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.booking_datetime ?? null).isValid()
                ? moment(row?.booking_datetime).format('DD MMM, YYYY')
                : '-'}
            </span>
            <span className="time">
              {moment(row?.booking_datetime ?? null).isValid() ? moment(row?.booking_datetime).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        minWidth: '150px',
        sortable: true,
        left: true,
      },
      {
        name: 'Plat Nomor',
        minWidth: '120px',
        selector: (row) => row?.car_license_plate ?? '-',
        sortable: true,
        wrap: true,
      },
      {
        name: 'Mobil',
        id: 'name',
        selector: (row) => row?.car_variant ?? '-',
        minWidth: '180px',
        sortable: true,
        wrap: true,
      },
      {
        name: 'Odometer',
        id: 'name',
        selector: (row) => row?.mileage ?? '-',
        minWidth: '180px',
        sortable: true,
        wrap: true,
      },
      {
        name: 'Kategori Servis',
        selector: (row) => row?.category_name ?? '-',
        minWidth: '170px',
        sortable: true,
      },
      {
        name: 'Detail Servis',
        selector: (row) => row?.product_name ?? '-',
        minWidth: '250px',
        sortable: true,
      },
      {
        name: (
          <span className="ml-3">
            <img className="icon-quest mt-0" id="infoTooltipDetail" src={Info} width="14" />
            <span>{'Biaya'}</span>
          </span>
        ),
        selector: (row) => `Rp. ${Helper.formatMoney(row?.price)}`,
        minWidth: '150px',
        // right: true,
        // style: {
        //   textAlign: 'left', // Align data in this column to the right
        // },
      },
      {
        name: 'Bengkel',
        selector: (row) => row?.workshop_name ?? '-',
        minWidth: '150px',
        wrap: 'false',
        sortable: true,
      },
      {
        name: 'Lokasi',
        selector: (row) => row?.location ?? '-',
        minWidth: '150px',
        wrap: 'false',
        sortable: true,
      },
      {
        name: 'Tags',
        selector: (row) => (row?.car_tags?.length ? row?.car_tags?.join(', ') : '-'),
        minWidth: '150px',
        wrap: 'false',
        sortable: true,
      },
    ],
    []
  );

  const generateCategory = (data) => {
    const mapping = data.map((item, index) => {
      if (index < 3) {
        return <div className="mb-4">{item}</div>;
      } else if (index == 3) {
        return <div className="mb-4">{'+' + (data?.length - 3) + ' Lainnya'}</div>;
      }
    });
    return mapping;
  };

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  async function fetchBookings() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/fleet/booking/services`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan data Service History, ` + error);
    }
  }

  async function fetchCarsOptions() {
    setLoading(true);
    setLoadingFilter(true);
    let listOption = [{ value: '', label: 'Semua Merek Mobil' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=cars');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Merek Mobil' });
        listOption = resultOptions;
      }

      setcarOptions(listOption);
      fetchLicensePlateOptions();
    } catch (error) {
      setcarOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchLicensePlateOptions() {
    let listOption = [{ value: '', label: 'Semua Plat Nomor' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=license_plate');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Plat Nomor' });
        listOption = resultOptions;
      }

      setLicensePlateOptions(listOption);
      fetchCategoryOptions();
    } catch (error) {
      setLicensePlateOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchCategoryOptions() {
    let listOption = [{ value: '', label: 'Semua Kategori Servis' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=service_categories');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Kategori Servis' });
        listOption = resultOptions;
      }

      setCategoryOptions(listOption);
      fetchTagsOptions();
    } catch (error) {
      setCategoryOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchTagsOptions() {
    let listOption = [{ value: '', label: 'Semua Tag' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=tags');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Tag' });
        listOption = resultOptions;
      }

      setTagOptions(listOption);
      fetchWorkshopOptions();
    } catch (error) {
      setTagOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchWorkshopOptions() {
    let listOption = [{ value: '', label: 'Semua bengkel' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=workshop');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua bengkel' });
        listOption = resultOptions;
      }

      setWorkshopOptions(listOption);
      fetchLocationOptions();
    } catch (error) {
      setWorkshopOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchLocationOptions() {
    let listOption = [{ value: '', label: 'Semua Lokasi' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=location');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Lokasi' });
        listOption = resultOptions;
      }

      setLocationOptions(listOption);
      fetchBookingCodeOptions();
    } catch (error) {
      setLocationOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchBookingCodeOptions() {
    let listOption = [{ value: '', label: 'Semua Booking Code' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=booking_code');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Booking Code' });
        listOption = resultOptions;
      }

      setBookingCodeOptions(listOption);
      setLoadingFilter(false);
    } catch (error) {
      setBookingCodeOptions(listOption);
      setLoadingFilter(false);
    }
  }

  const handleChangeOptions = () => {
    if (filters?.length) {
      filters[0].options = bookingCodeOptions;
      if (tempFiltered?.booking_code)
        filters[0].value = bookingCodeOptions.filter((status) => status?.value === tempFiltered?.booking_code)?.[0];
      filters[2].options = licensePlateOptions;
      if (tempFiltered?.license_plate)
        filters[2].value = licensePlateOptions.filter((status) => status?.value === tempFiltered?.license_plate)?.[0];
      filters[3].options = carOptions;
      if (tempFiltered?.cars) filters[3].value = carOptions.filter((value) => value?.value == tempFiltered?.cars);
      filters[4].options = categoryOptions;
      if (tempFiltered?.service_category)
        filters[4].value = categoryOptions.filter((status) => status?.value === tempFiltered?.service_category)?.[0];
      filters[5].options = workshopOptions;
      if (tempFiltered?.workshop)
        filters[5].value = workshopOptions.filter((status) => status?.value === tempFiltered?.workshop)?.[0];
      filters[6].options = locationOptions;
      if (tempFiltered?.location)
        filters[6].value = locationOptions.filter((status) => status?.value === tempFiltered?.location)?.[0];
      filters[7].options = tagsOptions;
      if (tempFiltered?.tags)
        filters[7].value = tagsOptions.filter((status) => status?.value === tempFiltered?.tags)?.[0];
      setSelectedStatus(tempFiltered?.status);
      setFilters([...filters]);
    }
  };

  const getParamData = () => {
    let params = {
      booking_code: filtered?.booking_code ?? null,
      license_plate: filtered?.license_plate ?? null,
      cars: filtered?.cars ?? null,
      service_categories: filtered?.service_category ?? null,
      tags: filtered?.tags ?? null,
      workshop: filtered?.workshop ?? null,
      location: filtered?.location ?? null,
      service_start_date: filtered?.service_start_date
        ? moment(filtered?.service_start_date).format('YYYY-MM-DD 00:00:00')
        : null,
      service_end_date: filtered?.service_end_date
        ? moment(filtered?.service_end_date).format('YYYY-MM-DD 23:59:59')
        : null,
      page: page,
      limit: pageLimit,
    };

    if (filtered?.status) {
      assign(params, {
        status: filtered?.status ?? null,
      });
    }

    if (sortingData?.order) {
      assign(params, {
        sort: sortingData?.sort ?? null,
        order: sortingData?.order ?? null,
      });
    }

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      // setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const resetFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetFilter();
    setSelectedStatus('');
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchBookings();
  };

  const handleMethodfetchBookings = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchBookings();
    }
  };

  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const resetBookings = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const handleCreateBooking = () => {
    history.push(`/bookings/create`);
  };

  const openBookingsDetail = (row) => {
    if (allowShow) {
      history.push(`/bookings/${row?.booking_code}`);
    }
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      booking_code: e?.booking_code?.value ?? '',
      cars: e?.cars?.value ?? '',
      license_plate: e?.license_plate?.value ?? '',
      service_category: e?.service_category?.value ?? '',
      tags: e?.tags?.value ?? '',
      workshop: e?.workshop?.value ?? '',
      location: e?.location?.value ?? '',
      service_start_date: e?.service_date?.start_date
        ? moment(e?.service_date?.start_date).format('YYYY-MM-DD 00:00:00')
        : '',
      service_end_date: e?.service_date?.end_date
        ? moment(e?.service_date?.end_date).format('YYYY-MM-DD 23:59:59')
        : '',
    };

    setFiltered(tempVal);
  };

  const handleCloseDownload = () => {
    setLoadingDownload(false);
  };

  const handleDownload = async (type) => {
    if (allowList) {
      setLoadingDownload(true);

      const params = getParamData();
      try {
        const response = await api.get(`v2/fleet/booking/services/download/${type}`, {
          responseType: 'blob',
          params,
        });
        const filename = `service_history.${type}`;
        if (+response?.status === 200) {
          handleCloseDownload();
          const blobType =
            type === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          const blob = new Blob([response?.data], { type: blobType });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        handleCloseDownload();
        handleResultNotification(true, 'failed', `Gagal - Export ${type}`);
      }
    }
  };

  useEffect(() => {
    if (page && pageLimit) {
      fetchBookings();
    }
  }, [page, pageLimit]);

  // useEffect(() => {
  //   if (!firstLoad) {
  //     handleChangeSetSorting();
  //   }
  // }, [sortingData]);

  useEffect(() => {
    if (filtered && !loadingFilter) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD 00:00:00') : '',
        end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD 23:59:59') : '',
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/service-history?${urlParam}`);
      !loading && fetchBookings();
    }
    resetBookings();
  }, [filtered, loadingFilter]);

  useEffect(() => {
    handleChangeOptions();
  }, [bookingCodeOptions]);

  useEffect(() => {
    fetchCarsOptions();
    setFilters(dataFilter);
  }, []);

  useEffect(() => {
    if (tempFiltered?.start_date && tempFiltered?.end_date) {
      setFiltered({
        ...tempFiltered,
        date: {
          start_date: tempFiltered?.start_date,
          end_date: tempFiltered?.end_date,
        },
        start_date: tempFiltered?.start_date,
        end_date: tempFiltered?.end_date,
      });
    }
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  return (
    <>
      <Wrapper
        customContentClassName="px-5"
        breadcrumbData={generateBreadcrumb()}
        customBreadcrumbClassName={'pb-0 pt-3'}
        showNotification={hasNotif}
        notification={notification}
        onCloseNotification={handleCloseNotification}
      >
        <Tooltip
          placement="top"
          isOpen={infoTooltip}
          target="infoTooltipDetail"
          toggle={() => setInfoTooltip(!infoTooltip)}
        >
          Harga sudah termasuk PPN.
        </Tooltip>
        <ConfirmationModal
          modal={confirmModal}
          modalWidth={'390'}
          uttonWidth={'50'}
          titleColor={statusApprovement ? 'green' : 'red'}
          toggle={() => setConfirmModal(!confirmModal)}
          header={`${statusApprovement ? 'Menyetujui' : 'Menolak'} Semua Permintaan Approval ?`}
          subHeader={`Apakah kamu yakin ingin ${
            statusApprovement ? 'menyetujui' : 'menolak'
          } semua permintaan approval untuk order ini?`}
          isDisabled={disableForm}
          onConfirm={() => approveEstimation(statusApprovement)}
          toggleClose={() => setConfirmModal(!confirmModal)}
        ></ConfirmationModal>
        <ConfirmationModal
          modal={alertModal}
          modalWidth={'400'}
          uttonWidth={'50'}
          titleColor={'red'}
          buttonClose={'Tutup'}
          withButtonConfirm={false}
          toggle={() => setAlertModal(!alertModal)}
          header={`Approval Gagal`}
          subHeader={`Untuk transaksi >10 juta rupiah hanya bisa diproses oleh Admin`}
          isDisabled={disableForm}
          toggleClose={() => setAlertModal(!alertModal)}
        ></ConfirmationModal>
        <Row className="mb-3 mt-0">
          <Col xs={12} md={3} lg={3} className={'overflow-title'}>
            <h3>Riwayat Servis</h3>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <div className="filter-sides">
              {/* <div onClick={() => setShowFilter(loading && loadingFilter ? null : !showFilter)}>
                <img className="cursor-pointer mt-2 mr-2" src={Filter} height={'30px'} />
              </div> */}
              <Button
                className={`${!showFilter ? 'btn-filter-show mt-0' : ''} mr-2 h-35`}
                color="purple"
                outline={!showFilter}
                onClick={() => setShowFilter(loading ? null : !showFilter)}
                disabled={disableForm || loading || loadingFilter}
              >
                {'Filter'}
                <img className="cursor-pointer mt-0 ml-2" src={Filter} height={'15px'} />
              </Button>
              {allowList && (
                <>
                  <ButtonDropdown
                    className="section-action"
                    isOpen={openDownload}
                    toggle={() => setOpenDownload(!openDownload)}
                    disabled={loading || loadingFilter}
                  >
                    <DropdownToggle
                      color="#ff5c5c"
                      className="button-action add w-170 h-35"
                      disabled={loadingDownload}
                      caret
                    >
                      {loadingDownload ? (
                        <Spinner size="sm" />
                      ) : (
                        <>
                          {'Download'}
                          <img
                            className="ml-3"
                            src={openDownload ? ChevronUp : ChevronDown}
                            alt=""
                            style={{ position: 'absolute', right: 10, top: 9 }}
                          />
                        </>
                      )}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <Button
                          disabled={loadingFilter || loading}
                          className="button-action add"
                          onClick={() => handleDownload('csv')}
                        >
                          CSV Format
                        </Button>
                      </DropdownItem>
                      <DropdownItem>
                        <Button
                          disabled={loadingFilter || loading}
                          className="button-action add"
                          onClick={() => handleDownload('xlsx')}
                        >
                          XLSX Format
                        </Button>
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          {allowList && showFilter && (
            <ActionFilterCustom
              filters={filters}
              showButton={true}
              showSearch={false}
              loading={loading || loadingFilter}
              onApplyFilters={(e) => handleSetFiltered(e)}
              onResetFilters={handleResetFilters}
              displayCenter={true}
            ></ActionFilterCustom>
          )}
        </Row>
        <Row className="status-filter mt-3"></Row>
        <Row className="p-0 my-4" style={{ backgroundColor: 'white' }}>
          <Col sm={12} md={12} className="p-0 mb-0">
            <DataTable
              persistTableHead
              highlightOnHover
              // sortServer
              noHeader={true}
              progressPending={loading || loadingFilter}
              data={allowList ? data : []}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              conditionalRowStyles={conditionalRowStyles}
              onRowClicked={(row) => openBookingsDetail(row)}
              onSort={handleChangeSorting}
            />
          </Col>
          <Col sm={12} md={12} className="pb-2 m-0">
            {data?.length >= 1 && !loading && !loadingFilter && allowList && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </Row>
      </Wrapper>
    </>
  );
}

export default ServiceHistory;
