import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomPagination from '@components/pagination/CustomPagination';
import AccountDropdrown from '@components/account/AccountDropdown';
import { Col, Button, Row } from 'reactstrap';
import findIndex from 'lodash/findIndex';
import { assign } from 'lodash';

const dataDummy = [
  {
    car: 'Kijang Inova',
    plate: 'AA 5667 GG',
    transmision: 'Automatic',
    year: '2019',
    fuel: 'Bensin',
  },
  {
    car: 'Kijang Inova',
    plate: 'AA 5667 GG',
    transmision: 'Automatic',
    year: '2019',
    fuel: 'Bensin',
  },
  {
    car: 'Kijang Inova',
    plate: 'AA 5667 GG',
    transmision: 'Automatic',
    year: '2019',
    fuel: 'Bensin',
  },
  {
    car: 'Kijang Inova',
    plate: 'AA 5667 GG',
    transmision: 'Automatic',
    year: '2019',
    fuel: 'Bensin',
  },
];

function Accounts() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const { id } = useParams();
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [statusOptions, setStatusOptions] = useState([]);
  const [control, setControl] = useState({});
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hideAction, sethideAction] = useState(false);

  const [hasModalCreateFleet, setHasModalCreateFleet] = useState(false);

  const toogleCreateFleet = () => setHasModalCreateFleet(!hasModalCreateFleet);

  const handleSubmitCreateFleet = (status, error) => {
    const errorMsg = error ? 'Gagal - Mobil gagal didaftarkan, ' + error : 'Gagal - Mobil gagal didaftarkan';
    if (status) {
      fetchAccount();
      setHasModalCreateFleet(false);
    }
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil - Mobil suskses didaftarkan' : errorMsg
    );
  };

  const openAccountDetail = (row) => {
    if (allowShow) {
      history.push(`/accounts/${row?.id}`);
    }
  };

  const handleCreateUser = () => {
    history.push(`/accounts/create`);
  };

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Akun',
        active: false,
        url: '/accounts',
      },
      {
        id: 2,
        label: `User Management`,
        active: true,
        url: ``,
      },
    ];
  };

  const allowPermission = (menu) => {
    return !!(generatePermission('user', menu) || generatePermission('user', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowAdd = allowPermission('all') || allowPermission('add');
  const allowShow = allowPermission('all') || allowPermission('show');
  const allowEdit = allowPermission('all') || allowPermission('edit');
  const allowActivation = allowPermission('all') || allowPermission('activation');
  const allowDelete = allowPermission('all') || allowPermission('delete');

  const customStylesHeaderTable = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        '&:last-of-type': {
          justifyContent: 'end',
          marginRight: '20px',
        },
      },
    },
    cells: {
      style: {
        '&:last-of-type': {
          justifyContent: 'end',
          marginRight: '20px',
        },
      },
    },
  };

  const stylesHeaderTable = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
  };

  let dataFilter = [
    {
      id: 'filter_text_filter',
      type: 'text',
      title: 'filter',
      name: 'filter',
      name_field: 'filter',
      value: '',
      placeholder: 'Cari Mobil',
    },
  ];

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row) => row?.name ?? '-',
        minWidth: '250px',
        sortable: true,
      },
      {
        name: 'Status Aktivasi',
        selector: (row) => row?.is_active ?? '-',
        cell: (row) => {
          if (row?.is_active) {
            return <span className="badge-custom badge-custom-order-done">Aktif</span>;
          } else if (!row?.is_active) {
            return <span className="badge-custom badge-custom-grey">Nonaktif</span>;
          }
        },
        minWidth: '150px',
      },
      {
        name: 'Tipe User',
        selector: (row) => row?.user_type?.name ?? '-',
        cell: (row) => row?.user_type?.name ?? '-',
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'Email',
        minWidth: '250px',
        selector: (row) => row?.email ?? '-',
        sortable: true,
        wrap: true,
      },
      // {
      //   name: 'Region',
      //   selector: (row) => row?.branch?.name ?? '-',
      //   minWidth: '100px',
      //   wrap: 'false',
      //   sortable: true,
      // },
      {
        name: 'Action',
        omit: hideAction,
        selector: (row) => '',
        cell: (row) => (
          <div className="cursor-pointer text-blue">
            <AccountDropdrown
              dropdownOpen={dropdownOpen}
              toggle={toggle}
              id={row?.id}
              isActive={row?.is_active ?? null}
              name={row?.name ?? ''}
              handleFetchAccount={() => fetchAccount()}
              onLoading={handleLoading}
              handleResultNotification={handleResultNotification}
              allowEdit={allowEdit}
              allowActivation={allowActivation}
              allowDelete={allowDelete}
            ></AccountDropdrown>
          </div>
        ),
        minWidth: '70px',
      },
    ],
    [hideAction]
  );

  useEffect(() => {
    if (!allowActivation && !allowDelete && !allowEdit) {
      sethideAction(true);
    } else {
      sethideAction(false);
    }
  }, [allowActivation, allowDelete, allowEdit]);

  const handleChangeApprove = (value, sku) => {
    const index = findIndex(data, (value) => {
      return value?.sku === sku;
    });

    setData((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], approve: value };

      return updated;
    });
  };

  useEffect(() => {
    handleChangeApprove(control?.value, control?.slug);
  }, [control]);

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleLoading = (status) => {
    setLoading(status);
  };

  async function fetchAccount() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/fleet/account/`, { params });
      setData(response?.data?.data ?? []);
      // setData(dataDummy ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      // console.log(error);
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan data Akun, ` + error);
    }
  }

  const getParamData = () => {
    let params = {
      page: page,
      limit: pageLimit,
    };

    if (filtered?.filter) {
      assign(params, {
        keyword: filtered?.filter ?? null,
      });
    }

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchAccount();
  };

  const handleMethodfetchAccount = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchAccount();
    }
  };

  const resetBookings = () => {
    setResetSorting(true);
    setSortingData({});
  };

  useEffect(() => {
    if (page && pageLimit) {
      fetchAccount();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    resetBookings();
  }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, [statusOptions]);

  return (
    <>
      <Wrapper
        customContentClassName="px-5"
        breadcrumbData={generateBreadcrumb()}
        customBreadcrumbClassName={'pb-0 pt-3'}
        showNotification={hasNotif}
        notification={notification}
      >
        <Row className="mb-3 mt-0">
          <Col xs={12} md={4}>
            <h3>User Management</h3>
          </Col>
          <Col xs={12} md={8} className="">
            <div className="filter-sides">
              {allowAdd && (
                <div className="btn-approve-resp">
                  <Button className="button-action save btn-blue btn-create-responsive" onClick={handleCreateUser}>
                    {'Tambah User'}
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="p-0 my-4" style={{ backgroundColor: 'white' }}>
          <Col sm={12} md={12} className="p-0 mb-0">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              data={allowList ? data : []}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={hideAction ? stylesHeaderTable : customStylesHeaderTable}
              onRowClicked={(row) => openAccountDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="pb-2 m-0">
            {data?.length >= 1 && !loading && allowList && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </Row>
      </Wrapper>
    </>
  );
}

export default Accounts;
