import React from 'react';
import { Badge } from 'reactstrap';

const BookingFleetBadges = ({ status }) => {
  switch (status?.slug) {
    case 'review':
      return <span className="badge-custom badge-custom-waiting-estimation">{status?.name}</span>;
    case 'waiting':
      return <span className="badge-custom badge-custom-need-confirm">{status?.name}</span>;
    case 'complain':
      return <span className="badge-custom badge-custom-revision">{status?.name}</span>;
    case 'confirm':
      return <span className="badge-custom badge-custom-waiting-service">{status?.name}</span>;
    case 'on-process':
      return <span className="badge-custom badge-custom-on-process">{status?.name}</span>;
    case 'service-done':
      return <span className="badge-custom badge-custom-service-done">{status?.name}</span>;
    case 'finish':
      return <span className="badge-custom badge-custom-order-done">{status?.name}</span>;
    case 'cancel':
      return <span className="badge-custom badge-custom-cancel">{status?.name}</span>;
    default:
      return <span className="badge-custom badge-custom-grey">{status?.name ?? '-'}</span>;
  }
};

export default BookingFleetBadges;
