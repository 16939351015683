import React, { useEffect, useState } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import CustomerForms from '@components/account/CustomerForms';
import { useHistory } from 'react-router-dom';
import userPermission from '@utils/userPermission';
import { Row, Col } from 'reactstrap';

const AccountCreate = () => {
  const history = useHistory();
  const { generatePermission } = userPermission();

  const [notification, setNotification] = useState({});
  const [hasNotification, setHasNotification] = useState(false);

  const breadcrumb = [
    {
      id: 1,
      label: 'Akun',
      active: false,
      url: '/accounts',
    },
    {
      id: 2,
      label: 'User Management',
      active: false,
      url: '/accounts',
    },
    {
      id: 3,
      label: 'Tambah User',
      active: true,
      url: '/accounts/create',
    },
  ];

  const allowPermission = (menu) => {
    const allowAccess = !!(generatePermission('user', menu) || generatePermission('user', 'all'));
    if (!allowAccess) return history.push('/404');
    return allowAccess;
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = async (notification) => {
    const { status, type, message } = notification;
    if (status) {
      setNotification({
        type: type,
        message: message,
      });
    }

    setHasNotification(status);
    setTimeout(() => {
      setHasNotification(false);
      setNotification({});
      return status;
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotification(false);
    setNotification({});
  };

  useEffect(() => {
    allowPermission('add');
  }, []);

  const renderContent = () => {
    return (
      <>
        <Wrapper
          customContentClassName="px-5"
          customBreadcrumbClassName={'pb-0 pt-3'}
          breadcrumbData={breadcrumb}
          showNotification={hasNotification}
          notification={notification}
          onCloseNotification={handleCloseNotification}
        >
          <Row className="mb-3 mt-0 d-flex" xs={2}>
            <Col xs={12} md={3}>
              <h3>{'Tambah User'}</h3>
            </Col>
          </Row>
          <CustomerForms
            origin="add"
            allowPermission={allowPermission('add')}
            handleResultNotification={handleResultNotification}
          />
        </Wrapper>
      </>
    );
  };

  return renderContent();
};

export default AccountCreate;
