import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader, Row, Button, Col } from 'reactstrap';
import { Badge } from 'reactstrap';
import moment from 'moment';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import UploadIcon from '@assets/icons/upload-file.svg';
import UploadFile from '@components/modal/UploadFile';
import API from '@utils/API';
import editIcon from '@assets/icons/edit.svg';
import InputField from '@components/field/InputField';
import UploadFileConfig from '@components/modal/UploadFileConfig';
import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import { assign } from 'lodash';
import PreviewDownload from '@components/modal/PreviewDownload';
import Helper from '@helpers/Helper';

const dataDummy = [
  {
    id: 1,
    time: '',
    file_name: 'SPK',
    document: 'doc',
  },
  {
    id: 2,
    time: '2023-02-09T19:44:44.969691Z',
    file_name: 'STNK',
    document: 'doc',
  },
];

const BookingDetailFileUser = (props) => {
  const api = new API('v2');
  const { detailData, onLoading, handleResultNotification, onFetchBookingDetail, id } = props;
  const { generatePermission } = userPermission();
  const [fileSelected, setFileSelected] = useState('');
  const [filename, setFilename] = useState('');
  const [selectedFile, setSelectedFile] = useState({});
  const [SPKNumber, setSPKNumber] = useState('');
  const [errorSPKNumber, setErrorSPKNumber] = useState('');

  const [hasModalUpload, setHasModalUpload] = useState(false);
  const [isEditSPKNumber, setIsEditSPKNumber] = useState(false);

  const [activeFile, setActiveFile] = useState({});
  const [uploadFile, setUploadFile] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [errorImage, setErrorImage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleResultUploadFile = (result) => {
    onLoading(true);
    const file = result?.file;

    if (file?.name) {
      const payloadUploadFile = new FormData();
      payloadUploadFile.append('namespace', 'booking-document');
      payloadUploadFile.append('identifier', id);
      payloadUploadFile.append('file', file);
      handleUploadImage(payloadUploadFile, file?.name);
    } else {
      handleResultNotification(true, 'fail', 'Gagal upload file ' + selectedFile?.label + ' file tidak diketahui');
      onLoading(false);
    }

    setHasModalUpload(!hasModalUpload);
  };

  async function handleUploadImage(payload, fileName) {
    api
      .post(`v2/fleet/file/upload/`, payload)
      .then((response) => {
        const keyFile = response?.data?.data[0]?.key ?? '';
        if (keyFile !== '') {
          handleSaveFile(keyFile, fileName);
        } else {
          handleResultNotification(true, 'fail', 'Gagal upload file ' + selectedFile?.label);
          onLoading(false);
        }
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleResultNotification(true, 'fail', 'Gagal upload file ' + selectedFile?.label + ' ' + message);
        onLoading(false);
      });

    async function handleSaveFile(keyFile, fileName) {
      const payload = {
        document_type: selectedFile?.document_type,
        filename: fileName,
        key: keyFile,
      };
      api
        .post(`v2/fleet/booking/${id}/documents/`, payload)
        .then((response) => {
          handleResultNotification(true, 'success', 'Berhasil menyimpan file ' + selectedFile?.label, true);
          onFetchBookingDetail(id);
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          handleResultNotification(true, 'fail', 'Gagal menyimpan file ' + selectedFile?.label + ' ' + message);
          onLoading(false);
        });
    }
  }

  const handleModalUpload = (file, isEdit) => {
    setHasModalUpload(true);
    setActiveFile(file);
    setUploadFile(file);
    setIsEdit(isEdit);
  };

  const handleDeleteFile = (data) => {
    setShowModal(true);
    setActiveFile(data);
  };

  const handleErrorUploadFile = (msg, data = 'none') => {
    setErrorImage(msg);
    if (msg) {
      handleResultNotification(true, 'fail', 'Gagal upload file ' + data?.label_file + ' ' + msg);
    }
  };

  async function handleSaveFile(keyFile, fileName, file, spkNumber, is_deleted = false) {
    setShowModal(false);
    onLoading(true);
    const payload = {
      document_type: activeFile?.document_type,
      filename: fileName,
      key: keyFile?.length > 0 ? keyFile : activeFile?.key,
      is_deleted,
      id: activeFile?.id ?? null,
      master_document_config_id: activeFile?.master_document_config_id,
      slug: activeFile?.slug,
    };

    if (activeFile?.document_type == 'spk') assign(payload, { spk_number: spkNumber ?? activeFile?.spk_number });

    api
      .post(`v2/fleet/booking/${id}/documents/`, payload)
      .then((response) => {
        handleResultNotification(
          true,
          'success',
          `Berhasil ${is_deleted ? 'menghapus' : 'menyimpan'}  file ` + activeFile?.label,
          true
        );
        setTimeout(() => {
          onFetchBookingDetail(id);
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleResultNotification(
          true,
          'fail',
          `Gagal ${is_deleted ? 'menghapus' : 'menyimpan'} file ` + activeFile?.label + ' ' + message
        );
        onLoading(false);
      });
  }

  const previewPdf = (base64) => {
    const contentType = 'application/pdf';
    var blob = Helper.base64StringToBlob(base64, contentType);
    const dataUrl = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.style.position = 'fixed';
    iframe.style.right = '0';
    iframe.style.bottom = '0';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    iframe.src = dataUrl;
    document.body.appendChild(iframe);

    iframe.contentWindow.print();
  };

  const columns = useMemo(
    () => [
      {
        name: 'Time',
        selector: (row) =>
          moment(row?.created_at).isValid() ? moment(row?.created_at).format('MMM DD YYYY , HH:mm:ss') : '-',
        wrap: true,
        minWidth: '180px',
      },
      {
        name: 'File Name',
        selector: (row) => row?.label ?? '-',
        wrap: true,
        minWidth: '150px',
        cell: (row) => (
          <div className={`text-bold text-purple-2 ${row?.document_type == 'spk' ? 'label-required' : ''}`}>
            {row?.label ?? '-'}
          </div>
        ),
      },
      {
        name: 'File',
        selector: (row) => row?.filename,
        cell: (row) =>
          row?.filename ? (
            <>
              <>
                <Col lg={9} sm={9} md={9}>
                  {row?.document_type == 'spk' && <div className="mb-0">{row?.spk_number ?? '-'}</div>}
                  {row?.filename?.split('.')[row?.filename?.split('.')?.length - 1] == 'pdf' ? (
                    <div className="text-bold text-purple-2" onClick={() => previewPdf(row?.base64)}>
                      {row?.filename}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <PreviewDownload previewURL={row?.url} previewName={row?.filename} />
                    </div>
                  )}
                </Col>
                {(row?.document_type == 'spk' || row?.document_type == 'stnk') && (
                  <Col lg={3} sm={3} md={3}>
                    {row?.document_type == 'spk' ? (
                      <>
                        <img
                          className="ml-0 cursor-pointer"
                          src={EditIcon}
                          height={15}
                          onClick={() => handleModalUpload(row, true)}
                        />
                        <img
                          className="ml-3 cursor-pointer"
                          src={DeleteIcon}
                          height={15}
                          onClick={() => handleDeleteFile(row)}
                        />
                      </>
                    ) : (
                      <img
                        className="ml-0 cursor-pointer"
                        src={DeleteIcon}
                        height={15}
                        onClick={() => handleDeleteFile(row)}
                      />
                    )}
                  </Col>
                )}
              </>
            </>
          ) : (
            <Button className="cursor-pointer" color="light" onClick={(e) => handleModalUpload(row, false)}>
              Klik untuk mengisi data
            </Button>
          ),
        minWidth: '350px',
        wrap: true,
      },
    ],
    []
  );

  const conditionalRowStyles = [
    {
      when: (row) => row?.document_type == 'spk',
      style: {
        minHeight: '80px',
      },
    },
  ];

  const downloadFile = (url) => {
    if (url) {
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', '');
      link.click();
    }
  };

  const handleChangeSPKNumber = (e) => {
    let value = e.target.value;
    setSPKNumber(value);
    if (!value) {
      setErrorSPKNumber('tidak boleh kososng');
    } else {
      setErrorSPKNumber();
    }
  };

  const handleEditSPKNumber = () => {
    setIsEditSPKNumber(!isEditSPKNumber);
  };

  const handleCancelSPKNumber = () => {
    setIsEditSPKNumber(!isEditSPKNumber);
  };

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Lampiran File</CardHeader>
      <CardBody className="pb-0">
        {/* <div>
          <dl className="row align-items-center">
            <div className="col-sm-2 text-sm-left">
              <dt>No. SPK</dt>
            </div>
            {!isEditSPKNumber && (
              <>
                <div className="col-sm-8 text-sm-left">
                  <dd className="mb-1" style={{ whiteSpace: 'pre-line' }}>
                    {detailData ? '2434324/3423/3243' : '-'}
                  </dd>
                </div>
                <div className="col-sm-2 text-sm-right">
                  <img
                    className="zoom-icon"
                    src={editIcon}
                    height={16}
                    onClick={() => {
                      handleEditSPKNumber();
                    }}
                  />
                </div>
              </>
            )}
            {isEditSPKNumber && (
              <>
                <div className="col-sm-6 text-sm-left p-0">
                  <InputField
                    inputName="SPKNumber"
                    placeholder={'SPK Number'}
                    value={SPKNumber}
                    // disabled={disabled || disabledGLobal}
                    invalid={errorSPKNumber}
                    errorMessage={errorSPKNumber ?? ''}
                    onChange={(e) => handleChangeSPKNumber(e)}
                  />
                </div>
                <div className="col-sm-4 text-sm-right section-action">
                  <Row>
                    <Col lg={6}>
                      <Button
                        className="button-outline purple-outline"
                        color="purple"
                        // disabled={disabled || disabledGLobal}
                        onClick={() => {
                          handleCancelSPKNumber();
                        }}
                      >
                        Batal
                      </Button>
                    </Col>
                    <Col lg={6}>
                      <Button
                        className="btn-export-finance w-65"
                        // disabled={disabled || disabledGLobal}
                        // onClick={handleSubmit}
                      >
                        Simpan
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </dl>
        </div> */}
        <DataTable
          persistTableHead
          highlightOnHover
          data={detailData}
          noHeader={true}
          fixedHeader
          fixedHeaderScrollHeight="239px"
          columns={generatePermission('booking', 'show') ? columns : []}
          className="table table-bordered dt-responsive"
          conditionalRowStyles={conditionalRowStyles}
        />
      </CardBody>
      <UploadFileConfig
        id={id}
        title={`${isEdit ? 'Edit' : 'Isi'} Data`}
        subTitle={``}
        modal={hasModalUpload}
        toggle={() => setHasModalUpload(!hasModalUpload)}
        onError={(e) => handleErrorUploadFile(e)}
        file={uploadFile}
        onConfirm={(e) => handleSaveFile(e?.key, e?.fileName, e?.file, e?.spkNumber)}
      />
      <ConfirmationModal
        modal={showModal}
        isDisabled={false}
        toggle={() => setShowModal(!showModal)}
        header={`Yakin ingin menghapus file "${activeFile?.label}" ?`}
        onConfirm={() => handleSaveFile(null, activeFile?.filename, activeFile, null, true)}
        toggleClose={() => setShowModal(!showModal)}
      />
      {/* <UploadFile
        isValidation={true}
        title={'Unggah File'}
        subTitle={`Unggah ${selectedFile?.label} dengan maksimal size 5 MB.`}
        sizeString={'5 MB'}
        sizeValidation={5120}
        fileType={'image/png, image/jpeg, image/jpg, .pdf'}
        modal={hasModalUpload}
        resultUploadFile={handleResultUploadFile}
        toggle={() => setHasModalUpload(!hasModalUpload)}
      /> */}
    </Card>
  );
};

export default BookingDetailFileUser;
