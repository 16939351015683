import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import { getActiveLink } from '@reducers/App';
import { resetAuth, getUsername } from '@reducers/Auth';
import { authLogout } from '@actions/Auth';
import userPermission from '@utils/userPermission';
import Logo from '@assets/images/logo.svg';
import CustomModal from '@components/modal/CustomModal';
import ChangePasswordForm from '@components/navbar/ChangePasswordForm';
import API from '@utils/API';
import { regexWithMinimumNumberCharacter } from '@utils/Constants';
import Avatar from '@assets/images/avatar.png';

function Dashboard() {
  const api = new API('v2');
  const dispatch = useDispatch();
  const activeLink = useSelector(getActiveLink);
  const username = useSelector(getUsername);
  const history = useHistory();
  const { showMainMenu, showSubMenu } = userPermission();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [ConfirmNewPassword, setConfirmNewPassword] = useState();
  const [errorMessageCurrent, setErrorMessageCurrent] = useState();
  const [errorMessageNew, setErrorMessageNew] = useState();
  const [errorMessageConfirm, setErrorMessageConfirm] = useState();
  const [disabledForm, setDisabledForm] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const showSystemAll = showSubMenu('system', 'all');

  const toggleSidebar = () => setHideSidebar((prevState) => !prevState);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove('intools_tok_c');

    dispatch(resetAuth());
  };

  const hasActiveLink = (router) => {
    if (router === activeLink) {
      return 'active';
    }

    return 'inactive';
  };

  const handleCurrentPassword = (e) => {
    setSubmitStatus(null);
    setCurrentPassword(e.target.value);
    // if (e.target.value.length < 1) {
    //   setErrorMessageCurrent('Password is empty or invalid format');
    // } else if (e.target.value.length < 8) {
    //   setErrorMessageCurrent('minimal character is 8');
    // } else if (e.target.value.length > 5) {
    //   setErrorMessageCurrent();
    // }
    if (!e.target.value) {
      setErrorMessageCurrent('Password is empty or invalid format');
    } else {
      setErrorMessageCurrent();
    }
  };

  const handleNewPassword = (e) => {
    setSubmitStatus(null);
    setNewPassword(e.target.value);
    const isPasswordCorrect = regexWithMinimumNumberCharacter.test(e.target.value);
    // if (e.target.value.length < 1) {
    //   setErrorMessageNew('Password is empty or invalid format');
    // } else if (e.target.value.length > 5) {
    //   if (ConfirmNewPassword !== e.target.value) {
    //     setErrorMessageConfirm('Passwords are not matching');
    //     setErrorMessageNew();
    //   } else if (ConfirmNewPassword === e.target.value) {
    //     setErrorMessageConfirm();
    //     setErrorMessageNew();
    //   }
    // } else if (e.target.value.length < 6) {
    //   setErrorMessageNew('minimal character is 6');
    // }
    if (isPasswordCorrect) {
      setErrorMessageNew();
      if (ConfirmNewPassword !== e.target.value) {
        setErrorMessageConfirm('Passwords are not matching');
      } else {
        setErrorMessageConfirm();
      }
    } else if (!e.target.value) {
      setErrorMessageNew('Password is empty or invalid format');
    } else if (!isPasswordCorrect) {
      setErrorMessageNew('Use 8 or more characters with a mix of letters and numbers');
    }
  };

  const handleConfirmNewPassword = (e) => {
    setSubmitStatus(null);
    setConfirmNewPassword(e.target.value);
    // if (e.target.value.length < 1) {
    //   setErrorMessageConfirm('Password is empty or invalid format');
    // } else if (e.target.value.length < 8) {
    //   setErrorMessageConfirm('minimal character is 6');
    // } else if (e.target.value.length > 7) {
    //   if (newPassword !== e.target.value) {
    //     setErrorMessageConfirm('Passwords are not matching');
    //   } else if (newPassword === e.target.value) {
    //     setErrorMessageConfirm();
    //   }
    // }
    if (!e.target.value) {
      setErrorMessageConfirm('Repeat Password is empty or invalid format');
    } else if (newPassword !== e.target.value) {
      setErrorMessageConfirm('Passwords are not matching');
    } else {
      setErrorMessageConfirm();
    }
  };

  const resetForm = () => {
    setSubmitStatus(null);
    setCurrentPassword();
    setNewPassword();
    setConfirmNewPassword();
    setErrorMessageCurrent();
    setErrorMessageNew();
    setErrorMessageConfirm();
  };

  const validationData = () => {
    let status = true;

    // if (!currentPassword) {
    //   setErrorMessageCurrent('Password is empty or invalid format');
    //   status = false;
    // } else if (currentPassword.length < 6) {
    //   setErrorMessageCurrent('minimal character is 6');
    //   status = false;
    // }

    // if (!newPassword) {
    //   setErrorMessageNew('Password is empty or invalid format');
    //   status = false;
    // } else if (newPassword.length < 6) {
    //   setErrorMessageNew('minimal character is 6');
    //   status = false;
    // }

    // if (!ConfirmNewPassword) {
    //   setErrorMessageConfirm('Password is empty or invalid format');
    //   status = false;
    // } else if (ConfirmNewPassword.length < 6) {
    //   setErrorMessageConfirm('minimal character is 6');
    //   status = false;
    // }

    // if (newPassword !== ConfirmNewPassword) {
    //   setErrorMessageConfirm('Passwords are not matching');
    //   status = false;
    // }

    if (!currentPassword) {
      setErrorMessageCurrent('Password is empty or invalid format');
      status = false;
    }

    if (!newPassword) {
      setErrorMessageNew('Password is empty or invalid format');
      status = false;
    }

    const isNewPasswordCorrect = regexWithMinimumNumberCharacter.test(newPassword);
    if (newPassword && !isNewPasswordCorrect) {
      setErrorMessageNew('Use 8 or more characters with a mix of letters and numbers');
      status = false;
    }

    if (!ConfirmNewPassword) {
      setErrorMessageConfirm('Password is empty or invalid format');
      status = false;
    }

    if (newPassword !== ConfirmNewPassword) {
      setErrorMessageConfirm('Passwords are not matching');
      status = false;
    }

    return status;
  };

  const handleSubmitNewPassword = (e) => {
    e.preventDefault();
    // console.log(validationData());
    // return;
    if (!validationData()) {
      return;
    }
    handleOnConfirm();
  };

  const handleOnConfirm = () => {
    setSubmitStatus(null);
    setDisabledForm(true);
    const payload = {
      current: currentPassword,
      new: newPassword,
      confirm: ConfirmNewPassword,
    };

    api
      .post(`v2/fleet/auth/change-password/`, payload)
      .then((response) => {
        showAlert('success', response?.message ?? 'Berhasil update password.');
        setTimeout(() => {
          setShowChangePass(false);
          setDisabledForm(false);
          resetForm();
        }, 500);
      })
      .catch((error) => {
        showAlert('danger', error?.response?.data?.error?.message ?? 'Gagal update password.');
        setTimeout(() => {
          setDisabledForm(false);
        }, 500);
      });
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });

    // setTimeout(() => {
    //   setSubmitStatus(null);
    // }, 3000);
  };

  const handleClickActionButton = (source = '') => {
    if (source === 'wa')
      return window.open(
        'https://wa.me/62811920025?text=Halo%20OtoBuddy,%20saya%20ingin%20menanyakan%20service%20di%20Otoklix',
        '_blank'
      );
    return handleOpenChat();
  };

  const handleOpenChat = () => {
    if (typeof window !== 'undefined') {
      const chatId = document.getElementById('fc_frame');
      if (chatId) {
        window.fcWidget.open();
      }
    }
  };

  return (
    <>
      <CustomModal
        modal={showChangePass}
        modalWidth="800"
        backdrop="static"
        toggle={() => {
          setShowChangePass(false);
          resetForm();
        }}
      >
        <ChangePasswordForm
          currentPassword={currentPassword}
          newPassword={newPassword}
          ConfirmNewPassword={ConfirmNewPassword}
          errorMessageCurrent={errorMessageCurrent}
          errorMessageNew={errorMessageNew}
          errorMessageConfirm={errorMessageConfirm}
          toggleClose={() => {
            setShowChangePass(false);
            resetForm();
          }}
          disabledForm={disabledForm}
          onChangeCurrentPassword={handleCurrentPassword}
          onChangeNewPassword={handleNewPassword}
          onChangeConfirmNewPassword={handleConfirmNewPassword}
          onConfirm={(e) => handleSubmitNewPassword(e)}
          submitStatus={submitStatus}
        />
      </CustomModal>

      <div className={`left-sidenav ${hideSidebar ? '' : 'hidden-resp'}`}>
        <div className="topbar-left">
          <a href="/" className="logo">
            <span>
              <img src={Logo} alt="logo-small" className="logo-sm" />
            </span>
          </a>
        </div>

        <div className="leftbar-profile p-3 w-100">
          <div className="media position-relative">
            <div className="leftbar-user online">
              <img src={Avatar} className="thumb-md rounded-circle" />
            </div>
            <div className="media-body align-self-center text-truncate ml-3">
              <h5 className="mt-0 mb-1 font-weight-semibold">{username}</h5>
              <Row>
                <Col md={4}>
                  <Link to="#" onClick={() => handleLogout()} className="text-uppercase mb-0 font-11">
                    Logout
                  </Link>
                </Col>
                <Col md={4}>
                  <Link
                    to="#"
                    onClick={() => setShowChangePass(!showChangePass)}
                    className="text-uppercase invalid-hour mb-0 font-11"
                  >
                    Change Password
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <ul className="left-sidenav-menu slimscroll">
          <li className="leftbar-menu-item mt-2">
            <Link to="/dashboard" onClick={() => toggleSidebar()} className={hasActiveLink('dashboard')}>
              <i className="mdi mdi-desktop-mac align-self-center vertical-menu-icon"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          {showMainMenu('booking') && (
            <li className="leftbar-menu-item">
              <Link to="/bookings" onClick={() => toggleSidebar()} className={hasActiveLink('booking')}>
                <i className="mdi mdi-book align-self-center vertical-menu-icon"></i>
                <span>Booking / Order</span>
              </Link>
            </li>
          )}

          {showMainMenu('service') && (
            <li className="leftbar-menu-item">
              <Link to="/service-history" onClick={() => toggleSidebar()} className={hasActiveLink('service')}>
                <i className="mdi mdi-book align-self-center vertical-menu-icon"></i>
                <span>Riwayat Servis</span>
              </Link>
            </li>
          )}

          {showMainMenu('garage') && (
            <li className="leftbar-menu-item">
              <Link to="/fleets" onClick={() => toggleSidebar()} className={hasActiveLink('fleet')}>
                <i className="mdi mdi-car align-self-center vertical-menu-icon"></i>
                <span>Mobil</span>
              </Link>
            </li>
          )}

          {(showSubMenu('user', 'all') || showSubMenu('user', 'list')) && (
            <li className="leftbar-menu-item">
              <Link to="/accounts" onClick={() => toggleSidebar()} className={hasActiveLink('user')}>
                <i className="mdi mdi-account-multiple align-self-center vertical-menu-icon"></i>
                <span>Akun</span>
              </Link>
            </li>
          )}

          {!showSubMenu('user', 'list') && showSubMenu('user', 'show') && (
            <li className="leftbar-menu-item">
              <Link to="/accounts/me" onClick={() => toggleSidebar()} className={hasActiveLink('user')}>
                <i className="mdi mdi-account-multiple align-self-center vertical-menu-icon"></i>
                <span>Akun</span>
              </Link>
            </li>
          )}
        </ul>
      </div>

      <div className="topbar">
        <nav className="navbar-custom">
          <ul className="list-unstyled topbar-nav mb-0 menu-resp">
            <li>
              <button className="button-menu-mobile nav-link" onClick={() => toggleSidebar()}>
                <i className="mdi mdi-menu align-self-center hamburger-menu" />
              </button>
            </li>
            <li>
              <div className="logo button-menu-mobile nav-link">
                <span>
                  <img src={Logo} alt="logo-small" height={'30px'} className="align-self-center" />
                </span>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div
        id="custom_chat_button"
        className="default-custom_chat_button "
        onClick={() => handleClickActionButton('wa')}
      ></div>
    </>
  );
}

export default Dashboard;
