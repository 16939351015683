const config = {
  api_url: process.env.REACT_APP_API_URL || 'https://api.backoffice.stg.otoklix.com/',
  app_environment: process.env.REACT_APP_ENV || 'staging',
  posthog_key: process.env.REACT_APP_PUBLIC_POSTHOG_KEY || 'phc_k9Xkff7spE60WDX0FUM4pIfM5hy4UQmOAKd5OBvc78I',
  posthog_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
  api_url_v2: process.env.REACT_APP_API_URL_V2 || 'https://api.stg.otoklix.com/',
  webapp_api_url: process.env.REACT_APP_WEBAPP_API_URL || 'https://api.web.stg.otoklix.com/',
};

export default config;
