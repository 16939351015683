import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Input, Col, Label, Row } from 'reactstrap';
import API from '@utils/API';
import SelectField from '@components/field/SelectField';
import InputField from '@components/field/InputField';
import InputMask from 'react-input-mask';
import { map } from 'lodash';
import Helper from '@helpers/Helper';
import assign from 'lodash/assign';

const EstimastionNoteModal = (props) => {
  const { modal, toggle, data, onSubmit } = props;

  const api = new API('v2');
  const [disableForm, setDisableForm] = useState(false);
  const [note, setNote] = useState();
  const [errorNote, setErrorNote] = useState();
  const [loading, setLoading] = useState(false);

  const customStyles = {
    control: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? '#052C65' : '#052C65',
      backgroundColor: isDisabled ? '#DEE2E6' : '#FFFFFF',
      border: '1px solid #CED4DA',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#052C65',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#6C757D',
    }),
  };

  const resetData = () => {
    setNote();
    setErrorNote();
  };

  const validate = () => {
    let status = true;

    // if (!note) {
    //   status = false;
    //   setErrorNote('Tidak boleh kosong');
    // }

    return status;
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    } else {
      toggle();
      onSubmit(note);
    }
  };

  const handleChangeNote = (e) => {
    let note = e?.target?.value;
    setNote(note);
    // if (note?.length < 1 || !note) setErrorNote('Tidak boleh kosong');
    // else setErrorNote();
  };

  const cancel = () => {
    resetData();
    toggle();
  };

  useEffect(() => {
    setNote(data?.note);
  }, [data]);

  return (
    <Modal className="modal-dialog-centered modal-edit-inventory" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3 mx-0">
        <div className="header">{`${data?.hideButton ? 'Data' : 'Edit'} Catatan`}</div>
        <div className="text-center w-75">Untuk {data?.product}</div>
        <div className="content">
          <FormGroup row>
            <Label lg={2}>Catatan</Label>
            <Col lg={10}>
              <InputField
                inputType="textarea"
                disabled={disableForm || data?.hideButton}
                invalid={errorNote}
                errorMessage={errorNote ?? ''}
                value={note}
                onChange={handleChangeNote}
                placeholder={'Note'}
              />
            </Col>
          </FormGroup>
        </div>

        <div className="action mb-3 mt-0">
          <Button className="button-outline purple-outline w-30" color="purple" onClick={cancel} disabled={loading}>
            {`${data?.hideButton ? 'Kembali' : 'Batal'}`}
          </Button>
          {!data?.hideButton && (
            <Button color="purple" className="btn-export-finance w-30" onClick={handleSubmit} disabled={loading}>
              Simpan
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EstimastionNoteModal;
