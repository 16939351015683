import React, { useState, useMemo, useEffect } from 'react';
import { Badge } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import ExportButton from '@components/export/ExportButton';
import ActionFilterStnk from '@components/filters/ActionFilterStnk';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import userPermission from '@utils/userPermission';

function Stnk() {
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [stnkStatus, setStnkStatus] = useState({ value: '', label: 'Semua Plat Nomer' });
  const [licensePlate, setLicensePlate] = useState('');
  const [customerName, setCustomerName] = useState('');

  useEffect(() => {
    loadListStnk(1);
  }, []);

  async function loadListStnk(page) {
    setLoading(true);
    const response = await api.get(
      `v2/intools/user-cars/?status=${stnkStatus.value}&license_plate=${licensePlate}&customer=${customerName}&page=${page}&limit=${perPage}`
    );
    setData(response.data.data.user_cars);
    setTotalRows(response.data.pagination.total_rows);
    setLoading(false);
  }

  const openStnkDetail = (row) => {
    if (generatePermission('customer', 'cardetail') || generatePermission('customer', 'carall')) {
      history.push(`/stnk/${row.id}`);
    }
  };

  const handlePageChange = (page) => {
    loadListStnk(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await api.get(
      `v2/intools/user-cars/?status=${stnkStatus.value}&license_plate=${licensePlate}&page=${page}&limit=${newPerPage}`
    );

    setData(response.data.data.user_cars);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Customer Name',
        selector: 'customer.name',
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'License Plate',
        selector: 'license_plate',
        minWidth: '150px',
      },
      {
        name: 'Status',
        cell: (row) => {
          if (row?.stnk_status === 'unverified') {
            return <Badge className="p-2 badge-warning">Unverified</Badge>;
          } else if (row?.stnk_status === 'verified') {
            return <Badge className="p-2 badge-secondary">Verified</Badge>;
          } else if (row?.stnk_status === 'checking') {
            return <Badge className="p-2 badge-info">Menunggu Verifikasi</Badge>;
          } else {
            return <Badge className="p-2 badge-danger">No Status</Badge>;
          }
        },
        wrap: true,
      },
    ],
    []
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleSearch = () => {
      loadListStnk(1);
    };

    return (
      <ActionFilterStnk
        onChangeStnkStatus={(e) => setStnkStatus(e)}
        onChangeLicensePlate={(e) => setLicensePlate(e.target.value)}
        onChangeCustomerName={(e) => setCustomerName(e.target.value)}
        stnkStatus={stnkStatus}
        onSearch={handleSearch}
        licensePlate={licensePlate}
        customerName={customerName}
      />
    );
  }, [licensePlate, customerName, stnkStatus]);

  return (
    <Wrapper title="List Customer Car" className="wrapperContent">
      <DataTable
        persistTableHead
        highlightOnHover
        pagination
        subHeader
        progressPending={loading}
        data={data}
        columns={generatePermission('customer', 'carlist') || generatePermission('customer', 'carall') ? columns : []}
        className="table table-bordered dt-responsive"
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressComponent={<Loading />}
        actions={
          <ExportButton
            data={data}
            hasActionExport={generatePermission('customer', 'carexport') || generatePermission('customer', 'carall')}
            title="Export CSV"
            keys={['customer.name', 'license_plate']}
            header={['customer name', 'license plate']}
          />
        }
        subHeaderComponent={
          generatePermission('customer', 'carsearch') || generatePermission('customer', 'carall')
            ? subHeaderComponentMemo
            : ''
        }
        onRowClicked={(row) => openStnkDetail(row)}
      />
    </Wrapper>
  );
}

export default Stnk;
