import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Input, Col, Label, Row } from 'reactstrap';
import API from '@utils/API';
import SelectField from '@components/field/SelectField';
import InputField from '@components/field/InputField';
import InputMask from 'react-input-mask';
import { map } from 'lodash';
import Helper from '@helpers/Helper';
import assign from 'lodash/assign';
import SelectAsyncField from '@components/field/SelectAsyncField';
import { regexOnlyAllowNumber } from '@utils/Constants';

const CreateFleetModal = (props) => {
  const { modal, toggle, dataFleet, onSubmit, onLoading, handleResultNotification } = props;

  const api = new API('v2');
  const [userInfo, setUserInfo] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [branchOptions, setBranchOptions] = useState();
  const [car, setCar] = useState();
  const [transmission, setTransmission] = useState();
  const [year, setYear] = useState();
  const [branch, setBranch] = useState([]);
  const [mileage, setMileage] = useState();
  const [errorCar, setErrorCar] = useState();
  const [errorTransmission, setErrorTransmission] = useState();
  const [errorYear, setErrorYear] = useState();
  const [errorPlate, setErrorPlate] = useState();
  const [errorBranch, setErrorBranch] = useState();
  const [errorFuel, setErrorFuel] = useState();
  const [errorMileage, setErrorMileage] = useState();
  const [fuel, setFuel] = useState();
  const [status, setStatus] = useState(true);
  const [cityCode, setCityCode] = useState();
  const [plate, setPlate] = useState();
  const [areaCode, setAreaCode] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingFetchData, setLoadingFetchData] = useState(false);
  const [inputTag, setInputTag] = useState('');

  const customStyles = {
    control: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? '#052C65' : '#052C65',
      backgroundColor: isDisabled ? '#DEE2E6' : '#FFFFFF',
      border: '1px solid #CED4DA',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#052C65',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#6C757D',
    }),
  };

  const defaultData = (data) => {
    const car = {
      value: data?.brand_type?.id ?? '',
      label: data?.brand_type?.name ?? '',
    };
    const mileage = Helper.formatMoneyOnField(data?.mileage?.toString() ?? '0');
    const transmision = {
      value: data?.transmission == 'mt' ? 'mt' : 'at',
      label: data?.transmission == 'mt' ? 'Manual' : 'Automatic',
    };
    const year = { value: parseInt(data?.year) ?? '', label: parseInt(data?.year) ?? '' };
    const fuel = { value: data?.fuel == 'P' ? 'P' : 'D', label: data?.fuel == 'P' ? 'Bensin' : 'Solar' };
    const branch = { value: data?.branch?.id, label: data?.branch?.name };

    const tags = data?.tags?.map((item) => {
      return { value: item, label: item };
    });

    setCar(car);
    mappingPlateNumber(data?.license_plate ?? '');
    setMileage(mileage);
    setTransmission(transmision);
    setYear(year);
    setFuel(fuel);
    setBranch(data?.tags ? tags : []);
    setStatus(data?.is_active);
  };

  const mappingPlateNumber = (plateNumber) => {
    const regexNumb = new RegExp(regexOnlyAllowNumber);
    const cleanPlate = plateNumber ? plateNumber.replace(/  +/g, ' ').trim().toUpperCase() : '';
    const splitPlate = cleanPlate ? cleanPlate.split(' ') : [];

    let prefix = '';
    let number = '';
    let suffix = '';

    if (splitPlate?.length == 2) {
      if (regexNumb.test(splitPlate[0])) {
        number = splitPlate[0] ? splitPlate[0].substring(0, 4) : '';
        suffix = splitPlate[1] ? splitPlate[1].substring(0, 3) : '';
      } else {
        prefix = splitPlate[0] ? splitPlate[0].substring(0, 2) : '';
        number = splitPlate[1] ? splitPlate[1].substring(0, 4) : '';
      }
    } else if (splitPlate?.length == 1) {
      if (regexNumb.test(splitPlate[0])) {
        number = splitPlate[0] ? splitPlate[0].substring(0, 4) : '';
      } else {
        prefix = splitPlate[0] ? splitPlate[0].substring(0, 2) : '';
      }
    } else {
      prefix = splitPlate[0] ? splitPlate[0].substring(0, 2) : '';
      number = splitPlate[1] ? (!regexNumb.test(splitPlate[1]) ? '' : splitPlate[1].substring(0, 4)) : '';
      suffix = splitPlate[2] ? splitPlate[2].substring(0, 3) : '';
    }

    setCityCode(prefix);
    setPlate(number);
    setAreaCode(suffix);
  };

  const resetData = () => {
    setCar();
    setTransmission();
    setYear();
    setStatus(true);
    setFuel();
    setCityCode();
    setPlate();
    setAreaCode();
    setBranch([]);
    setMileage();
    setErrorCar();
    setErrorTransmission();
    setErrorYear();
    setErrorPlate();
    setErrorBranch();
    setErrorFuel();
    setErrorMileage();
  };

  const validate = () => {
    let status = true;

    if (!car?.value && !dataFleet) {
      status = false;
      setErrorCar('Tidak boleh kosong');
    }

    if ((mileage?.formatMoney < 1 || !mileage?.formatMoney) && !dataFleet) {
      status = false;
      setErrorMileage('Tidak boleh kosong');
    }

    if (!transmission?.value && !dataFleet) {
      status = false;
      setErrorTransmission('Tidak boleh kosong');
    }

    if (!year?.value && !dataFleet) {
      status = false;
      setErrorYear('Tidak boleh kosong');
    }

    if (!plate && !dataFleet) {
      status = false;
      setErrorPlate('Tidak boleh kosong');
    }

    if (dataFleet) {
      if (!fuel?.value) {
        status = false;
        setErrorFuel('Tidak boleh kosong');
      }
    }
    return status;
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    } else {
      setDisableButton(true);
      checkPlateNumber(true);
    }
  };

  const submitForm = () => {
    setDisableForm(true);
    setLoading(true);
    onLoading(true);
    setDisableButton(true);

    const url = dataFleet ? `v2/fleet/garage/${dataFleet?.id}/` : `v2/fleet/garage/`;
    const payload = {
      brand_type: {
        id: car?.value,
        name: car?.label,
      },
      license_plate: getLicensePlate(),
      transmission: transmission?.value,
      year: year?.value,
      fuel: fuel?.value,
      is_active: status,
      mileage: mileage?.formatMoney,
      tags: branch.map((item) => item?.label),
    };

    if (dataFleet) {
      return api
        .put(url, payload)
        .then((response) => {
          onSubmit(true);
          setLoading(false);
          setTimeout(() => {
            onLoading(false);
          }, 300);
          setDisableForm(false);
          setDisableButton(false);
        })
        .catch((error) => {
          onSubmit(false, error);
          setLoading(false);
          setTimeout(() => {
            onLoading(false);
          }, 300);
          setDisableForm(false);
          setDisableButton(false);
          if (!dataFleet) resetData();
        });
    } else if (!dataFleet) {
      return api
        .post(url, payload)
        .then((response) => {
          onSubmit(true);
          setLoading(false);
          onLoading(false);
          setDisableForm(false);
          resetData();
          setDisableButton(false);
        })
        .catch((error) => {
          onSubmit(false, error);
          setLoading(false);
          onLoading(false);
          setDisableForm(false);
          setDisableButton(false);
          resetData();
          setDisableButton(false);
        });
    }
  };

  const getLicensePlate = () => {
    let licensePlate = cityCode ?? '';
    licensePlate += plate ? (licensePlate ? ' ' + plate : plate) : '';
    licensePlate += areaCode ? (licensePlate ? ' ' + areaCode : areaCode) : '';

    return licensePlate;
  };

  const checkPlateNumber = (submit) => {
    let checkAreaCode = getLicensePlate();
    if (plate) {
      if (dataFleet?.license_plate !== checkAreaCode) {
        return api
          .get(`v2/fleet/garage/availability/?license_plate=${checkAreaCode}`)
          .then((response) => {
            setErrorPlate();
            if (submit) {
              toggle();
              submitForm();
            }
          })
          .catch((err) => {
            const error = err?.response?.data?.error?.message;
            setErrorPlate(error ?? 'duplicate code');
            setDisableButton(false);
          });
      } else if (dataFleet) {
        if (submit) {
          toggle();
          submitForm();
        }
      }
    } else {
      if (submit) {
        toggle();
        submitForm();
      }
    }
  };

  const fetchUserInfo = () => {
    return api
      .get(`v2/fleet/auth/`)
      .then((response) => {
        setUserInfo(response?.data?.data);
      })
      .catch(() => {
        handleResultNotification(true, 'failed', `Gagal - User info gagal didapatkan`);
      });
  };

  const getTagOptions = () => {
    return api
      .get(`v2/fleet/garage/car-tags`)
      .then((response) => {
        let resultBranchList = map(response?.data?.data, (item) => {
          return { value: item, label: item ?? '-' };
        });
        setBranchOptions(resultBranchList);
      })
      .catch((err) => {
        setBranchOptions();
        const error = err?.response?.data?.error?.message;
        handleResultNotification(true, 'failed', `Gagal mendapatkan data Tags, ` + error);
      });
  };

  const loadCarOptions = (value) => {
    let url = `v2/fleet/garage/brand/options/?keyword=${value}`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.name ?? '-' };
        });
        return transformedOptions;
      })
      .catch((err) => {
        const error = err?.response?.data?.error?.message;
        handleResultNotification(true, 'failed', `Gagal mendapatkan data Mobil, ` + error);
      });
  };

  const getYearOptions = () => {
    let years = Helper.generateYearOptions(1900, new Date().getFullYear(), true);

    return years;
  };

  const handleAddNewTag = () => {
    let data = { value: inputTag, label: inputTag };
    branch ? branch.push(data) : setBranch([data]);
    setInputTag();
  };

  const fuelOptions = [
    { value: 'P', label: 'Bensin' },
    { value: 'D', label: 'Solar' },
  ];

  const transmissionOptions = [
    { value: 'at', label: 'Automatic' },
    { value: 'mt', label: 'Manual' },
  ];

  const handleChangeCarMileage = (e) => {
    let mileageValue = e?.target?.value.replace(/[^0-9+()]/g, '');
    setMileage(Helper.formatMoneyOnField(mileageValue));

    if (mileageValue < 1 || !mileageValue) setErrorMileage('Tidak boleh kosong');
    else setErrorMileage();
  };

  const cancel = () => {
    resetData();
    toggle();
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (dataFleet) defaultData(dataFleet);
    if (modal && userInfo) getTagOptions();
  }, [modal, userInfo]);

  return (
    <Modal className="modal-dialog-centered modal-edit-inventory" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3 mx-0">
        <div className="header">{dataFleet ? 'Ubah Mobil' : 'Tambah Mobil'}</div>
        {!loadingFetchData && (
          <div className="content">
            <FormGroup row>
              <Label lg={4} className="label-required">
                {'Mobil'}
              </Label>
              <Col lg={8}>
                {/* <SelectField
                  name="car"
                  placeholder="Merk"
                  isSearchable={false}
                  value={car}
                  options={[]}
                  invalid={errorCar}
                  errorMessage={errorCar ?? ''}
                  isDisabled={disableForm}
                  styles={customStyles}
                  onChange={(e) => {
                    setCar(e);
                    setErrorCar();
                  }}
                /> */}
                <SelectAsyncField
                  isMulti={false}
                  // defaultOptions={[]}
                  placeholder={'Cari Merk . . .'}
                  isDisabled={disableForm || dataFleet}
                  value={car}
                  errorMessage={errorCar ?? ''}
                  loadOptions={loadCarOptions}
                  // isSelectAll={true}
                  closeMenuOnSelect={true}
                  onChange={(e) => {
                    setCar(e);
                    setErrorCar();
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={4} className="label-required">
                Plat nomor
              </Label>
              <Col lg={8}>
                <Row className="mx-0">
                  <Col lg={4} xs={4}>
                    <InputMask
                      className={`mask-input valid`}
                      value={cityCode?.toUpperCase()}
                      mask={'**'}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        setCityCode(e.target.value.toUpperCase());
                        if (plate) {
                          setErrorPlate('');
                        } else {
                          setErrorPlate('Tidak boleh kosong');
                        }
                      }}
                      disabled={disableForm || dataFleet}
                      onBlur={() => checkPlateNumber(false)}
                    >
                      <Input placeholder={'AA'}></Input>
                    </InputMask>
                  </Col>
                  <Col lg={4} xs={4}>
                    <InputMask
                      className={`mask-input ${errorPlate ? 'invalid' : 'valid'}`}
                      value={plate?.toUpperCase()}
                      aria-invalid={errorPlate}
                      mask={'9999'}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        setPlate(e.target.value.toUpperCase());
                        if (e.target.value) {
                          setErrorPlate('');
                        } else {
                          setErrorPlate('Tidak boleh kosong');
                        }
                      }}
                      disabled={disableForm || dataFleet}
                      onBlur={() => checkPlateNumber(false)}
                    >
                      <Input className="input-field" placeholder={'9999'} invalid={errorPlate}></Input>
                    </InputMask>
                  </Col>
                  <Col lg={4} xs={4}>
                    <InputMask
                      className={`mask-input valid`}
                      value={areaCode?.toUpperCase()}
                      mask={'***'}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        setAreaCode(e.target.value.toUpperCase());
                        if (plate) {
                          setErrorPlate('');
                        } else {
                          setErrorPlate('Tidak boleh kosong');
                        }
                      }}
                      disabled={disableForm || dataFleet}
                      onBlur={() => checkPlateNumber(false)}
                    >
                      <Input placeholder={'AAA'}></Input>
                    </InputMask>
                  </Col>
                  <Col>
                    <div className="invalid-hour">{errorPlate}</div>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={4} className="label-required">
                Odometer
              </Label>
              <Col lg={8}>
                <InputField
                  disabled={disableForm || dataFleet}
                  invalid={errorMileage}
                  errorMessage={errorMileage ?? ''}
                  value={mileage?.formatMoneyString}
                  onChange={handleChangeCarMileage}
                  placeholder={'Ex: 20.000 KM'}
                  suffix={'KM'}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={4} className="label-required">
                Transmisi
              </Label>
              <Col lg={8}>
                <SelectField
                  name="transmission"
                  placeholder="Transmission"
                  isSearchable={false}
                  value={transmission}
                  invalid={errorTransmission}
                  errorMessage={errorTransmission ?? ''}
                  options={transmissionOptions}
                  disabled={disableForm || dataFleet}
                  styles={customStyles}
                  onChange={(e) => {
                    setTransmission(e);
                    setErrorTransmission();
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={4} className="label-required">
                Tahun
              </Label>
              <Col lg={8}>
                <SelectField
                  name="year"
                  placeholder="Year"
                  isSearchable={true}
                  value={year}
                  invalid={errorYear}
                  errorMessage={errorYear ?? ''}
                  options={getYearOptions()}
                  disabled={disableForm || dataFleet}
                  styles={customStyles}
                  onChange={(e) => {
                    setYear(e);
                    setErrorYear();
                  }}
                />
              </Col>
            </FormGroup>
            {dataFleet && (
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Bahan bakar
                </Label>
                <Col lg={8}>
                  <SelectField
                    name="fuel"
                    placeholder="Fuel"
                    value={fuel}
                    options={fuelOptions}
                    disabled={disableForm || dataFleet}
                    invalid={errorFuel}
                    errorMessage={errorFuel ?? ''}
                    styles={customStyles}
                    onChange={(e) => {
                      setFuel(e);
                      setErrorFuel();
                    }}
                  />
                </Col>
              </FormGroup>
            )}
            <FormGroup row>
              <Label lg={4} className="">
                Tags
              </Label>
              <Col lg={8}>
                <SelectField
                  name="branch"
                  placeholder="Tags"
                  isSearchable={true}
                  value={branch}
                  invalid={errorBranch}
                  errorMessage={errorBranch ?? ''}
                  options={branchOptions}
                  disabled={disableForm || !branchOptions}
                  styles={customStyles}
                  isMulti={true}
                  onChange={(e) => {
                    setBranch(e);
                    setErrorBranch();
                  }}
                  isCreate={inputTag?.length > 0 ? true : false}
                  textLabelCreate={'Tambah Tags'}
                  needInputChange={true}
                  isLowerCaseInputSearch={true}
                  onInputChange={(e) => setInputTag(e)}
                  onClickCreate={() => handleAddNewTag()}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={4} className="label-required">
                Status
              </Label>
              <Col lg={8}>
                <Row>
                  <Col sm={12} md={3}>
                    <Label className="ml-4 mt-2 mb-0">
                      <Input type="radio" checked={status} onClick={() => setStatus(true)} disabled={disableForm} />
                      Aktif
                    </Label>
                  </Col>
                  <Col sm={12} md={3}>
                    <Label className="ml-4 mt-2 mb-0">
                      <Input type="radio" checked={!status} onClick={() => setStatus(false)} disabled={disableForm} />
                      Disposal
                    </Label>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </div>
        )}

        <div className="action mb-3 mt-0">
          <Button
            className="button-outline purple-outline w-30"
            color="purple"
            onClick={cancel}
            disabled={disableButton}
          >
            Batal
          </Button>
          <Button
            color="purple"
            className="btn-export-finance w-30"
            onClick={() => handleSubmit()}
            disabled={disableButton}
          >
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CreateFleetModal;
