export const UserType = {
  general: 'General',
  ucd: 'Used Car Dealer',
};

export const HourOptions = [
  { value: '00:00', label: '00:00' },
  { value: '01:00', label: '01:00' },
  { value: '02:00', label: '02:00' },
  { value: '03:00', label: '03:00' },
  { value: '04:00', label: '04:00' },
  { value: '05:00', label: '05:00' },
  { value: '06:00', label: '06:00' },
  { value: '07:00', label: '07:00' },
  { value: '08:00', label: '08:00' },
  { value: '09:00', label: '09:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '12:00', label: '12:00' },
  { value: '13:00', label: '13:00' },
  { value: '14:00', label: '14:00' },
  { value: '15:00', label: '15:00' },
  { value: '16:00', label: '16:00' },
  { value: '17:00', label: '17:00' },
  { value: '18:00', label: '18:00' },
  { value: '19:00', label: '19:00' },
  { value: '20:00', label: '20:00' },
  { value: '21:00', label: '21:00' },
  { value: '22:00', label: '22:00' },
  { value: '23:00', label: '23:00' },
];

export const CommonLabels = {
  at: 'Automatic',
  mt: 'Manual',
  ucd: 'Used Car Dealer',
  general: 'General',
  P: 'Bensin',
  D: 'Solar',
};

export const gmapConfig = () => {
  const config = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    fullscreenControl: false,
    gestureHandling: 'greedy',
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };
  return config;
};

export const monasLocation = {
  lat: -6.1753924,
  lng: 106.8271528,
};

export const GMAPS_API_KEY = 'AIzaSyC2pqT6mc7rU9DwBaNUIKnxLNucdahHzFU';

export const regexOnlyAllowSomeSymbols = "^[ A-Za-z0-9',./]*$";
export const regexOnlyAllowNumber = '^[0-9]*$';
export const regexOnlyAllowNumberCharacter = '^[A-Za-z0-9]*$';
export const regexOnlyAllowNumberCharacterSpace = '^[A-Za-z0-9 ]*$';
export const regexOnlyAllowCharacter = /^[a-zA-Z\s]*$/;
export const regexWithMinimumNumberCharacter = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

export const DISCOUNT_OPTIONS = { value: 5, label: '5%' };

export const DEFAULT_PRICE_AGREEMENT_VALUE = { value: 0, label: '0%' };

export const accountTypeOptions = [
  // delete soon
  { id: 1, value: 'b2c', label: 'B2C' },
  { id: 2, value: 'b2b', label: 'B2B' },
];

export const customerTypeOptions = [
  { id: 1, value: 'b2c', label: 'B2C' },
  { id: 2, value: 'b2b', label: 'B2B' },
];

export const userTypeOption = [
  { id: 1, value: 'General', label: 'General' },
  { id: 2, value: 'UCD', label: 'UCD' },
  { id: 3, value: 'O2O', label: 'O2O' },
];

export const flagTypeOption = [
  { id: 1, value: 'default', label: 'None' },
  { id: 2, value: 'suspect abuser', label: 'Suspect Abuser' },
];

export const statusOptions = [
  { id: 1, value: 'trial', label: 'Trial' },
  { id: 1, value: 'live', label: 'Live' },
];

export const INSPECTION_SCORE_LIST = [
  {
    value: 0,
    label: '0',
    description: 'Perlu ganti sebelum 1,000 km',
  },
  {
    value: 10,
    label: '10',
    description: 'Perlu ganti sebelum 1,000 km',
  },
  {
    value: 20,
    label: '20',
    description: 'Perlu ganti sebelum 1,000 km',
  },
  {
    value: 30,
    label: '30',
    description: 'Perlu ganti sebelum 10,000 km',
  },
  {
    value: 40,
    label: '40',
    description: 'Perlu ganti sebelum 10,000 km',
  },
  {
    value: 50,
    label: '50',
    description: 'Perlu servis atau perawatan',
  },
  {
    value: 60,
    label: '60',
    description: 'Perlu servis atau perawatan',
  },
  {
    value: 70,
    label: '70',
    description: 'Aman tapi butuh cek rutin',
  },
  {
    value: 80,
    label: '80',
    description: 'Aman tapi butuh cek rutin',
  },
  {
    value: 90,
    label: '90',
    description: 'Prima/baru',
  },
  {
    value: 100,
    label: '100',
    description: 'Prima/baru',
  },
];

export const CAR_TRANSMISSION_OPTIONS = [
  { label: 'Manual', value: 'mt' },
  { label: 'Automatic', value: 'at' },
];

export const BOOKING_CATEGORY_OPTIONS = [
  { label: 'Produk', value: 'product' },
  { label: 'Jasa', value: 'service' },
];

export const ProductTypeNameInit = [
  { label: 'Produk', value: 'part' },
  { label: 'Jasa', value: 'work' },
];
