import { CommonLabels } from '@utils/Constants';
import get from 'lodash/get';

export default class Helper {
  static formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 0 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();

      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    } catch (e) {
      console.log(e);
    }
  }

  static isEmptyField(e) {
    switch (e) {
      case '':
      case null:
      case false:
      case typeof e == 'undefined':
        return true;
      default:
        return false;
    }
  }

  static getAreaData(addressArray, typeArea) {
    let result = '';

    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && typeArea === addressArray[i].types[0]) {
        result = addressArray[i].long_name;
        return result;
      }
    }
  }

  static getCoordByAddress(addressArray) {
    let location = {};
    if (Array.isArray(addressArray?.results) && addressArray?.results.length > 0) {
      location = addressArray?.results[0]?.geometry?.location || {};
    }
    return location;
  }

  static shortenByDash(text) {
    if (text == null || typeof text === 'undefined') return '-';
    const splittedString = text.split(' - ');
    const firstElement = splittedString[0];
    return firstElement;
  }

  static getCommonLabel(text, defaultText) {
    if (text) {
      if (CommonLabels[text]) {
        return CommonLabels[text];
      }
    }
    return defaultText || text;
  }

  static formatRangePrice(range) {
    let rangePrice = '-';
    if (range) {
      const splittedString = range.split('-');
      splittedString.forEach((item, index) => {
        if (index < 2) {
          const price = 'Rp. ' + this.formatMoney(item);
          rangePrice = index == 0 ? price : rangePrice + ' - ' + price;
        }
      });
    }
    return rangePrice;
  }

  static formatMoneyOnField(amount, prefix) {
    let money = amount.replace(/[^\d]/g, '').toString();
    let isMoney = money == '' ? false : true;
    money = money == '' ? '0' : money;

    while (money.charAt(0) === '0' && money.length > 1) {
      money = money.slice(1);
    }
    let split = money.split(',');
    let sisa = split[0].length % 3;
    let rupiah = split[0].substr(0, sisa);
    let ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      let separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;

    let result = {
      formatMoney: parseInt(money),
      formatIsMoney: isMoney,
      formatMoneyString: prefix == undefined ? rupiah : rupiah ? prefix + rupiah : '',
    };

    return result;
  }

  static numberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  static convertArrayOfObjectsToCSV(data, headers, keys) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';

    result = '';
    result += headers.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += get(item, key);

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  static downloadCSV(data, headers, keys) {
    const link = document.createElement('a');
    let csv = this.convertArrayOfObjectsToCSV(data, headers, keys);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  static countUpRate(percentage, amount) {
    const percentToDecimal = percentage / 100;
    const upRatePrice = percentToDecimal * amount;

    return upRatePrice + amount;
  }

  static countDiscount(percentage, amount, quantity = 1) {
    const percentToDecimal = percentage / 100;
    const discountedPrice = percentToDecimal * amount;
    const discountXquantity = Math.trunc(discountedPrice) * quantity;

    return discountXquantity;
  }

  static generatePercentage(start = 0, limit = 0) {
    let array = [];
    for (let i = start; i <= limit; i++) {
      array.push({ value: i, label: `${i}%` });
    }

    return array;
  }

  static generateFinalMoney(price, currency = 'IDR') {
    const isNegativeValue = price < 0;
    const amount = Math.abs(price);
    const final = `${isNegativeValue ? '- ' : ''}${currency} ${this.formatMoney(amount)}`;

    return final;
  }

  static seperateEmail(email) {
    const splitedString = email.split('@');

    return splitedString[0];
  }

  static generateYearOptions(from = 2000, to, reversed) {
    const endDate = to || new Date().getFullYear();
    let years = [];

    for (var i = from; i <= endDate; i++) {
      years.push({ value: from, label: from });
      from++;
    }
    return reversed ? years.reverse() : years;
  }

  static decreaseSPKName(fileName) {
    return fileName.length > 25 ? fileName.substr(0, 11) + '...' + fileName.substr(fileName.length - 11) : fileName;
  }

  static base64StringToBlob(base64String, contentType) {
    // Convert the base64 string to binary data
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the binary data
    return new Blob([byteArray], { type: contentType });
  }

  static jsonToUrlParam(json) {
    const params = new URLSearchParams();
    for (const key in json) {
      if (json.hasOwnProperty(key) && json[key] !== '') {
        params.append(key, json[key]);
      }
    }
    return params.toString();
  }
}
