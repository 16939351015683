import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

const PageNotFound = () => {
  const history = useHistory();

  const handleGoToBlank = () => history.push('/');

  const containerStyle = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  };
  return (
    <Container style={containerStyle}>
      <span style={{ fontSize: 64, fontWeight: 'bold' }}>404</span>
      <span style={{ fontSize: 16, marginBottom: 16 }}>Halaman yang kamu cari tidak ada</span>
      <Button color="primary" onClick={handleGoToBlank}>
        Back
      </Button>
    </Container>
  );
};

export default PageNotFound;
