import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import DetailField from '@components/field/DetailField';
import moment from 'moment';
import BookingFleetBadges from '@components/badge/BookingFleetBadges';

function BookingDetailDetail(props) {
  const { detailData } = props;

  const generateStatusColor = (status) => {
    const statusColorCode = {
      waiting: 'text-warning',
      confirm: 'text-secondary',
      finish: 'text-success',
      cancel: 'text-danger',
      payment: 'text-warning',
      review: 'text-info',
      'on-process': 'text-secondary',
      'service-done': 'text-success',
      complain: 'text-danger',
    };
    return statusColorCode[status];
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Detail</CardHeader>
        <CardBody className="pb-0">
          <DetailField title="ID" value={detailData?.booking_code ?? ''} />
          <DetailField
            title="Status"
            value={<BookingFleetBadges status={detailData?.status} />}
            classValue={`${generateStatusColor(detailData?.status?.slug ?? '')} font-weight-bold`}
          />
          <DetailField
            title="Waktu"
            // value={`${detailData?.service_schedule ?? ''}, ${detailData?.booking_time ?? ''}`}
            value={
              moment(detailData?.service_schedule).isValid()
                ? moment(detailData?.service_schedule).format('MMM DD YYYY, HH.mm')
                : '-'
            }
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailDetail;
