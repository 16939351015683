import axios from 'axios';
import Cookies from 'universal-cookie';
import { store, persistor } from '@store/configureStore';
import { resetAuth, setRefreshToken } from '@reducers/Auth';
import config from '@utils/config';
import { authRefreshToken } from '@actions/Auth';
// import { useLocation } from 'react-router-dom';
// import { createBrowserHistory } from 'history';

const source = axios.CancelToken.source();
// const history = createBrowserHistory();

const getClient = (apiVersion = 'v1') => {
  const options = {
    baseURL: apiVersion === 'v2' || apiVersion === 'refresh' ? config.api_url_v2 : config.api_url,
  };

  const client = axios.create(options);
  // const { location } = useLocation();

  // console.log(window?.location?.pathname);

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.data?.error?.message === 'Unauthorized Access!') {
        window.location.href = '/404';
      }

      if (error?.response?.data?.error?.status_code == 401) {
        if (window?.location?.pathname.toString() !== '/reset-password') {
          // console.log(error?.response?.data?.error?.status_code == 401);
          // console.log('401');
          const cookies = new Cookies();
          const refreshToken = cookies.get('fleet_refresh_token');

          store
            .dispatch(authRefreshToken(refreshToken))
            .then((response) => {
              store.dispatch(setRefreshToken(response.data));
              window.location.reload();
            })
            .catch((error) => {
              console.log(error);
              const cookies = new Cookies();
              cookies.remove('fleet_jwt_token');
              cookies.remove('fleet_refresh_token');
              persistor.purge();
              store.dispatch(resetAuth());
            });
        }
      }

      if (error.response.data.detail === 'Invalid token.') {
        const cookies = new Cookies();
        cookies.remove('fleet_tok_c');
        cookies.remove('fleet_jwt_token');

        persistor.purge();
        store.dispatch(resetAuth());
      }

      return Promise.reject(error);
    }
  );

  client.interceptors.request.use(
    async (config) => {
      const cookies = new Cookies();

      if (apiVersion === 'v1') {
        const tokenKey = cookies.get('fleet_tok_c');

        if (tokenKey) {
          config.headers = {
            Authorization: `Token ${tokenKey}`,
          };
        }
      }

      if (apiVersion === 'v2') {
        const tokenKey = cookies.get('fleet_jwt_token');

        if (tokenKey) {
          config.headers = {
            Authorization: `Bearer ${tokenKey}`,
          };
        }
      }

      if (apiVersion === 'refresh') {
        const tokenKey = cookies.get('fleet_refresh_token');

        if (tokenKey) {
          config.headers = {
            Authorization: `Bearer ${tokenKey}`,
          };
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  return client;
};

export default class API {
  constructor(apiVersion = 'v1') {
    this.client = getClient(apiVersion);
  }

  get(path, options = {}) {
    return this.client.get(path, options);
  }

  post(path, data = {}, options = {}) {
    return this.client.post(path, data, options);
  }

  put(path, data = {}, options = {}) {
    return this.client.put(path, data, options);
  }

  delete(path, options = {}) {
    return this.client.delete(path, options);
  }

  cancelToken() {
    return source;
  }

  cancel() {
    source.cancel();
  }
}
