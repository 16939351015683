import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Button } from 'reactstrap';
import Helper from '@helpers/Helper';
import DetailField from '@components/field/DetailField';
import CreateFleetModal from '@components/fleet/CreateFleetModal';

function BookingDetailCar(props) {
  const history = useHistory();
  const { detailData, handleResultNotification, onSubmit, onLoading, allowEdit } = props;

  const [hasModalEditFleet, setHasModalEditFleet] = useState(false);

  const toogleEditFleet = () => setHasModalEditFleet(!hasModalEditFleet);

  const handleSubmitEditFleet = (status, error) => {
    const errorMsg = error ? 'Gagal - Data Mobil gagal diubah, ' + error : 'Gagal - Data Mobil gagal diubah';
    if (status) {
      onSubmit();
    }
    setHasModalEditFleet(false);
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil - Data mobil sukses diubah' : errorMsg
    );
  };

  const generatLabelStatus = (status) => {
    if (status) {
      return <span className="badge-custom badge-custom-order-done">Aktif</span>;
    } else if (!status) {
      return <span className="badge-custom badge-custom-grey">Disposal</span>;
    }
  };

  return (
    <React.Fragment>
      <CreateFleetModal
        modal={hasModalEditFleet}
        dataFleet={detailData ?? {}}
        onSubmit={handleSubmitEditFleet}
        toggle={toogleEditFleet}
        onLoading={onLoading}
        handleResultNotification={handleResultNotification}
      />
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Data Mobil</CardHeader>
        <CardBody className="pb-3">
          <Row>
            <Col>
              <img src={detailData?.image ?? ''} className="user-car" />
              <DetailField title="Mobil " value={detailData?.brand_type?.name ?? '-'} />
              <DetailField
                title="Plat Nomor"
                value={detailData?.license_plate?.length > 0 ? detailData?.license_plate : '-'}
              />
              <DetailField title="Transmisi" value={Helper.getCommonLabel(detailData?.transmission) || '-'} />
              <DetailField title="Tahun" value={detailData?.year || '-'} />
              <DetailField title="Bahan Bakar" value={Helper.getCommonLabel(detailData?.fuel) || '-'} />
              <DetailField
                title="Odometer"
                value={detailData?.mileage ? Helper.numberWithCommas(detailData?.mileage) : '-'}
              />
              <DetailField title="Tags" value={detailData?.tags ? detailData?.tags?.join(', ') : '-'} />
              <DetailField title="Status" value={generatLabelStatus(detailData?.is_active)} />
              {allowEdit && (
                <Button
                  className="button-action save btn-blue"
                  color="secondary"
                  onClick={() => setHasModalEditFleet(true)}
                >
                  Ubah Data
                </Button>
              )}
            </Col>
          </Row>
        </CardBody>
        {/* <CardFooter className="p-3">
          <Button color="purple" block onClick={goToCarHealthInspection}>
            <i className="mdi mdi-directions mr-2 mb-1"></i>
            <span>Inspeksi Mobil</span>
          </Button>
        </CardFooter> */}
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailCar;
