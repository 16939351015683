import React from 'react';
import { Col, Row } from 'reactstrap';

const WorkshopCard = (props) => {
  const { data, handleChangeWorkshop } = props;

  const handleClickCard = () => {
    if (handleChangeWorkshop) {
      handleChangeWorkshop(data);
    }
  };

  return (
    <Row className="workshop_card px-3 py-1 mb-2 pointer-cursor" onClick={handleClickCard}>
      <Col>
        <Row className="workshop_card__header">
          <span>{data?.name}</span>
          {data?.is_fulfilled_by_otoklix && <span className="tag">FBO</span>}
          {data?.pudo_tag && <span className="tag">PUDO</span>}
        </Row>
        <Row>
          <span>{data?.location?.street_address}</span>
        </Row>
        <Row>
          <span>
            Waktu Operasional:{' '}
            <span className={data?.operational_time?.is_closed ? 'text-danger' : ''}>
              {data?.operational_time?.text}
            </span>{' '}
            | Jarak: <span className="text-uppercase">{data?.location?.distance_text}</span>
          </span>
        </Row>
      </Col>
    </Row>
  );
};

export default WorkshopCard;
