import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Wrapper from '@components/wrapper/Wrapper';
import BookingFleetBadges from '@components/badge/BookingFleetBadges';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import { Col, Button, Row, Form } from 'reactstrap';
import map from 'lodash/map';
import TickBold from '@assets/icons/tick-bold.svg';
import TickBorder from '@assets/icons/tick-border.svg';
import CloseBold from '@assets/icons/close-bold.svg';
import CloseBorder from '@assets/icons/close-border.svg';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import { assign } from 'lodash';
import Popup from 'react-popup';
import Filter from '@assets/icons/filter.svg';

function Bookings() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);

  const keys = [
    'service_start_date',
    'service_end_date',
    'start_date',
    'end_date',
    'booking_code',
    'cars',
    'license_plate',
    'service_category',
    'tags',
    'created_by',
    'status',
    'page',
    'limit',
    'data',
  ];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });
  const moment = require('moment');
  require('moment/locale/id');

  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [statusOptions, setStatusOptions] = useState([]);
  const [originOptions, setOriginOptions] = useState([]);
  const [bookingCodeOptions, setBookingCodeOptions] = useState([]);
  const [carOptions, setcarOptions] = useState([]);
  const [licensePlateOptions, setLicensePlateOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tagsOptions, setTagOptions] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [statusApprovement, setStatusApprovement] = useState(false);
  const [selectedBookingCode, setSelectedBookingCode] = useState(false);
  const [loadOptions, setLoadOptions] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Booking / Order',
        active: false,
        url: '/bookings',
      },
      {
        id: 2,
        label: `Daftar Booking`,
        active: true,
        url: `/bookings`,
      },
    ];
  };

  const allowPermission = (menu) => {
    return !!(generatePermission('booking', menu) || generatePermission('booking', 'all'));
  };

  const allowPermissionFleet = (menu) => {
    return !!(generatePermission('garage', menu) || generatePermission('garage', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowCreate = allowPermission('all') || allowPermission('create');
  const allowShow = allowPermission('all') || allowPermission('show');
  const allowShowFleet = allowPermissionFleet('all') || allowPermissionFleet('show');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.service_details?.length > 3,
      style: {
        minHeight: '180px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 3,
      style: {
        minHeight: '150px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 2,
      style: {
        minHeight: '100px',
      },
    },
  ];

  const typeOptions = [
    { value: '', label: 'Semua' },
    { value: 'b2c', label: 'B2C' },
    { value: 'b2b', label: 'B2B' },
    { value: 'custom', label: 'Custom' },
  ];

  const queueTypeOptions = [
    { value: '', label: 'Semua Tipe Servis' },
    { value: 'priority', label: 'Prioritas' },
    { value: 'low-priority', label: 'Reguler' },
  ];

  const dataFilter = [
    {
      id: 'filter_date_service_date',
      type: 'date',
      title: '',
      name: 'service_date',
      name_field: 'service_date',
      date_range: true,
      value:
        tempFiltered?.service_start_date && tempFiltered?.service_end_date
          ? { start_date: tempFiltered?.service_start_date, end_date: tempFiltered?.service_end_date }
          : '',
      placeholder: 'Jadwal Servis',
    },
    {
      id: 'filter_date_date',
      type: 'date',
      title: '',
      name: 'date',
      name_field: 'date',
      date_range: true,
      value:
        tempFiltered?.start_date && tempFiltered?.end_date
          ? { start_date: tempFiltered?.start_date, end_date: tempFiltered?.end_date }
          : '',
      placeholder: 'Tanggal Dibuat',
    },
    {
      id: 'filter_select_booking_code',
      type: 'select',
      title: '',
      name: 'booking_code',
      name_field: 'booking_code',
      options: bookingCodeOptions,
      value: tempFiltered?.booking_code
        ? bookingCodeOptions.filter((type) => type?.value === tempFiltered?.booking_code)?.[0]
        : '',
      placeholder: 'Kode Booking',
    },
    {
      id: 'filter_select_cars',
      type: 'select',
      title: '',
      name: 'cars',
      name_field: 'cars',
      options: carOptions,
      value: tempFiltered?.cars ? carOptions.filter((type) => type?.value === tempFiltered?.cars)?.[0] : '',
      placeholder: 'Mobil',
    },
    {
      id: 'filter_select_license_plate',
      type: 'select',
      title: '',
      name: 'license_plate',
      name_field: 'license_plate',
      options: licensePlateOptions,
      value: tempFiltered?.license_plate
        ? licensePlateOptions.filter((type) => type?.value === tempFiltered?.license_plate)?.[0]
        : '',
      placeholder: 'Plat Nomor',
    },
    {
      id: 'filter_select_service_category',
      type: 'select',
      title: '',
      name: 'service_category',
      name_field: 'service_category',
      options: categoryOptions,
      value: tempFiltered?.service_category
        ? categoryOptions.filter((type) => type?.value === tempFiltered?.service_category)?.[0]
        : '',
      placeholder: 'Kategori Servis',
    },

    {
      id: 'filter_select_tags',
      type: 'select',
      title: '',
      name: 'tags',
      name_field: 'tags',
      options: tagsOptions,
      value: tempFiltered?.tags ? tagsOptions.filter((type) => type?.value === tempFiltered?.tags)?.[0] : '',
      placeholder: 'Tags',
    },

    {
      id: 'filter_select_created_by',
      type: 'select',
      title: '',
      name: 'created_by',
      name_field: 'created_by',
      options: createdByOptions,
      value: tempFiltered?.created_by
        ? createdByOptions.filter((type) => type?.value === tempFiltered?.created_by)?.[0]
        : '',
      placeholder: 'Dibuat Oleh',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Status',
        selector: (row) => <BookingFleetBadges status={row?.status} />,
        minWidth: '200px',
      },
      {
        name: 'Kode Booking',
        selector: (row) => row?.booking_code ?? '-',
        minWidth: '160px',
        sortable: true,
      },
      {
        name: 'Tanggal Dibuat',
        selector: (row) => row?.created_at ?? '-',
        cell: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        minWidth: '150px',
        sortable: true,
        left: true,
      },
      {
        name: 'Jadwal Servis',
        selector: (row) => row?.booking_schedule ?? '-',
        cell: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.booking_schedule ?? null, 'D MMMM YYYY, HH:mm').isValid()
                ? moment(row?.booking_schedule, 'D MMMM YYYY, HH:mm').format('DD MMM, YYYY')
                : '-'}
            </span>
            <span className="time">
              {moment(row?.booking_schedule ?? null, 'D MMMM YYYY, HH:mm').isValid()
                ? moment(row?.booking_schedule, 'D MMMM YYYY, HH:mm').format('HH:mm:ss')
                : '-'}
            </span>
          </div>
        ),
        minWidth: '150px',
        sortable: true,
        left: true,
      },
      {
        name: 'Mobil',
        id: 'name',
        selector: (row) => row?.car_brand ?? '-',
        minWidth: '180px',
        sortable: true,
        wrap: true,
      },
      {
        name: 'Plat Nomor',
        minWidth: '120px',
        selector: (row) => (row?.car_license_plate?.length > 0 ? row?.car_license_plate : '-'),
        sortable: true,
        wrap: true,
      },
      {
        name: 'Kategori Servis',
        selector: (row) => (row?.service_categories?.length > 0 ? generateCategory(row?.service_categories) : '-'),
        minWidth: '170px',
        sortable: true,
      },
      {
        name: 'Detail Servis',
        selector: (row) => (row?.service_details?.length > 0 ? generateCategory(row?.service_details) : '-'),
        minWidth: '250px',
        sortable: true,
      },
      {
        name: 'Biaya',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.final_price)}`,
        minWidth: '150px',
      },
      {
        name: 'Tags',
        selector: (row) => row?.tags?.join(', ') ?? '-',
        minWidth: '150px',
        wrap: 'false',
        sortable: true,
      },
      {
        name: 'Dibuat Oleh',
        selector: (row) => row?.created_by ?? '-',
        minWidth: '150px',
        wrap: 'false',
        sortable: true,
      },
      {
        name: 'Persetujuan',
        omit: true,
        id: 'action',
        selector: (row) => row?.booking_code,
        cell: (row) => (
          <>
            {row?.is_approved == true && (
              <>
                <img className="px-2" src={CloseBorder} />
                <img className="px-2 m-icon-approved" src={TickBold} />
              </>
            )}
            {row?.is_approved == false && (
              <>
                <img className="px-2 m-icon-list-rejected" src={CloseBold} />
                <img className="px-2" src={TickBorder} />
              </>
            )}
            {row?.is_approved == null && (
              <>
                <img
                  className={`px-2 icon-new-tab ${row?.status?.slug === 'review' ? 'disabled-icon' : ''}`}
                  src={CloseBorder}
                  onClick={() => {
                    handleChangeApprovement(false, row?.booking_code, row?.final_price, row?.status?.slug);
                  }}
                />
                <img
                  className={`px-2 icon-new-tab ${row?.status?.slug === 'review' ? 'disabled-icon' : ''}`}
                  src={TickBorder}
                  onClick={() => {
                    handleChangeApprovement(true, row?.booking_code, row?.final_price, row?.status?.slug);
                  }}
                />
              </>
            )}
          </>
        ),
        maxWidth: '115px',
        style: {
          position: '-webkit-sticky',
          position: 'sticky',
          right: 0,
          backgroundColor: '#fcfcfc',
          alignItem: 'left',
        },
      },
    ],
    []
  );

  const generateCategory = (data) => {
    const mapping = data.map((item, index) => {
      if (index < 3) {
        return <div className="mb-4">{item}</div>;
      } else if (index == 3) {
        return <div className="mb-4">{'+' + (data?.length - 3) + ' Lainnya'}</div>;
      }
    });
    return mapping;
  };

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  async function fetchBookings() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/fleet/booking/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan data Booking, ` + error);
    }
  }

  async function fetchBookingStatusOptions() {
    setLoading(true);
    setLoadingFilter(true);
    let listStatusOption = [{ value: '', label: 'Semua Booking Status' }];

    try {
      const response = await api.get('v2/fleet/booking/status/options/');
      if (response?.data?.data) {
        let resultStatusOptions = map(response?.data?.data, (item) => {
          return { value: item?.slug, label: item?.name };
        });
        resultStatusOptions.splice(0, 0, { value: '', label: 'Semua Booking Status' });
        listStatusOption = resultStatusOptions;
      }
      setStatusOptions(listStatusOption);
      fetchBookingCodeOptions();
    } catch (error) {
      setStatusOptions(listStatusOption);
      setLoadingFilter(false);
    }
  }

  async function fetchBookingCodeOptions() {
    let listOption = [{ value: '', label: 'Semua Booking Code' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=booking_code');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Booking Code' });
        listOption = resultOptions;
      }

      setBookingCodeOptions(listOption);
      fetchCarsOptions();
    } catch (error) {
      setBookingCodeOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchCarsOptions() {
    let listOption = [{ value: '', label: 'Semua Merek Mobil' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=cars');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Merek Mobil' });
        listOption = resultOptions;
      }

      setcarOptions(listOption);
      fetchLicensePlateOptions();
    } catch (error) {
      setcarOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchLicensePlateOptions() {
    let listOption = [{ value: '', label: 'Semua Plat Nomor' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=license_plate');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Plat Nomor' });
        listOption = resultOptions;
      }

      setLicensePlateOptions(listOption);
      fetchCategoryOptions();
    } catch (error) {
      setLicensePlateOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchCategoryOptions() {
    let listOption = [{ value: '', label: 'Semua Kategori Servis' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=service_categories');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Kategori Servis' });
        listOption = resultOptions;
      }

      setCategoryOptions(listOption);
      fetchTagsOptions();
    } catch (error) {
      setCategoryOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchTagsOptions() {
    let listOption = [{ value: '', label: 'Semua Tag' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=tags');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua Tag' });
        listOption = resultOptions;
      }

      setTagOptions(listOption);
      fetchCreatedByOptions();
    } catch (error) {
      setTagOptions(listOption);
      setLoadingFilter(false);
    }
  }

  async function fetchCreatedByOptions() {
    let listOption = [{ value: '', label: 'Semua User' }];

    try {
      const response = await api.get('v2/fleet/booking/filter_options/?options=created_by');
      if (response?.data?.data) {
        let resultOptions = response?.data?.data;
        resultOptions.splice(0, 0, { value: '', label: 'Semua User' });
        listOption = resultOptions;
      }

      setCreatedByOptions(listOption);
      setLoadingFilter(false);
    } catch (error) {
      setCreatedByOptions(listOption);
      setLoadingFilter(false);
    }
  }

  const handleChangeOptions = () => {
    if (filters?.length) {
      filters[2].options = bookingCodeOptions;
      if (tempFiltered?.booking_code)
        filters[2].value = bookingCodeOptions.filter((status) => status?.value === tempFiltered?.booking_code)?.[0];
      filters[3].options = carOptions;
      if (tempFiltered?.cars) filters[3].value = carOptions.filter((value) => value?.value == tempFiltered?.cars);
      filters[4].options = licensePlateOptions;
      if (tempFiltered?.license_plate)
        filters[4].value = licensePlateOptions.filter((status) => status?.value === tempFiltered?.license_plate)?.[0];
      filters[5].options = categoryOptions;
      if (tempFiltered?.service_category)
        filters[5].value = categoryOptions.filter((status) => status?.value === tempFiltered?.service_category)?.[0];
      filters[6].options = tagsOptions;
      if (tempFiltered?.tags)
        filters[6].value = tagsOptions.filter((status) => status?.value === tempFiltered?.tags)?.[0];
      filters[7].options = createdByOptions;
      if (tempFiltered?.created_by)
        filters[7].value = createdByOptions.filter(
          (status) =>
            status?.value ===
            (tempFiltered?.created_by == 'Otoklix' ? tempFiltered?.created_by : parseInt(tempFiltered?.created_by))
        )?.[0];
      setSelectedStatus(tempFiltered?.status);
      setFilters([...filters]);
    }
  };

  const getParamData = () => {
    let params = {
      booking_code: filtered?.booking_code ?? null,
      license_plate: filtered?.license_plate ?? null,
      cars: filtered?.cars ?? null,
      service_categories: filtered?.service_category ?? null,
      tags: filtered?.tags ?? null,
      created_by: filtered?.created_by ?? null,
      service_start_date: filtered?.service_start_date
        ? moment(filtered?.service_start_date).format('YYYY-MM-DD 00:00:00')
        : null,
      service_end_date: filtered?.service_end_date
        ? moment(filtered?.service_end_date).format('YYYY-MM-DD 23:59:59')
        : null,
      start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD 00:00:00') : null,
      end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD 23:59:59') : null,
      // status: filtered?.status ?? null,
      page: page,
      limit: pageLimit,
    };

    if (filtered?.status) {
      assign(params, {
        status: filtered?.status ?? null,
      });
    }

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const resetFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetFilter();
    setSelectedStatus('');
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchBookings();
  };

  const handleMethodfetchBookings = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchBookings();
    }
  };

  const resetBookings = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const handleCreateBooking = () => {
    history.push(`/bookings/create`);
  };

  const openBookingsDetail = (row) => {
    if (allowShow) {
      history.push(`/bookings/${row?.booking_code}`);
    }
  };

  const handleSetFiltered = (e) => {
    filtered.booking_code = e?.booking_code?.value ?? '';
    filtered.cars = e?.cars?.value ?? '';
    filtered.license_plate = e?.license_plate?.value ?? '';
    filtered.service_category = e?.service_category?.value ?? '';
    filtered.tags = e?.tags?.value ?? '';
    filtered.created_by = e?.created_by?.value ?? '';
    filtered.service_start_date = e?.service_date?.start_date
      ? moment(e?.service_date?.start_date).format('YYYY-MM-DD 00:00:00')
      : '';
    filtered.service_end_date = e?.service_date?.end_date
      ? moment(e?.service_date?.end_date).format('YYYY-MM-DD 23:59:59')
      : '';
    filtered.start_date = e?.date?.start_date ? moment(e?.date?.start_date).format('YYYY-MM-DD 00:00:00') : '';
    filtered.end_date = e?.date?.end_date ? moment(e?.date?.end_date).format('YYYY-MM-DD 23:59:59') : '';
    setFiltered({ ...filtered });
  };

  useEffect(() => {
    if (page && pageLimit) {
      fetchBookings();
    }
  }, [page, pageLimit]);

  // useEffect(() => {
  //   if (!firstLoad) {
  //     handleChangeSetSorting();
  //   }
  // }, [sortingData]);

  useEffect(() => {
    if (filtered && !loadingFilter) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD 00:00:00') : '',
        end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD 23:59:59') : '',
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/bookings?${urlParam}`);
      !loading && fetchBookings();
    }
    resetBookings();
  }, [filtered, loadingFilter]);

  useEffect(() => {
    handleChangeOptions();
  }, [createdByOptions]);

  useEffect(() => {
    fetchBookingStatusOptions();
    setFilters(dataFilter);
  }, []);

  useEffect(() => {
    if (tempFiltered?.start_date && tempFiltered?.end_date) {
      setFiltered({
        ...tempFiltered,
        date: {
          start_date: tempFiltered?.start_date,
          end_date: tempFiltered?.end_date,
        },
        start_date: tempFiltered?.start_date,
        end_date: tempFiltered?.end_date,
      });
    }
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  return (
    <>
      <Wrapper
        customContentClassName="px-5"
        breadcrumbData={generateBreadcrumb()}
        customBreadcrumbClassName={'pb-0 pt-3'}
        showNotification={hasNotif}
        notification={notification}
        onCloseNotification={handleCloseNotification}
      >
        <ConfirmationModal
          modal={confirmModal}
          modalWidth={'390'}
          uttonWidth={'50'}
          titleColor={statusApprovement ? 'green' : 'red'}
          toggle={() => setConfirmModal(!confirmModal)}
          header={`${statusApprovement ? 'Menyetujui' : 'Menolak'} Semua Permintaan Approval ?`}
          subHeader={`Apakah kamu yakin ingin ${
            statusApprovement ? 'menyetujui' : 'menolak'
          } semua permintaan approval untuk order ini?`}
          isDisabled={disableForm}
          onConfirm={() => approveEstimation(statusApprovement)}
          toggleClose={() => setConfirmModal(!confirmModal)}
        ></ConfirmationModal>
        <ConfirmationModal
          modal={alertModal}
          modalWidth={'400'}
          uttonWidth={'50'}
          titleColor={'red'}
          buttonClose={'Tutup'}
          withButtonConfirm={false}
          toggle={() => setAlertModal(!alertModal)}
          header={`Approval Gagal`}
          subHeader={`Untuk transaksi >10 juta rupiah hanya bisa diproses oleh Admin`}
          isDisabled={disableForm}
          toggleClose={() => setAlertModal(!alertModal)}
        ></ConfirmationModal>
        <Row className="mb-3 mt-0">
          <Col xs={12} md={3} lg={3} className={'overflow-title'}>
            <h3>Daftar Booking</h3>
          </Col>
          <Col xs={12} md={9} lg={9}>
            <div className="filter-sides">
              {false && (
                <ActionFilterCustom
                  filters={filters}
                  showButton={false}
                  loading={loading}
                  onApplyFilters={(e) => setFiltered(e)}
                ></ActionFilterCustom>
              )}
              <Button
                className={`${!showFilter ? 'btn-filter-show' : 'mt-2px h-35'} mr-1`}
                color="purple"
                outline={!showFilter}
                onClick={() => setShowFilter(loading ? null : !showFilter)}
                disabled={disableForm || loading}
              >
                {'Filter'}
                <img className="cursor-pointer mt-0 ml-2" src={Filter} height={'15px'} />
              </Button>
              {allowCreate && (
                <div className="btn-approve-resp">
                  <Button
                    className="button-action save btn-blue btn-create-responsive btn-wide h-35"
                    onClick={handleCreateBooking}
                  >
                    {'Ajukan Servis'}
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          {allowList && showFilter && (
            <ActionFilterCustom
              filters={filters}
              showButton={true}
              showSearch={false}
              loading={loading || loadingFilter}
              onApplyFilters={(e) => handleSetFiltered(e)}
              onResetFilters={handleResetFilters}
              displayCenter={true}
            ></ActionFilterCustom>
          )}
        </Row>
        <Row className="status-filter mt-3">
          {map(statusOptions, (item, index) => (
            <Button
              className={`${selectedStatus !== item.value ? 'btn-filter' : ''} ${
                item?.label?.length < 16 ? 'btn-filter-width' : ''
              } m-1`}
              color="purple"
              outline={selectedStatus !== item.value}
              onClick={() => {
                setFiltered({ ...filtered, status: item?.value });
                // handleSetFiltered({ status: { value: item?.value, label: item?.label } });
                setSelectedStatus(item?.value);
              }}
              disabled={disableForm || loading}
            >
              {item?.label}
            </Button>
            //   <Button
            //   className={`${selectedStatus == item.value ? item?.style + `-filtered` : `${item?.style}`} ${
            //     item?.label?.length < 17 ? 'w-120' : ''
            //   } m-0`}
            //   color="purple"
            //   outline={selectedStatus !== item.value}
            //   onClick={() => setSelectedStatus(item?.value)}
            //   disabled={disableForm}
            // >
            //   {item?.label}
            // </Button>
          ))}
        </Row>
        <Row className="p-0 my-4" style={{ backgroundColor: 'white' }}>
          <Col sm={12} md={12} className="p-0 mb-0">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading || loadingFilter}
              data={allowList ? data : []}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              conditionalRowStyles={conditionalRowStyles}
              onRowClicked={(row) => openBookingsDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="pb-2 m-0">
            {data?.length >= 1 && !loading && !loadingFilter && allowList && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </Row>
      </Wrapper>
    </>
  );
}

export default Bookings;
