import React, { useState, useMemo, useEffect, Component } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import userPermission from '@utils/userPermission';
import { Card, CardBody, CardText, CardTitle, Row, Col, Button, Form, Label } from 'reactstrap';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import Loading from '@components/loading/Loading';
import BookingFleetBadges from '@components/badge/BookingFleetBadges';
import moment from 'moment';
import SelectField from '@components/field/SelectField';
import Helper from '@helpers/Helper';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import TickBold from '@assets/icons/tick-bold.svg';
import TickBorder from '@assets/icons/tick-border.svg';
import CloseBold from '@assets/icons/close-bold.svg';
import CloseBorder from '@assets/icons/close-border.svg';
import API from '@utils/API';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import { assign } from 'lodash';
import DataTableByStatus from '@components/dashboard/DataTablebyStatus';
import Popup from 'react-popup';

function Dashboard() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);

  const keys = ['start_date', 'end_date'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [data, setData] = useState([]);
  const [dataDashboard, setDataDashboard] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [filter, setFilter] = useState({ value: 'daily', label: 'Harian' });
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [statusApprovement, setStatusApprovement] = useState(false);
  const [selectedBookingCode, setSelectedBookingCode] = useState(false);

  const [control, setControl] = useState({});
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});

  const allowPermission = (menu) => {
    return !!(generatePermission('booking', menu) || generatePermission('booking', 'all'));
  };

  const allowPermissionFleet = (menu) => {
    return !!(generatePermission('garage', menu) || generatePermission('garage', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowCreate = allowPermission('all') || allowPermission('create');
  const allowShow = allowPermission('all') || allowPermission('show');
  const allowShowFleet = allowPermissionFleet('all') || allowPermissionFleet('show');

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Dashboard',
        active: false,
        url: '/dashboard',
      },
    ];
  };

  const handleBtn = () => {
    Popup.alert(
      <>
        Mohon maaf, saat ini pengajuan servis melalui Aplikasi sedang mengalami kendala.
        {<br></br>}
        Anda dapat langsung mengajukan servis kepada tim Otoklix.
        {<br></br>}
        {<br></br>}
        Terima kasih.
      </>
    );
  };

  const handleCreateBooking = () => {
    history.push(`/bookings/create`);
  };

  const handleListBooking = (status) => {
    history.push(`/bookings?status=${status}`);
  };

  const openBookingsDetail = (row) => {
    history.push(`/bookings/${row?.booking_code}`);
  };

  const openBookingDetail = (id) => {
    if (allowShow) {
      history.push(`/bookings/${id}`);
    }
  };

  const openCarDetail = (id) => {
    if (allowShowFleet) {
      history.push(`/fleets/${id}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: 'Status',
        selector: (row) => <BookingFleetBadges status={row?.status} />,
        minWidth: '200px',
      },
      {
        name: 'Create At',
        selector: (row) => row?.created_at ?? '-',
        cell: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        minWidth: '120px',
        sortable: true,
        left: true,
      },
      {
        name: 'Booking Code',
        selector: (row) => row?.id ?? '-',
        cell: (row) => (
          <span
            onClick={() => openBookingDetail(row?.booking_code)}
            className={allowShow ? 'cursor-pointer hover-blue' : ''}
          >
            {row?.booking_code}
          </span>
        ),
        minWidth: '160px',
        sortable: true,
      },
      {
        name: 'Merek Mobil',
        id: 'name',
        selector: (row) => row?.car_brand ?? '-',
        cell: (row) => (
          <span
            onClick={() => openCarDetail(row?.user_car_id)}
            className={allowShowFleet ? 'cursor-pointer hover-blue' : ''}
          >
            {row?.car_brand}
          </span>
        ),
        minWidth: '180px',
        sortable: true,
      },
      {
        name: 'Plat Nomor',
        minWidth: '120px',
        selector: (row) => row?.car_license_plate ?? '-',
        sortable: true,
        wrap: true,
      },
      {
        name: 'Region',
        selector: (row) => row?.city?.name ?? '-',
        minWidth: '150px',
        wrap: 'false',
        sortable: true,
      },
      {
        name: 'Kategori Servis',
        selector: (row) => (row?.service_categories?.length > 0 ? generateCategory(row?.service_categories) : '-'),
        minWidth: '170px',
        sortable: true,
      },
      {
        name: 'Detail Servis',
        selector: (row) => (row?.service_details?.length > 0 ? generateCategory(row?.service_details) : '-'),
        minWidth: '250px',
        sortable: true,
      },
      {
        name: 'Harga',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.final_price)}`,
        minWidth: '120px',
      },
      {
        name: 'Persetujuan',
        id: 'action',
        selector: (row) => row?.booking_code,
        cell: (row) => (
          <>
            {row?.is_approved == true && (
              <>
                <img className="px-2" src={CloseBorder} />
                <img className="px-2 m-icon-approved" src={TickBold} />
              </>
            )}
            {row?.is_approved == false && (
              <>
                <img className="px-2 m-icon-list-rejected" src={CloseBold} />
                <img className="px-2" src={TickBorder} />
              </>
            )}
            {row?.is_approved == null && (
              <>
                <img
                  className={`px-2 icon-new-tab ${row?.status?.slug === 'review' ? 'disabled-icon' : ''}`}
                  src={CloseBorder}
                  onClick={() => {
                    handleChangeApprovement(false, row?.booking_code, row?.final_price, row?.status?.slug);
                  }}
                />
                <img
                  className={`px-2 icon-new-tab ${row?.status?.slug === 'review' ? 'disabled-icon' : ''}`}
                  src={TickBorder}
                  onClick={() => {
                    handleChangeApprovement(true, row?.booking_code, row?.final_price, row?.status?.slug);
                  }}
                />
              </>
            )}
          </>
        ),
        maxWidth: '115px',
        style: {
          position: '-webkit-sticky',
          position: 'sticky',
          right: 0,
          backgroundColor: '#fcfcfc',
          alignItem: 'left',
        },
      },
    ],
    []
  );

  const generateCategory = (data) => {
    const mapping = data.map((item, index) => {
      if (index < 3) {
        return <div className="mb-4">{item}</div>;
      } else if (index == 3) {
        return <div className="mb-4">{'+' + (data?.length - 3) + ' Lainnya'}</div>;
      }
    });
    return mapping;
  };

  const generateArray = (data) => {
    const mapping = data.map((item, index) => {
      if (index < 3) {
        return <div className="mb-4">{item}</div>;
      } else if (index == 3) {
        return <div className="mb-4">{'+' + (data?.length - 3) + ' Lainnya'}</div>;
      }
    });
    return mapping;
  };

  const handleChangeApprovement = (value, id, price, status) => {
    if (status == 'waiting') {
      if (price >= 10000000) {
        setAlertModal(true);
        setSelectedBookingCode(id);
      } else {
        setConfirmModal(true);
        setStatusApprovement(value);
        setSelectedBookingCode(id);
      }
    }
  };

  const handleChangeFilter = (e) => {
    setFilter(e);
  };

  const typeOptions = [
    { value: '', label: 'Semua' },
    { value: 'daily', label: 'Harian' },
    { value: 'weekly', label: 'Mingguan' },
    { value: 'monthly', label: 'Bulanan' },
    { value: 'yearly', label: 'Tahunan' },
    { value: 'custom', label: 'Custom Tanggal' },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '80px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        '&:last-of-type': {
          justifyContent: 'center',
          position: 'sticky',
          right: '0',
          backgroundColor: '#F3F3F3',
        },
      },
    },
    cells: {
      style: {
        paddingTop: '25px',
        alignItems: 'start',
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        '&:last-of-type': {
          position: 'sticky',
          right: '0',
        },
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.service_details?.length > 3,
      style: {
        minHeight: '180px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 3,
      style: {
        minHeight: '150px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 2,
      style: {
        minHeight: '100px',
      },
    },
  ];

  let dataFilter = [
    {
      id: 'filter_date_date',
      type: 'date',
      title: '',
      name: 'date',
      name_field: 'date',
      date_range: true,
      value:
        tempFiltered?.start_date && tempFiltered?.end_date
          ? { start_date: tempFiltered?.start_date, end_date: tempFiltered?.end_date }
          : '',
      placeholder: 'Tanggal Dibuat',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  async function approveEstimation(status) {
    setLoading(true);
    setDisableForm(true);

    const payload = {
      update_event: 'approval-booking',
      is_approve: status ?? null,
    };
    try {
      const response = await api.put(`v2/fleet/booking/${selectedBookingCode}`, payload);
      if (response?.data) {
        setLoading(false);
        setTimeout(() => {
          handleResultNotification(
            true,
            'success',
            `Berhasil - Semua Permintaan Approval Booking '${selectedBookingCode}' ${status ? 'disetujui' : 'ditolak'}`
          );
          fetchBookings();
        }, 300);
      }
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        setDisableForm(false);
        handleResultNotification(
          true,
          'failed',
          `Gagal - Semua Permintaan Approval Booking '${selectedBookingCode}' gagal ${
            status ? 'disetujui' : 'ditolak'
          }, ` + error
        );
      }, 300);
    }

    setDisableForm(false);
    setConfirmModal(false);
  }

  const getParamData = () => {
    let params = {
      start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD') : null,
      end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD') : null,
    };

    return params;
  };

  async function fetchDashboard() {
    setLoading(true);

    const params = getParamData();

    try {
      const response = await api.get(`v2/fleet/dashboard/?`, { params });
      setDataDashboard(response?.data?.data ?? {});
      setLoading(false);
      setFirstLoad(false);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setDataDashboard({});
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan Data Overview, ` + error);
    }
  }

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      start_date: e?.date?.start_date ? moment(e?.date?.start_date).format('YYYY-MM-DD 00:00:00') : '',
      end_date: e?.date?.end_date ? moment(e?.date?.end_date).format('YYYY-MM-DD 23:59:59') : '',
    };

    setFiltered(tempVal);
  };

  const resetFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetFilter();
    setSelectedStatus('');
  };

  const resetBookings = () => {
    // setResetSorting(true);
    setSortingData({});
  };

  // useEffect(() => {
  //   if (firstLoad && filtered) {
  //     fetchDashboard();
  //   } else {
  //     resetBookings();
  //   }
  // }, [filtered]);

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD') : '',
        end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD') : '',
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/dashboard?${urlParam}`);
      fetchDashboard();
    }
    resetBookings();
  }, [filtered]);

  const setDefaultFilters = async () => {
    let dateRange = {
      start_date: moment().subtract(30, 'days'),
      end_date: moment(),
    };

    dataFilter[0].value = dateRange;
    tempFiltered = dateRange;
    setFilters([...dataFilter]);

    setFiltered(dateRange);
  };

  useEffect(() => {
    setDefaultFilters();
  }, []);

  useEffect(() => {
    if (tempFiltered?.start_date && tempFiltered?.end_date) {
      setFiltered({
        ...tempFiltered,
        date: {
          start_date: tempFiltered?.start_date,
          end_date: tempFiltered?.end_date,
        },
        start_date: tempFiltered?.start_date,
        end_date: tempFiltered?.end_date,
      });
    }
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <>
        <Wrapper
          customContentClassName="px-5"
          breadcrumbData={generateBreadcrumb()}
          customBreadcrumbClassName={'pb-0 pt-3'}
          showNotification={hasNotif}
          notification={notification}
        >
          <ConfirmationModal
            modal={confirmModal}
            modalWidth={'390'}
            buttonWidth={'50'}
            titleColor={statusApprovement ? 'green' : 'red'}
            toggle={() => setConfirmModal(!confirmModal)}
            header={`${statusApprovement ? 'Menyetujui' : 'Menolak'} Semua Permintaan Approval ?`}
            subHeader={`Apakah kamu yakin ingin ${
              statusApprovement ? 'menyetujui' : 'menolak'
            } semua permintaan approval untuk order ini?`}
            isDisabled={disableForm}
            onConfirm={() => approveEstimation(statusApprovement)}
            toggleClose={() => setConfirmModal(!confirmModal)}
          ></ConfirmationModal>
          <ConfirmationModal
            modal={alertModal}
            modalWidth={'400'}
            uttonWidth={'50'}
            titleColor={'red'}
            buttonClose={'Tutup'}
            withButtonConfirm={false}
            toggle={() => setAlertModal(!alertModal)}
            header={`Approval Gagal`}
            subHeader={`Untuk transaksi >10 juta rupiah hanya bisa diproses oleh Admin`}
            isDisabled={disableForm}
            toggleClose={() => setAlertModal(!alertModal)}
          ></ConfirmationModal>
          <Row className="mb-3 mt-0 d-flex" xs={2}>
            <Col xs={12} md={4}>
              <h3>Data Overview</h3>
            </Col>
            <Col xs={12} md={8} className="">
              {/* <SelectField
              colWidth={3}
              className="pr-2"
              title="filter"
              // disabled={disableForm}
              closeMenuOnSelect={true}
              options={filterOptions}
              value={filter}
              onChange={(e) => handleChangeFilter(e)}
            /> */}
              <div className="filter-sides">
                {allowList && (
                  <ActionFilterCustom
                    filters={filters}
                    showButton={false}
                    showSearch={false}
                    loading={loading}
                    onApplyFilters={(e) => handleSetFiltered(e)}
                    onResetFilters={handleResetFilters}
                  ></ActionFilterCustom>
                )}
                {allowCreate && (
                  <div className="btn-approve-resp">
                    <Button className="button-action save btn-blue btn-create-responsive" onClick={handleCreateBooking}>
                      {'Ajukan Servis'}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="dashboard-card">
            <Col md={6}>
              <Card className="bg-dark-blue">
                <CardBody>
                  <CardTitle>Mobil Terdaftar</CardTitle>
                  <CardText tag="h3" className="text-center mb-3 text-white">
                    {dataDashboard?.fleet_total ? Helper.numberWithCommas(dataDashboard?.fleet_total) : 0}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="bg-dark-orange">
                <CardBody>
                  <CardTitle>Total Transaksi</CardTitle>
                  <CardText tag="h3" className="text-center mb-3 text-white">
                    <span>{'Rp '}</span>
                    {dataDashboard?.total_spent ? Helper.numberWithCommas(dataDashboard?.total_spent) : 0}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Row className="dashboard-card">
                <Col md={12}>
                  <h5>Permintaan Servis</h5>
                </Col>
                <Col md={4} sm={4}>
                  <Card className="bg-white h-90">
                    <CardBody>
                      <CardTitle style={{ height: '30px' }}>Menunggu Estimasi</CardTitle>
                      <CardText tag="h3" className="text-center text-grey mb-3">
                        {dataDashboard?.booking_by_status?.length > 0
                          ? Helper.numberWithCommas(dataDashboard?.booking_by_status[0]?.total ?? 0)
                          : 0}
                      </CardText>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-round grey text-bold"
                          color="black"
                          onClick={() => handleListBooking('review')}
                        >
                          View All
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4} sm={4}>
                  <Card className="bg-grey  h-90">
                    <CardBody>
                      <CardTitle style={{ height: '30px' }}>Butuh Persetujuan</CardTitle>
                      <CardText tag="h3" className="text-center mb-3 text-white">
                        {dataDashboard?.booking_by_status?.length > 0
                          ? Helper.numberWithCommas(dataDashboard?.booking_by_status[1]?.total ?? 0)
                          : 0}
                      </CardText>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-round white text-bold"
                          color="black"
                          onClick={() => handleListBooking('waiting')}
                        >
                          View All
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4} sm={4}>
                  <Card className="bg-black h-90">
                    <CardBody>
                      <CardTitle style={{ height: '30px' }}>Dalam Revisi</CardTitle>
                      <CardText tag="h3" className="text-center mb-3 text-white">
                        {dataDashboard?.booking_by_status?.length > 0
                          ? Helper.numberWithCommas(dataDashboard?.booking_by_status[2]?.total ?? 0)
                          : 0}
                      </CardText>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-round white text-bold"
                          color="black"
                          onClick={() => handleListBooking('complain')}
                        >
                          View All
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <div className="vertical-line"></div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="dashboard-card">
                <Col md={12}>
                  <h5>Mobil</h5>
                </Col>
                <Col md={4} sm={4}>
                  <Card className="bg-red h-90">
                    <CardBody>
                      <CardTitle style={{ height: '30px' }}>Menunggu Kedatangan</CardTitle>
                      <CardText tag="h3" className="text-center mb-3 text-white">
                        {dataDashboard?.booking_by_status?.length > 0
                          ? Helper.numberWithCommas(dataDashboard?.booking_by_fleet[0]?.total ?? 0)
                          : 0}
                      </CardText>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-round white text-bold"
                          color="black"
                          onClick={() => handleListBooking('confirm')}
                        >
                          View All
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4} sm={4}>
                  <Card className="bg-yellow h-90">
                    <CardBody>
                      <CardTitle style={{ height: '30px' }}>Servis Berjalan</CardTitle>
                      <CardText tag="h3" className="text-center mb-3 text-white">
                        {dataDashboard?.booking_by_status?.length > 0
                          ? Helper.numberWithCommas(dataDashboard?.booking_by_fleet[1]?.total ?? 0)
                          : 0}
                      </CardText>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-round white text-bold"
                          color="black"
                          onClick={() => handleListBooking('on-process')}
                        >
                          View All
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4} sm={4}>
                  <Card className="bg-green h-90">
                    <CardBody>
                      <CardTitle style={{ height: '30px' }}>Servis Selesai</CardTitle>
                      <CardText tag="h3" className="text-center mb-3 text-white">
                        {dataDashboard?.booking_by_status?.length > 0
                          ? Helper.numberWithCommas(dataDashboard?.booking_by_fleet[2]?.total ?? 0)
                          : 0}
                      </CardText>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-round white text-bold"
                          color="black"
                          onClick={() => handleListBooking('service-done')}
                        >
                          View All
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            {dataDashboard?.booking_by_status && (
              <>
                <Col md={6}>
                  <Row className="dashboard-card">
                    <Col md={12}>
                      <h5>Permintaan Servis</h5>
                    </Col>
                    {dataDashboard?.booking_by_status?.length > 0 ? (
                      dataDashboard?.booking_by_status.map((item, idx) => (
                        <Col md={4} sm={4} key={`card-${idx}`}>
                          <Card className="bg-white h-90">
                            <CardBody>
                              <CardTitle>{item?.name}</CardTitle>
                              <CardText tag="h3" className="text-center mb-3">
                                {Helper.numberWithCommas(item?.total ?? 0)}
                              </CardText>
                              <div className="d-flex justify-content-end">
                                <Button
                                  className="btn-round grey text-bold"
                                  color="black"
                                  onClick={() => handleListBooking(item?.slug)}
                                >
                                  View All
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))
                    ) : (
                      <Col md={4} sm={4}></Col>
                    )}
                    <div className="vertical-line mb-2"></div>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="dashboard-card">
                    <Col md={12}>
                      <h5>Mobil</h5>
                    </Col>
                    {dataDashboard?.booking_by_fleet?.length > 0 ? (
                      dataDashboard?.booking_by_fleet.map(
                        (item, idx) =>
                          item?.slug !== 'finish' && (
                            <Col md={4} sm={4} key={`card-${idx}`}>
                              <Card className="bg-white h-90">
                                <CardBody>
                                  <CardTitle className="font-11">{item?.name}</CardTitle>
                                  <CardText tag="h3" className="text-center mb-3">
                                    {Helper.numberWithCommas(item?.total ?? 0)}
                                  </CardText>
                                  <div className="d-flex justify-content-end">
                                    <Button
                                      className="btn-round grey text-bold"
                                      color="black"
                                      onClick={() => handleListBooking(item?.slug)}
                                    >
                                      View All
                                    </Button>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                      )
                    ) : (
                      <Col md={4} sm={4}></Col>
                    )}
                  </Row>
                </Col>
              </>
            )}
          </Row> */}
          <DataTableByStatus
            status="review"
            title="Menunggu Estimasi"
            filtered={filtered}
            allowList={allowList}
            allowShow={allowShow}
            allowShowFleet={allowShowFleet}
            handleResultNotification={handleResultNotification}
          ></DataTableByStatus>
          <DataTableByStatus
            status="waiting"
            title="Butuh Persetujuan"
            filtered={filtered}
            allowList={allowList}
            allowShow={allowShow}
            allowShowFleet={allowShowFleet}
            handleResultNotification={handleResultNotification}
          ></DataTableByStatus>
          <DataTableByStatus
            status="complain"
            title="Dalam Revisi"
            filtered={filtered}
            allowList={allowList}
            allowShow={allowShow}
            allowShowFleet={allowShowFleet}
            handleResultNotification={handleResultNotification}
          ></DataTableByStatus>
          <DataTableByStatus
            status="confirm"
            title="Menunggu Kedatangan"
            filtered={filtered}
            allowList={allowList}
            allowShow={allowShow}
            allowShowFleet={allowShowFleet}
            handleResultNotification={handleResultNotification}
          ></DataTableByStatus>
          <DataTableByStatus
            status="on-process"
            title="Servis Berjalan"
            filtered={filtered}
            allowList={allowList}
            allowShow={allowShow}
            allowShowFleet={allowShowFleet}
            handleResultNotification={handleResultNotification}
          ></DataTableByStatus>
          <DataTableByStatus
            status="service-done"
            title="Servis Selesai"
            filtered={filtered}
            allowList={allowList}
            allowShow={allowShow}
            allowShowFleet={allowShowFleet}
            handleResultNotification={handleResultNotification}
          ></DataTableByStatus>
        </Wrapper>
      </>
    );
  };

  return renderContent();
}

export default Dashboard;
