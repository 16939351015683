import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader, Input } from 'reactstrap';
import { Badge } from 'reactstrap';
import moment from 'moment';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import UploadIcon from '@assets/icons/upload-file.svg';
import PreviewDownload from '@components/modal/PreviewDownload';
import Helper from '@helpers/Helper';

const dataDummy = [
  {
    time: '2023-02-09T19:44:44.969691Z',
    file_name: 'Foto Report kedatangan',
    document: 'doc',
    isRequired: true,
  },
  {
    time: '2023-02-09T19:44:44.969691Z',
    file_name: 'Foto Report Sparepart',
    document: 'doc',
    isRequired: false,
  },
  {
    time: '2023-02-09T19:44:44.969691Z',
    file_name: 'Hasil Inpskesi ',
    document: 'doc',
    isRequired: false,
  },
  {
    time: '2023-02-09T19:44:44.969691Z',
    file_name: 'KM Mobil Sebelum Servis',
    document: 'doc',
    isRequired: true,
  },
  {
    time: '2023-02-09T19:44:44.969691Z',
    file_name: 'KM Mobil Sesudah Servis',
    document: 'doc',
    isRequired: true,
  },
  {
    time: '2023-02-09T19:44:44.969691Z',
    file_name: 'Nomor Seri Ban Baru',
    document: 'doc',
    isRequired: false,
  },
  {
    time: '2023-02-09T19:44:44.969691Z',
    file_name: 'Foto Serah Terima Mobil',
    document: 'doc',
    isRequired: true,
  },
];

const previewPdf = (base64) => {
  const contentType = 'application/pdf';
  var blob = Helper.base64StringToBlob(base64, contentType);
  const dataUrl = URL.createObjectURL(blob);

  const iframe = document.createElement('iframe');
  iframe.style.position = 'fixed';
  iframe.style.right = '0';
  iframe.style.bottom = '0';
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.border = '0';
  iframe.src = dataUrl;
  document.body.appendChild(iframe);

  iframe.contentWindow.print();
};

const BookingDetailFileOtoklix = (props) => {
  const { detailData } = props;
  const { generatePermission } = userPermission();
  const columns = useMemo(
    () => [
      {
        name: 'Time',
        selector: (row) =>
          moment(row?.created_at).isValid() ? moment(row?.created_at).format('MMM DD YYYY , HH:mm:ss') : '-',
        wrap: true,
        minWidth: '180px',
      },
      {
        name: 'File Name',
        selector: (row) => row?.label ?? '-',
        wrap: true,
        minWidth: '200px',
        cell: (row) => <div className={`text-bold text-purple-2`}>{row?.label ?? '-'}</div>,
      },
      {
        name: 'File',
        selector: (row) => row?.filename,
        cell: (row) =>
          row?.filename?.split('.')[row?.filename?.split('.')?.length - 1] == 'pdf' ? (
            <div className="text-bold text-purple-2" onClick={() => previewPdf(row?.base64)}>
              {row?.filename}
            </div>
          ) : (
            <div className="mb-2">
              <PreviewDownload previewURL={row?.url} previewName={row?.filename} />
            </div>
          ),
        wrap: true,
        minWidth: '350px',
      },
    ],
    []
  );

  const downloadFile = (url) => {
    if (url) {
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', '');
      link.click();
    }
  };

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Lampiran Foto</CardHeader>
      <CardBody className="pb-0">
        <DataTable
          persistTableHead
          highlightOnHover
          data={detailData}
          noHeader={true}
          fixedHeader
          fixedHeaderScrollHeight="400px"
          columns={generatePermission('booking', 'show') ? columns : []}
          className="table table-bordered dt-responsive"
        />
      </CardBody>
    </Card>
  );
};

export default BookingDetailFileOtoklix;
