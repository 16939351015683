import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const CustomModal = (props) => {
  const { modal, toggle, modalWidth, backdrop, children, modalHeader, modalFooter } = props;

  return (
    <Modal
      className={
        modalWidth ? `modal-dialog-centered modal-custom w-${modalWidth}` : `modal-dialog-centered modal-custom w-400`
      }
      isOpen={modal}
      toggle={toggle}
      backdrop={backdrop}
    >
      {modalHeader ? <ModalHeader toggle={toggle}>{modalHeader}</ModalHeader> : ''}
      <ModalBody className="body">{children}</ModalBody>
      {modalFooter ? <ModalFooter>{modalFooter}</ModalFooter> : ''}
    </Modal>
  );
};

export default CustomModal;
