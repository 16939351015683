import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, FormGroup, Button, Form, Spinner, Input, FormFeedback, Alert } from 'reactstrap';
import { validate } from 'react-email-validator';
import Logo from '@assets/images/logo.svg';
import API from '@utils/API';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

function ForgotPassword() {
  const api = new API('v2');
  const history = useHistory();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [email, setEmail] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [isEmailFormatInvalid, setIsEmailFormatInvalid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    let emailCheck = validate(email);
    if (emailCheck) {
      setEmailErrorMessage();
      setIsEmailFormatInvalid(false);
      setIsEmailInvalid(false);
    } else if (!email) {
      setEmailErrorMessage('Email cannot be empty');
      setIsEmailFormatInvalid(false);
      setIsEmailInvalid(true);
    } else if (!emailCheck) {
      setEmailErrorMessage('Email is invalid format');
      setIsEmailFormatInvalid(true);
      setIsEmailInvalid(true);
    }

    setEmail(email);
  };

  const validation = () => {
    let status = true;

    if (isEmailFormatInvalid) {
      setEmailErrorMessage('Email is invalid format');
      setIsEmailInvalid(true);
      status = false;
    }
    if (!email) {
      setEmailErrorMessage('Email cannot be empty');
      setIsEmailInvalid(true);
      status = false;
    }

    return status;
  };

  const handleRecover = (e) => {
    e.preventDefault();

    setIsSucceed(false);

    if (!validation()) {
      return;
    }

    setIsDisabled(true);

    const params = {
      email: email,
    };

    if (validateCaptcha(captcha) === true) {
      api
        .post(`v2/fleet/auth/recovery/`, params)
        .then((res) => {
          setAlertMessage(res?.data?.data);
          setIsSucceed(true);
          setIsDisabled(false);
          setHasError(false);
          setEmail('');
          setCaptcha('');
        })
        .catch((e) => {
          setIsSucceed(false);
          setHasError(true);
          setIsDisabled(false);
          setErrorMessage(e?.response?.data?.error?.message ?? '');
        });
      loadCaptchaEnginge(5);
      setCaptcha('');
    } else {
      setHasError(true);
      setErrorMessage('Captcha does not match');
      setIsDisabled(false);
      setCaptcha('');
    }
  };

  const handleToLogin = () => {
    history.push(`/login`);
  };

  useEffect(() => {
    loadCaptchaEnginge(5);
  }, []);

  return (
    <Container fluid={true}>
      <Row className="vh-100">
        <Col md={6} className="p-0 h-100vh d-flex justify-content-center change-pwd-bg">
          <div className="accountbg d-flex align-items-end p-5">
            <div className="account-title text-left p-2">
              <h2 className="font-40 text-white">
                Fleet Repairs <div className="text-white">Management System</div>
              </h2>
              <div className="text-white">
                Track and manage fleet transparently from the price, SKU, service details, service status, service
                history and the inspection result of their each vehicle.
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} className="justify-content-center">
          <div className="auth-page px-5 py-3">
            <Card body className="border-0 mb-0 h-100">
              <div className="mb-5 align-items-left">
                <a href="/" className="logo logo-admin">
                  <span>
                    <img src={Logo} height={40} alt="logo" className="my-3" />
                  </span>
                </a>
              </div>
              <div className="align-items-end">
                <div className="mb-0 mt-5 text-black cursor-pointer" onClick={handleToLogin}>
                  <i className="mdi mdi-arrow-left align-self-center vertical-menu-icon pr-2"></i>
                  <span>Back</span>
                </div>

                {isSucceed && (
                  <Alert color="warning" className="mt-5">
                    {alertMessage}
                  </Alert>
                )}

                <Form className="form-horizontal auth-form my-5">
                  <FormGroup>
                    <Input
                      className="input-login"
                      value={email}
                      placeholder="Email"
                      invalid={isEmailInvalid}
                      onChange={handleEmailChange}
                    />
                    <FormFeedback>{emailErrorMessage}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Input
                      className="input-login mb-5"
                      value={captcha}
                      placeholder="Enter Code"
                      onChange={(e) => {
                        setCaptcha(e.target.value);
                        setErrorMessage();
                        setHasError(false);
                      }}
                    ></Input>
                    <LoadCanvasTemplateNoReload />
                  </FormGroup>

                  {hasError && <div className="alert alert-danger">{errorMessage}</div>}
                  <FormGroup>
                    <Button
                      block
                      outline
                      size="lg"
                      color="black"
                      className="btn-login"
                      disabled={isDisabled}
                      onClick={handleRecover}
                    >
                      {isDisabled && (
                        <>
                          <Spinner color="light" size="sm">
                            Loading...
                          </Spinner>{' '}
                          &nbsp;
                        </>
                      )}
                      Recover
                    </Button>
                  </FormGroup>
                </Form>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
