import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import map from 'lodash/map';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import BookingDetailCar from '@components/booking/BookingDetailCar';
import BookingDetailLog from '@components/booking/BookingDetailLog';
import BookingDetailDetails from '@components/booking/BookingDetailDetails';
import BookingDetailWorkshop from '@components/booking/BookingDetailWorkshop';
import BookingDetailService from '@components/booking/BookingDetailService';
import BookingDetailFileOtoklix from '@components/booking/BookingDetailFileOtoklix';
import BookingDetailFileUser from '@components/booking/BookingDetailFileUser';
import BookingServiceRequest from '@components/booking/BookingServiceRequest';

function BookingDetail() {
  const history = useHistory();
  const { id } = useParams();
  const { generatePermission } = userPermission();
  const [data, setData] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workshops, setWorkshops] = useState(null);
  const [bookingType, setBookingType] = useState(null);
  const [activityLogs, setActivityLogs] = useState([]);
  const [bookingTypeTitle, setBookingTypeTitle] = useState('');
  const [canReschedule, setCanReschedule] = useState(false);
  const [canReviseOrder, setCanReviseorder] = useState(false);
  const [dataBillSettlement, setDataBillSettlement] = useState({});
  const [canChangeWorkshop, setCanChangeWorkshop] = useState(false);
  const [bookingStatusOptions, setBookingStatusOptions] = useState([]);
  const [hasNotif, setHasNotif] = useState(true);
  const [notification, setNotification] = useState({});

  useEffect(() => {
    if (window?.location?.pathname.toString() === `/bookings/${id}`) {
      window.addEventListener(
        'popstate',
        (event) => {
          if (event.state && window?.location?.pathname.toString() === `/bookings/${id}/estimation`) {
            history.push('/bookings');
          }
        },
        false
      );
    }
  }, [window?.location?.pathname]);

  const allowShow = generatePermission('booking', 'show');

  useEffect(() => {
    if (allowShow) {
      loadBookingDetail(id);
    }
  }, [allowShow]);

  async function loadBookingDetail(bookingId) {
    setLoading(true);

    const api = new API('v2');
    try {
      const response = await api.get(`v2/fleet/booking/${bookingId}`);
      setData(response?.data?.data ?? {});
      // mappingDataBillSettlement(response?.data?.data ?? {});
      // loadLogHistory(response?.data?.data?.id ?? {});
      // loadBookingStatusOptions(response?.data?.data?.id ?? {});
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setData();
      handleResultNotification(true, 'failed', `Gagal mendapatkan Detail Booking, ` + error);
    }

    // if (resp !== null) {
    //   resp.booking_type = resp?.booking_type ? resp?.booking_type.toLowerCase() : '';

    //   setData(resp);
    //   mappingDataBillSettlement(resp);
    //   loadLogHistory(resp?.id);
    //   loadBookingStatusOptions(resp?.id);
    //   if (resp.booking_status.slug === 'finish' || resp.booking_status.slug === 'cancel') {
    //     setCanReschedule(true);
    //     setCanChangeWorkshop(true);
    //   }

    //   setWorkshops(resp);
    // }

    setLoading(false);
  }

  const mappingDataBillSettlement = (detailBooking) => {
    const dataBillSettlement = {
      packages: getDataPackages(detailBooking),
      otopoints: detailBooking?.total_point_spent >= 0 ? detailBooking?.total_point_spent : 0,
      discount: {
        code: detailBooking?.promo?.promo_code ?? '',
        nominal: detailBooking?.discount_promo ?? 0,
      },
      commission_otoklix: detailBooking?.commission_otoklix ?? 0,
      commission_workshop: detailBooking?.commission_workshop ?? 0,
      amount: detailBooking?.total_price ?? 0,
      amount_settlement_otoklix: detailBooking?.total_price_settlement_otoklix ?? 0,
      amount_settlement_workshop: detailBooking?.total_price_settlement_workshop ?? 0,
      booking_type: detailBooking?.booking_type ?? '',
      margin: detailBooking?.margin ?? 0,
    };

    setBookingType(detailBooking?.booking_type ?? '');
    setBookingTypeTitle(
      detailBooking?.booking_type
        ? detailBooking.booking_type !== 'custom'
          ? detailBooking.booking_type.toUpperCase()
          : 'Custom'
        : ''
    );
    // setDataBillSettlement(dataBillSettlement);

    const billSettlementTest = {
      status: 'active',
      groups: [
        {
          name: 'Paket',
          group_items: [
            {
              position: 'top',
              name: 'Paket Ganti Oli Fuchs 10W40',
              rate_otoklix: 0,
              rate_workshop: 100,
              nett_price_otoklix: 380000,
              nett_price_workshop: 380000,
              sub_total_bill: 380000,
              sub_total_otoklix: 0,
              sub_total_workshop: 380000,
              group_items_details: [
                {
                  name: 'Oli Fusch 10W40',
                  qty: 4,
                  markup: 0,
                  disc: 0,
                  price: 85000,
                  sub_total: 340000,
                },
                {
                  name: 'Filter Oli',
                  qty: 1,
                  markup: 0,
                  disc: 0,
                  price: 40000,
                  sub_total: 40000,
                },
                {
                  name: 'Jasa Ganti Oli',
                  qty: 1,
                  markup: 0,
                  disc: 0,
                  price: 0,
                  sub_total: 0,
                },
              ],
            },
          ],
        },
        {
          name: 'Produk',
          group_items: [
            {
              position: 'bottom',
              name: 'Subtotal Produk',
              sub_total_bill: 2600000,
              sub_total_otoklix: 0,
              sub_total_workshop: 2600000,
              group_items_details: [
                {
                  name: 'Ban Tempur',
                  qty: 4,
                  markup: 0,
                  disc: 0,
                  nett_price_otoklix: 800000,
                  nett_price_workshop: 800000,
                  rate_otoklix: 0,
                  rate_workshop: 100,
                  price: 200000,
                  sub_total: 800000,
                  sub_total_otoklix: 0,
                  sub_total_workshop: 800000,
                },
                {
                  name: 'Velg Racing',
                  qty: 4,
                  markup: 0,
                  disc: 0,
                  nett_price_otoklix: 1800000,
                  nett_price_workshop: 1800000,
                  rate_otoklix: 0,
                  rate_workshop: 100,
                  price: 450000,
                  sub_total: 1800000,
                  sub_total_otoklix: 0,
                  sub_total_workshop: 1800000,
                },
              ],
            },
          ],
        },
        {
          name: 'Jasa',
          group_items: [
            {
              position: 'bottom',
              name: 'Subtotal Jasa',
              sub_total_bill: 560000,
              sub_total_otoklix: 0,
              sub_total_workshop: 560000,
              group_items_details: [
                {
                  name: 'Jasa Bongkar Pasang dan Velg',
                  qty: 1,
                  markup: 0,
                  disc: 0,
                  nett_price_otoklix: 560000,
                  nett_price_workshop: 560000,
                  rate_otoklix: 0,
                  rate_workshop: 100,
                  price: 560000,
                  sub_total: 560000,
                  sub_total_otoklix: 0,
                  sub_total_workshop: 560000,
                },
              ],
            },
          ],
        },
      ],
      customer_type: detailBooking?.customer?.customer_type,
      pph: 60000,
      margin: 0,
      otopoints: 40000,
      discount: {
        code: 'PAKSETO',
        nominal: 10000,
      },
      sub_total_bill: 3540000,
      sub_total_otoklix: 0,
      sub_total_workshop: 3540000,
      total_amount_bill: 3550000,
      total_amount_otoklix: -10000,
      total_amount_workshop: 3540000,
    };
    setDataBillSettlement(billSettlementTest);
  };

  const getDataPackages = (detailBooking) => {
    let packages = [];

    detailBooking?.packages &&
      detailBooking?.packages.forEach((item) => {
        let packageItem = {
          category: item?.category ?? '-',
          name: Helper.shortenByDash(item?.name) ?? '-',
          price_rate_otoklix: item?.total_price_rate_otoklix ?? 0,
          price_rate_workshop: item?.total_price_rate_workshop ?? 0,
          sub_total: item?.total_price ?? 0,
          sub_total_rate_otoklix: item?.total_price_rate_otoklix ?? 0,
          sub_total_rate_workshop: item?.total_price_rate_workshop ?? 0,
          package_details: [],
          package_services: [],
        };

        item?.package_details &&
          item?.package_details.forEach((value) => {
            let key = 'package_details';
            let valueName = value?.name ?? '';
            let valueItemName = value?.line_item?.items?.name ?? '';
            let namePackageItem = valueItemName ? valueName + ' - ' + valueItemName : valueName;

            if (value?.category == 'custom') {
              packageItem.name = value?.name ?? '';
              namePackageItem = item?.description ?? '-';
            } else if (value?.category == 'b2b') {
              key = value?.type_item == 'service' ? 'package_services' : 'package_details';
            } else {
              key = /jasa/gi.test(valueItemName ? valueItemName : valueName) ? 'package_services' : 'package_details';
            }

            packageItem[key].push({
              name: namePackageItem,
              qty: value?.qty ?? 0,
              price: value?.price ?? 0,
              sub_total: value?.subtotal ?? 0,
              nett_sub_total: value?.nett_subtotal ?? 0,
              rate_otoklix: value?.rate_otoklix ?? 0,
              rate_workshop: value?.rate_workshop ?? 100,
              price_rate_otoklix: value?.price_rate_otoklix ?? 0,
              price_rate_workshop: value?.price_rate_workshop ?? 0,
              up_rate: value?.up_rate ?? 0,
              discount_amount: value?.discount_amount ?? 0,
              discount_percentage: value?.discount_percentage ?? 0,
              nett_price: value?.nett_price ?? 0,
            });
          });

        packages.push(packageItem);
      });

    return packages;
  };

  async function loadLogHistory(id) {
    const api = new API('v2');
    const url = `v2/fleet/activity-logs/booking/${id}`;
    const params = {
      params: {
        page: 1,
        limit: 100,
      },
    };
    const response = await api.get(url, params);
    setActivityLogs(response?.data?.data?.activity_logs ?? []);
  }

  async function loadBookingStatusOptions(id) {
    const api = new API('v2');
    const response = await api.get(`v2/fleet/booking/${id}/status-options/`);
    const bookingOptions = map(response.data.data, (option) => {
      return { name: option.label, value: option.slug };
    });

    bookingOptions && setBookingStatusOptions(bookingOptions);
  }

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Booking / Order',
        active: false,
        url: '/bookings',
      },
      {
        id: 2,
        label: `Daftar Booking`,
        active: false,
        url: `/bookings`,
      },
      {
        id: 2,
        label: `Detail Booking`,
        active: true,
        url: `/bookings/${data?.booking_code}`,
      },
    ];
  };

  const handleLoading = (status) => {
    setLoading(status);
  };

  const goToRevisePage = () => {
    history.push(`/bookings/${id}/revise`);
  };

  const handleCanReviseOrder = () => {
    if (
      data?.booking_type == 'b2b' &&
      (data?.booking_status?.slug == 'payment' ||
        data?.booking_status?.slug == 'waiting' ||
        data?.booking_status?.slug == 'confirm' ||
        data?.booking_status?.slug == 'on-process' ||
        data?.booking_status?.slug == 'service-done' ||
        data?.booking_status?.slug == 'finish')
    ) {
      setCanReviseorder(true);
    } else {
      setCanReviseorder(false);
    }
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  // const handleResultNotification = (notification) => {
  //   if (notification.status) {
  //     setNotification({
  //       type: notification.type,
  //       message: notification.message,
  //     });
  //     window.scrollTo({
  //       top: 0,
  //       behavior: 'smooth',
  //     });
  //   }
  //   setHasNotif(notification.status);
  //   setTimeout(() => {
  //     setHasNotif(false);
  //     setNotification({});
  //   }, 3000);

  //   if (notification?.type == 'success') {
  //     loadBookingDetail(id);
  //   }
  // };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  useEffect(() => {
    handleCanReviseOrder();
  }, [data]);

  useEffect(() => {
    if (!allowShow) {
      history.push('/404');
    }
  }, [allowShow]);

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        {!data && (
          <Wrapper
            customContentClassName="px-5"
            breadcrumbData={generateBreadcrumb()}
            customBreadcrumbClassName={'pb-0 pt-3'}
            showNotification={hasNotif}
            notification={notification}
            onCloseNotification={handleCloseNotification}
          >
            <Row className="mb-3 mt-0 d-flex" xs={2}>
              <Col xs={12} md={3}>
                <h3>{'Detail Booking'}</h3>
              </Col>
            </Row>
          </Wrapper>
        )}
        {data && (
          <Wrapper
            customContentClassName="px-5"
            breadcrumbData={generateBreadcrumb()}
            customBreadcrumbClassName={'pb-0 pt-3'}
            showNotification={hasNotif}
            notification={notification}
            onCloseNotification={handleCloseNotification}
          >
            <Row className="mb-3 mt-0 d-flex" xs={2}>
              <Col xs={12} md={3}>
                <h3>{'Detail Booking'}</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <BookingDetailDetails
                  id={id}
                  detailData={data}
                  workshops={workshops}
                  canReschedule={canReschedule}
                  bookingStatusOptions={bookingStatusOptions}
                  onLoading={handleLoading}
                  onFetchBookingDetail={loadBookingDetail}
                  onResultNotification={handleResultNotification}
                ></BookingDetailDetails>
                <BookingDetailCar detailData={data?.car_detail}></BookingDetailCar>
                <BookingDetailWorkshop id={id} detailData={data}></BookingDetailWorkshop>
                <BookingDetailLog detailData={data?.log_history}></BookingDetailLog>
              </Col>
              <Col sm={12} md={6}>
                {data?.status?.slug != 'review' && (
                  <BookingDetailService
                    detailService={data?.service_details}
                    detailData={data}
                    status={data?.status}
                    title={`Detail Servis`}
                    id={id}
                  ></BookingDetailService>
                )}
                <BookingServiceRequest
                  detailService={data?.service_details}
                  detailData={data}
                  status={data?.status}
                  title={`Permintaan Servis`}
                  id={id}
                ></BookingServiceRequest>
                <BookingDetailFileOtoklix detailData={data?.photo_attachment}></BookingDetailFileOtoklix>
                <BookingDetailFileUser
                  detailData={data?.file_attachment}
                  id={id}
                  onLoading={handleLoading}
                  handleResultNotification={handleResultNotification}
                  onFetchBookingDetail={loadBookingDetail}
                ></BookingDetailFileUser>
              </Col>
            </Row>
          </Wrapper>
        )}
      </React.Fragment>
    );
  };

  return renderContent();
}

export default BookingDetail;
