import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import Wrapper from '@components/wrapper/Wrapper';
import API from '@utils/API';
import CustomerForms from '@components/account/CustomerForms';
import { assign, find, map } from 'lodash';
import { Col, Button, Row, Form } from 'reactstrap';
import { flagTypeOption, userTypeOption } from '../../utils/Constants';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';
import { useHistory } from 'react-router-dom';

function AccountDetail() {
  const { id } = useParams();
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [customerType, setCustomerType] = useState('b2c');
  const [userType, setUserType] = useState(undefined);
  const [flagType, setFlagType] = useState(undefined);
  const [loyaltyPoints, setLoyaltyPoints] = useState(undefined);
  const [origin, setOrigin] = useState('detail');
  const [notification, setNotification] = useState({});
  const [hasNotification, setHasNotification] = useState(false);
  const [disabledAll, setDisabledAll] = useState(false);
  const [userLocations, setUserLocations] = useState();

  const allowPermission = (menu) => {
    const allowAccess = !!(generatePermission('user', menu) || generatePermission('user', 'all'));
    return allowAccess;
  };

  const breadcrumb = [
    {
      id: 1,
      label: 'Akun',
      active: false,
      url: '/accounts',
    },
    {
      id: 2,
      label: `User Management`,
      active: false,
      url: '/accounts',
    },
    {
      id: 3,
      label: 'Detail User',
      active: true,
      url: '',
    },
  ];

  const breadcrumbShow = [
    {
      id: 1,
      label: 'Akun',
      active: false,
      url: '/accounts',
    },
    {
      id: 2,
      label: 'Detail User',
      active: true,
      url: '',
    },
  ];

  const loadUserDetail = async () => {
    setLoading(true);

    await api
      .get(`v2/fleet/account/${id}/`)
      .then((response) => {
        const data = response?.data?.data;
        setInitialValues(data ?? {});
        setLoading(false);
      })
      .catch((err) => {
        const error = err?.response?.data?.error?.message;
        setDisabledAll(true);
        setInitialValues({});
        if (err?.response?.data?.error?.status_code == 404) {
          history.push('/404');
        } else {
          handleResultNotification(true, 'failed', `Gagal mendapatkan detail Akun, ` + error);
        }
        setLoading(false);
      });
  };

  const loadUserInfo = async () => {
    setLoading(true);

    await api
      .get(`v2/fleet/auth/`)
      .then((response) => {
        const data = response?.data?.data;
        setInitialValues(data ?? {});
        setLoading(false);
      })
      .catch((err) => {
        const error = err?.response?.data?.error?.message;
        setDisabledAll(true);
        setInitialValues({});
        handleResultNotification(true, 'failed', `Gagal mendapatkan detail Akun, ` + error);
        setLoading(false);
      });
  };

  const loadUserLocation = async () => {
    const response = await api.get(`v2/fleet/customers/${id}/locations/`);

    setUserLocations(response?.data?.data);
  };

  const loadUserCar = async () => {
    const response = await api.get(`v2/fleet/customers/customer-car/${id}/`);

    setUserCars(response?.data?.data ?? []);
  };

  const handleChangeOrigin = (origin) => {
    setOrigin(origin);
  };

  const handleEditForm = (status) => {
    setCustomerType(status);
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    const { status, type, message } = notification;
    if (status) {
      setNotification({
        type: type,
        message: message,
      });
    }
    setHasNotification(status);
    setTimeout(() => {
      setHasNotification(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotification(false);
    setNotification({});
  };

  useEffect(() => {
    if (id) {
      if (id === 'me') loadUserInfo();
      else loadUserDetail();
    }
  }, [id]);

  // useEffect(() => {
  //   allowPermission(origin);
  // }, [origin]);

  // render main content page
  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        <Wrapper
          customContentClassName="px-5"
          customBreadcrumbClassName={'pb-0 pt-3'}
          breadcrumbData={id === 'me' ? breadcrumbShow : breadcrumb}
          showNotification={hasNotification}
          notification={notification}
          onCloseNotification={handleCloseNotification}
        >
          <Row className="mb-3 mt-0">
            <Col xs={12} md={4}>
              <h3>Detail User</h3>
            </Col>
          </Row>
          {initialValues?.id && (
            <CustomerForms
              id={id}
              initialValues={initialValues}
              origin={origin}
              disabledAll={disabledAll}
              allowPermission={allowPermission('edit')}
              handleChangeOrigin={handleChangeOrigin}
              handleEditForm={handleEditForm}
              activeCustomerType={customerType}
              loadUserDetail={loadUserDetail}
              loadUserCar={loadUserCar}
              handleResultNotification={handleResultNotification}
            />
          )}
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default AccountDetail;
