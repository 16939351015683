import { map } from 'lodash';
import React, { useState, useMemo, useEffect, Component } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import Loading from '@components/loading/Loading';
import BookingFleetBadges from '@components/badge/BookingFleetBadges';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import moment from 'moment';

const DataTablebyStatus = (props) => {
  const history = useHistory();
  const { allowShow, allowList, allowShowFleet, filtered, status, title, handleResultNotification } = props;
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});

  const openBookingDetail = (id) => {
    if (allowShow) {
      history.push(`/bookings/${id}`);
    }
  };

  const openCarDetail = (id) => {
    if (allowShowFleet) {
      history.push(`/fleets/${id}`);
    }
  };

  const openBookingsDetail = (row) => {
    if (allowShow) {
      history.push(`/bookings/${row?.booking_code}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: 'Status',
        selector: (row) => <BookingFleetBadges status={row?.status} />,
        minWidth: '200px',
      },
      {
        name: 'Tanggal Dibuat',
        selector: (row) => row?.created_at ?? '-',
        cell: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        minWidth: '150px',
        sortable: true,
        left: true,
      },
      {
        name: 'Booking Code',
        selector: (row) => row?.booking_code ?? '-',
        // cell: (row) => (
        //   <span
        //   // onClick={() => openBookingDetail(row?.booking_code)}
        //   // className={allowShow ? 'cursor-pointer hover-blue' : ''}
        //   >
        //     {row?.booking_code}
        //   </span>
        // ),
        minWidth: '160px',
        sortable: true,
      },
      {
        name: 'Merek Mobil',
        id: 'name',
        selector: (row) => row?.car_brand ?? '-',
        // cell: (row) => (
        //   <span
        //   // onClick={() => openCarDetail(row?.user_car_id)}
        //   // className={allowShowFleet ? 'cursor-pointer hover-blue' : ''}
        //   >
        //     {row?.car_brand}
        //   </span>
        // ),
        minWidth: '180px',
        sortable: true,
        wrap: true,
      },
      {
        name: 'Plat Nomor',
        minWidth: '120px',
        selector: (row) => (row?.car_license_plate ? row?.car_license_plate : '-'),
        sortable: true,
        wrap: true,
      },
      {
        name: 'Tags',
        selector: (row) => row?.tags?.join(', ') ?? '-',
        minWidth: '150px',
        wrap: 'false',
        sortable: true,
      },
      {
        name: 'Kategori Servis',
        selector: (row) => (row?.service_categories?.length > 0 ? generateCategory(row?.service_categories) : '-'),
        minWidth: '170px',
        sortable: true,
      },
      {
        name: 'Detail Servis',
        selector: (row) => (row?.service_details?.length > 0 ? generateCategory(row?.service_details) : '-'),
        minWidth: '250px',
        sortable: true,
      },
      {
        name: 'Biaya',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.final_price)}`,
        minWidth: '150px',
      },
    ],
    []
  );

  const conditionalRowStyles = [
    {
      when: (row) => row?.service_details?.length > 3,
      style: {
        minHeight: '180px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 3,
      style: {
        minHeight: '150px',
      },
    },
    {
      when: (row) => row?.service_details?.length == 2,
      style: {
        minHeight: '100px',
      },
    },
  ];

  const customStylesHeaderTable = {
    rows: {
      style: {
        minHeight: '80px', // override the row height
      },
    },
    cells: {
      style: {
        paddingTop: '25px',
        alignItems: 'start',
      },
    },
  };

  const generateCategory = (data) => {
    const mapping = data.map((item, index) => {
      if (index < 3) {
        return <div className="mb-4">{item}</div>;
      } else if (index == 3) {
        return <div className="mb-4">{'+' + (data?.length - 3) + ' Lainnya'}</div>;
      }
    });
    return mapping;
  };

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchBookings() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/fleet/booking/?`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      console.log(error);
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan data Booking, ` + error);
    }
  }

  const getParamData = () => {
    let params = {
      status: status,
      start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD 23:59:59') : null,
      end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD 00:00:00') : null,
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const resetBookings = () => {
    // setResetSorting(true);
    setSortingData({});
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleMethodfetchBookings = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchBookings();
    }
  };

  useEffect(() => {
    if (page && pageLimit) {
      if (!firstLoad) {
        fetchBookings();
      }
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad && filtered?.time?.value == 'custom') {
      if (filtered?.date?.start_date) {
        handleMethodfetchBookings();
      }
    } else {
      if (!firstLoad) handleMethodfetchBookings();
    }
  }, [sortingData]);

  useEffect(() => {
    if (firstLoad && filtered) {
      fetchBookings();
    } else {
      resetBookings();
    }
  }, []);

  return (
    <>
      {allowList && (
        <Row className="p-0 my-4">
          <Col className="py-2">
            <h5>{title}</h5>
          </Col>
          <Col sm={12} md={12} className="p-0 mb-0" style={{ backgroundColor: 'white' }}>
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              progressComponent={<Loading />}
              data={allowList ? data : []}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              conditionalRowStyles={conditionalRowStyles}
              customStyles={customStylesHeaderTable}
              onRowClicked={(row) => openBookingsDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="pb-2 m-0" style={{ backgroundColor: 'white' }}>
            {data?.length >= 1 && !loading && allowList && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default DataTablebyStatus;
