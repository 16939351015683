import React, { useState, useMemo, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import { Col, Button, Row, Form } from 'reactstrap';
import findIndex from 'lodash/findIndex';
import CreateFleetModal from '@components/fleet/CreateFleetModal';
import { assign } from 'lodash';
import Helper from '@helpers/Helper';

function Fleet() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);

  const keys = ['keyword'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [statusOptions, setStatusOptions] = useState([]);
  const [control, setControl] = useState({});
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});

  const [hasModalCreateFleet, setHasModalCreateFleet] = useState(false);

  const toogleCreateFleet = () => setHasModalCreateFleet(!hasModalCreateFleet);

  const handleSubmitCreateFleet = (status, error) => {
    const errorMsg = error ? 'Gagal - Mobil gagal didaftarkan, ' + error : 'Gagal - Mobil gagal didaftarkan';
    if (status) {
      fetchBookings();
      setHasModalCreateFleet(false);
    }
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil - Mobil suskses didaftarkan' : errorMsg
    );
  };

  const openBookingsDetail = (row) => {
    if (allowShow) {
      history.push(`/fleets/${row?.id}`);
    }
  };

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Mobil',
        active: false,
        url: '/fleets',
      },
      {
        id: 2,
        label: `Daftar Mobil`,
        active: true,
        url: ``,
      },
    ];
  };

  const allowPermission = (menu) => {
    return !!(generatePermission('garage', menu) || generatePermission('garage', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowAdd = allowPermission('all') || allowPermission('create');
  const allowShow = allowPermission('all') || allowPermission('show');

  const customStylesHeaderTable = {
    rows: {
      style: {
        minHeight: '40px', // override the row height
      },
    },
  };

  let dataFilter = [
    {
      id: 'filter_text_filter',
      type: 'text',
      title: 'keyword',
      name: 'keyword',
      name_field: 'keyword',
      value: tempFiltered?.keyword ?? '',
      placeholder: 'Cari Mobil',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Mobil',
        selector: (row) => row?.brand_type?.name ?? '-',
        minWidth: '250px',
      },
      {
        name: 'Plat Nomor',
        selector: (row) => row?.license_plate ?? '-',
        cell: (row) => (row?.license_plate?.length > 0 ? row?.license_plate : '-'),
        minWidth: '150px',
        sortable: true,
      },
      {
        name: 'Transmisi',
        minWidth: '150px',
        selector: (row) => Helper.getCommonLabel(row?.transmission) ?? '-',
        sortable: true,
        wrap: true,
      },
      {
        name: 'Tahun',
        selector: (row) => row?.year ?? '-',
        minWidth: '30px',
        wrap: 'false',
        sortable: true,
      },
      {
        name: 'Bahan Bakar',
        selector: (row) => Helper.getCommonLabel(row?.fuel) ?? '-',
        minWidth: '120px',
      },
      {
        name: 'Tags',
        selector: (row) => row?.tags?.join(', ') ?? '-',
        minWidth: '160px',
      },
      {
        name: 'Status',
        selector: (row) => row?.is_active ?? '-',
        cell: (row) => {
          if (row?.is_active) {
            return <span className="badge-custom badge-custom-order-done">Aktif</span>;
          } else if (!row?.is_active) {
            return <span className="badge-custom badge-custom-grey">Disposal</span>;
          }
        },
        minWidth: '150px',
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleLoading = (status) => {
    setLoading(status);
  };

  async function fetchBookings() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/fleet/garage/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (err) {
      const error = err?.response?.data?.error?.message;
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'failed', `Gagal mendapatkan data Mobil, ` + error);
    }
  }

  const getParamData = () => {
    let params = {
      keyword: filtered?.keyword ?? null,
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchBookings();
  };

  const handleMethodfetchBookings = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchBookings();
    }
  };

  const resetBookings = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      keyword: e?.keyword ?? '',
    };

    setFiltered(tempVal);
  };

  useEffect(() => {
    if (page && pageLimit && filtered) {
      fetchBookings();
    }
  }, [page, pageLimit, filtered]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/fleets?${urlParam}`);
      fetchBookings();
    }
    resetBookings();
  }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  useEffect(() => {
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  return (
    <>
      <Wrapper
        customContentClassName="px-5"
        breadcrumbData={generateBreadcrumb()}
        customBreadcrumbClassName={'pb-0 pt-3'}
        showNotification={hasNotif}
        notification={notification}
      >
        <CreateFleetModal
          modal={hasModalCreateFleet}
          onSubmit={handleSubmitCreateFleet}
          toggle={toogleCreateFleet}
          onLoading={handleLoading}
          handleResultNotification={handleResultNotification}
        />
        <Row className="mb-3 mt-0">
          <Col xs={12} md={4}>
            <h3>Daftar Mobil</h3>
          </Col>
          <Col xs={12} md={8} className="">
            <div className="filter-sides">
              {allowList && (
                <ActionFilterCustom
                  filters={filters}
                  showButton={false}
                  showSearch={false}
                  loading={loading}
                  onApplyFilters={(e) => handleSetFiltered(e)}
                ></ActionFilterCustom>
              )}
            </div>
          </Col>
        </Row>
        <Row className="p-0 my-4" style={{ backgroundColor: 'white' }}>
          <Col sm={12} md={12} className="p-0 mb-0">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              data={allowList ? data : []}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              onRowClicked={(row) => openBookingsDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="pb-2 m-0">
            {data?.length >= 1 && !loading && allowList && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </Row>
      </Wrapper>
      {allowAdd && (
        <div
          className="d-flex justify-content-end"
          style={{
            backgroundColor: 'white',
            position: 'sticky',
            bottom: '0px',
            marginBottom: '-10px',
            padding: '10px 20px 10px 20px',
            width: '100%',
          }}
        >
          <Button
            className="mr-4 ml-2 btn-export-finance w-65"
            color="purple"
            disabled={disableForm}
            onClick={() => setHasModalCreateFleet(true)}
          >
            Tambah Mobil
          </Button>
        </div>
      )}
    </>
  );
}

export default Fleet;
