import React, { useRef, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import map from 'lodash/map';
import FilterTextField from '@components/field/FilterTextField';
import FilterSelectField from '@components/field/FilterSelectField';
import FilterRangePriceField from '@components/field/FilterRangePriceField';
import FilterSelectTextField from '@components/field/FilterSelectTextField';
import FilterDateField from '@components/field/FilterDateField';

const ActionFilterCustom = (props) => {
  const {
    loading,
    filters,
    onApplyFilters,
    onResetFilters,
    selectTextDirection,
    showButton = true,
    displayCenter = false,
    showSearch = true,
  } = props;

  const [dummyFilters, setDummyFilters] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [onSearch, setOnSearch] = useState(false);
  const [text, setText] = useState(null);

  const onChangeText = (index, value) => {
    dummyFilters[index].value = value;
    dummyFilters[index].is_filtered = false;
    setDummyFilters([...dummyFilters]);
    // handleApplyFilters();
    // handleSearch();
    setText(value);
  };

  const onApplyText = (index, e) => {
    if (e?.key === 'Enter' && !loading) {
      dummyFilters[index].is_filtered = dummyFilters[index]?.value ? true : false;
      setDummyFilters([...dummyFilters]);
      // handleApplyFilters();
      handleSearch();
    }
  };

  const onChangeSelect = (index, e) => {
    dummyFilters[index].value = e;
    dummyFilters[index].is_filtered = true;
    setDummyFilters([...dummyFilters]);
    // handleApplyFilters();
    handleSearch();
  };

  const onChangeRangePrice = (index, e) => {
    dummyFilters[index].value = e?.value ?? {};
    dummyFilters[index].is_filtered = e?.value?.price_from || e?.value?.price_to ? true : false;
    setDummyFilters([...dummyFilters]);
    // handleApplyFilters();
    handleSearch();
  };

  const onChangeSelectText = (index, e, type) => {
    if (type == 'Enter') {
      dummyFilters[index].value = e?.value ?? {};
      dummyFilters[index].is_filtered = e?.value_text ? true : false;
      setDummyFilters([...dummyFilters]);
      // handleApplyFilters();
      handleSearch();
    } else {
      dummyFilters[index].dummy_value_select = e?.dummy_value_text ? e?.dummy_value_select : '';
      dummyFilters[index].value_text = e?.dummy_value_text ?? '';
      dummyFilters[index].value_select = e?.dummy_value_text ? e?.dummy_value_select : '';
      dummyFilters[index].temp_value_select = '';
      dummyFilters[index].value = {
        searchBy: e?.dummy_value_select ?? '',
        search: e?.dummy_value_text ?? '',
      };
      setDummyFilters([...dummyFilters]);
    }
  };

  const onChangeDate = (index, e) => {
    dummyFilters[index].value = e?.value ?? {};
    dummyFilters[index].is_filtered = e?.selected_date || (e?.start_date && e?.end_date) ? true : false;
    setDummyFilters([...dummyFilters]);
    // handleApplyFilters();
    handleSearch();
  };

  const handleDefautlFilters = () => {
    map(filters, (item, index) => {
      if (item.type === 'text') {
        filters[index].value = item?.value ?? '';
        filters[index].is_filtered = item?.value ? true : false;
      } else if (item.type === 'select') {
        filters[index].is_filtered = item?.value ? true : false;
      } else if (item.type === 'range_price') {
        filters[index].is_filtered = item?.value?.price_from || item?.value?.price_to ? true : false;
      } else if (item.type === 'select_text') {
        filters[index].dummy_value_text = item?.value?.search ?? '';
        filters[index].dummy_value_select = item?.value?.searchBy ?? '';
        filters[index].value_select = item?.value?.searchBy ?? '';
        filters[index].value_text = item?.value?.search ?? '';
        filters[index].is_filtered = item?.value?.search ? true : false;
      } else if (item.type === 'date') {
        filters[index].selected_date = item?.value?.selected_date ? new Date(item?.value?.selected_date) : '';
        filters[index].start_date = item?.value?.start_date ? new Date(item?.value?.start_date) : '';
        filters[index].end_date = item?.value?.end_date ? new Date(item?.value?.end_date) : '';
        filters[index].is_filtered =
          item?.value?.selected_date || (item?.value?.start_date && item?.value?.end_date) ? true : false;
      }
    });
    setDummyFilters(filters);
  };

  const handleSearch = () => {
    dummyFilters.forEach((item, index) => {
      if (item?.type == 'text') {
        dummyFilters[index].is_filtered = dummyFilters[index]?.value ? true : false;
        setDummyFilters([...dummyFilters]);
      } else if (item?.type == 'select_text') {
        dummyFilters[index].is_filtered = dummyFilters[index].value ? true : false;
        setDummyFilters([...dummyFilters]);
      }
    });
    handleApplyFilters();
    setOnSearch(true);
    setTimeout(() => {
      setOnSearch(false);
    }, 10);
  };

  const handleApplyFilters = () => {
    let filteredData = {};

    dummyFilters.forEach((item) => {
      filteredData[item?.name_field] = item?.value;
    });

    onApplyFilters(filteredData);
  };

  const handleIsFiltered = () => {
    let filtered = false;

    dummyFilters?.forEach((item) => {
      if (item?.is_filtered) {
        filtered = true;
      }
    });

    setIsFiltered(filtered);
  };

  useEffect(() => {
    handleIsFiltered();
  }, [dummyFilters]);

  useEffect(() => {
    handleDefautlFilters();
  }, [filters]);

  useEffect(() => {
    if (text?.length >= 0) {
      const delayDebounceFn = setTimeout(() => {
        handleSearch();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [text]);

  return (
    <div className={displayCenter ? 'section-filters status-filter' : 'section-filters'}>
      {map(dummyFilters, (item, index) => {
        return (
          <>
            {item.type === 'text' && (
              <FilterTextField
                handle={dummyFilters}
                key={item?.id}
                name={item?.name}
                placeholder={item?.placeholder}
                className={item?.value ? 'filled' : ''}
                width={item?.width ?? 'w-261'}
                autoComplete="off"
                value={item?.value ?? ''}
                onChange={(e) => {
                  let value = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : item?.value;
                  onChangeText(index, value);
                }}
                onKeyDown={(e) => onApplyText(index, e)}
                disabled={loading}
              ></FilterTextField>
            )}
            {item.type === 'select' && (
              <div className="d-flex justify-content-end btn-create-responsive">
                {item?.title && (
                  <h6 col className="pr-2 font-weight-semibold font-13">
                    {item?.title}
                  </h6>
                )}
                <FilterSelectField
                  key={item?.id}
                  name={item?.name}
                  placeholder={item?.placeholder}
                  width={item?.width ?? 'w-200'}
                  isSearchable={item?.searchable ?? true}
                  value={item?.value}
                  options={item?.options}
                  onChange={(e) => onChangeSelect(index, e)}
                  disabled={loading}
                ></FilterSelectField>
              </div>
            )}
            {item.type === 'range_price' && (
              <FilterRangePriceField
                key={item?.id}
                data={item}
                disabled={loading}
                onChange={(e) => onChangeRangePrice(index, e)}
              ></FilterRangePriceField>
            )}
            {item.type === 'select_text' && (
              <FilterSelectTextField
                key={item?.id}
                data={item}
                disabled={loading}
                onSearch={onSearch}
                onChange={(e, type) => {
                  onChangeSelectText(index, e, type);
                }}
                direction={selectTextDirection}
              ></FilterSelectTextField>
            )}
            {item.type === 'date' && (
              <FilterDateField
                key={item?.id}
                data={item}
                disabled={loading}
                onChange={(e) => onChangeDate(index, e)}
                backDate={item?.backDate}
                futureDate={item?.futureDate}
              ></FilterDateField>
            )}
          </>
        );
      })}
      {showSearch && (
        <Button className="button-reset-filter" disabled={loading} onClick={handleSearch}>
          Search
        </Button>
      )}

      {isFiltered && showButton && (
        <Button className="button-reset-filter" disabled={loading} onClick={onResetFilters}>
          Reset Filter
        </Button>
      )}
    </div>
  );
};

export default ActionFilterCustom;
